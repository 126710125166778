import React, { useState, useEffect } from 'react';
import { Button, Form, Select, Typography, message } from 'antd';
import supabase from '~supabaseConfig';
import { Box } from '@mui/joy';
import BoxWrapper from '../components/BoxWrapper';
const { Title } = Typography;

const { Option } = Select;

const ALL_CLINICS_VALUE = 'all_clinics';
const ALL_FARMS_VALUE = 'all_farms';

const ReportGenerator = () => {
 const [clinics, setClinics] = useState([]);
 const [allFarms, setAllFarms] = useState([]);
 const [filteredFarms, setFilteredFarms] = useState([]);
 const [years, setYears] = useState([]);
 const [loading, setLoading] = useState(false);
 const [form] = Form.useForm();

 useEffect(() => {
  fetchClinics();
  fetchAllFarms();
 }, []);

 const fetchClinics = async () => {
  const { data, error } = await supabase.from('clinics').select('id, clinic_name');
  if (error) {
   console.error('Error fetching clinics:', error);
  } else {
   setClinics([{ id: ALL_CLINICS_VALUE, clinic_name: 'All Clinics' }, ...data]);
  }
 };

 const fetchAllFarms = async () => {
  const { data, error } = await supabase
   .from('farms')
   .select('id, farm_name, clinic_id');
  if (error) {
   console.error('Error fetching farms:', error);
  } else {
   setAllFarms(data);
  }
 };

 const handleClinicChange = (selectedClinicIds) => {
  console.log('Selected clinics:', selectedClinicIds);
  if (selectedClinicIds.includes(ALL_CLINICS_VALUE)) {
   setFilteredFarms([{ id: ALL_FARMS_VALUE, farm_name: 'All Farms' }, ...allFarms]);
  } else if (selectedClinicIds.length === 0) {
   setFilteredFarms([]);
  } else {
   const newFilteredFarms = allFarms.filter(farm =>
    selectedClinicIds.includes(farm.clinic_id)
   );
   setFilteredFarms([{ id: ALL_FARMS_VALUE, farm_name: 'All Farms' }, ...newFilteredFarms]);
  }
  form.setFieldsValue({ farms: [], consultType: undefined, year: undefined });
  setYears([]);
 };

 const handleFarmChange = (selectedFarmIds) => {
  console.log('Selected farms:', selectedFarmIds);
  form.setFieldsValue({ consultType: undefined, year: undefined });
  setYears([]);
  fetchYears(selectedFarmIds, form.getFieldValue('consultType'));
 };

 const handleConsultTypeChange = (selectedConsultType) => {
  console.log('Selected consult type:', selectedConsultType);
  form.setFieldsValue({ year: undefined });
  fetchYears(form.getFieldValue('farms'), selectedConsultType);
 };

 const fetchYears = async (selectedFarms, consultType) => {
  console.log('Fetching years for farms:', selectedFarms, 'and consult type:', consultType);
  if (!selectedFarms || selectedFarms.length === 0 || !consultType) {
   setYears([]);
   return;
  }

  let tableName;
  switch (consultType) {
   case 'rvm':
    tableName = 'rvms';
    break;
   case 'milk_quality':
    tableName = 'milk_quality_consults';
    break;
   case 'wellbeing':
    tableName = 'wellbeing_consults';
    break;
   default:
    setYears([]);
    return;
  }

  try {
   let query = supabase.from(tableName).select('start_date');

   if (!selectedFarms.includes(ALL_FARMS_VALUE)) {
    query = query.in('farm_id', selectedFarms);
   }

   const { data, error } = await query;

   if (error) throw error;

   console.log('Fetched consult data:', data);

   const years = new Set(
    data.map(consult => new Date(consult.start_date).getFullYear())
   );

   const sortedYears = Array.from(years).sort((a, b) => b - a);
   console.log('Available years:', sortedYears);
   setYears(sortedYears);
  } catch (error) {
   console.error('Error fetching years:', error);
   message.error('Failed to fetch available years');
  }
 };

 const onFinish = async (values) => {
  setLoading(true);
  try {
   let data, error;

   if (values.consultType === 'rvm') {
    const allClinics = values.clinics.includes(ALL_CLINICS_VALUE);
    const allFarms = values.farms.includes(ALL_FARMS_VALUE);

    const clinicIds = allClinics ? [] : values.clinics;
    const farmIds = allFarms ? [] : values.farms;

    ({ data, error } = await supabase.rpc('generate_rvm_report', {
     p_clinic_ids: clinicIds,
     p_farm_ids: farmIds,
     p_report_year: values.year.toString(),
     p_all_clinics: allClinics,
     p_all_farms: allFarms
    }));
   } else {
    // Placeholder for other consult types
    message.error('Report generation for this consult type is not yet implemented');
    setLoading(false);
    return;
   }

   if (error) throw error;

   // Convert JSON to CSV
   const csv = jsonToCSV(data);
   const clinicNames = values.clinics.map(id => clinics.find(clinic => clinic.id === id)?.clinic_name || id).join('_');
   const farmNames = values.farms.map(id => allFarms.find(farm => farm.id === id)?.farm_name || id).join('_');
   downloadCSV(csv, `${clinicNames}_${farmNames}_${values.consultType}_${values.year}_report.csv`);

   message.success('Report generated successfully');
  } catch (error) {
   console.error('Error generating report:', error);
   message.error('Failed to generate report');
  } finally {
   setLoading(false);
  }
 };

 const jsonToCSV = (jsonData) => {
  const items = jsonData;
  const replacer = (key, value) => {
   if (value === null) {
    return '';
   }
   if (Array.isArray(value)) {
    return value.join(', ');
   }
   return value;
  };

  const header = Object.keys(items[0]);
  let csv = items.map(row =>
   header.map(fieldName => {
    let cell = JSON.stringify(row[fieldName], replacer);
    // Remove surrounding quotes added by JSON.stringify
    cell = cell.replace(/^"|"$/g, '');
    // If the cell contains a comma, newline, or double quote, wrap it in quotes
    if (cell.includes(',') || cell.includes('\n') || cell.includes('"')) {
     cell = `"${cell.replace(/"/g, '""')}"`;
    }
    return cell;
   }).join(',')
  );

  csv.unshift(header.join(','));
  return csv.join('\r\n');
 };

 const downloadCSV = (csv, filename) => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
   const url = URL.createObjectURL(blob);
   link.setAttribute('href', url);
   link.setAttribute('download', filename);
   link.style.visibility = 'hidden';
   document.body.appendChild(link);
   link.click();
   document.body.removeChild(link);
  }
 };

 return (
  <BoxWrapper>
   <Form
    name="basic"
    labelCol={{ span: 8 }}
    style={{ display: 'flex', textAlign: 'left', justifyContent: 'center', maxWidth: 600, flexDirection: 'column' }}
    wrapperCol={{ span: 16 }}
    form={form}
    onFinish={onFinish}
    layout="vertical"
   >
    <Title level={3}>Report generation</Title>
    <Form.Item name="clinics" label="Clinics" rules={[{ required: true, message: 'Please select at least one clinic' }]}>
     <Select
      mode="multiple"
      placeholder="Select clinics"
      optionFilterProp="children"
      onChange={handleClinicChange}
     >
      {clinics.map(clinic => (
       <Option key={clinic.id} value={clinic.id}>{clinic.clinic_name}</Option>
      ))}
     </Select>
    </Form.Item>

    <Form.Item name="farms" label="Farms" rules={[{ required: true, message: 'Please select at least one farm' }]}>
     <Select
      mode="multiple"
      placeholder="Select farms"
      optionFilterProp="children"
      disabled={filteredFarms.length === 0}
      onChange={handleFarmChange}
     >
      {filteredFarms.map(farm => (
       <Option key={farm.id} value={farm.id}>{farm.farm_name}</Option>
      ))}
     </Select>
    </Form.Item>

    <Form.Item name="consultType" label="Consult Type" rules={[{ required: true, message: 'Please select a consult type' }]}>
     <Select
      placeholder="Select consult type"
      onChange={handleConsultTypeChange}
      disabled={!form.getFieldValue('farms') || form.getFieldValue('farms').length === 0}
     >
      <Option value="rvm">RVM</Option>
      <Option value="milk_quality">Milk Quality</Option>
      <Option value="wellbeing">Wellbeing</Option>
     </Select>
    </Form.Item>

    <Form.Item name="year" label="Year" rules={[{ required: true, message: 'Please select a year' }]}>
     <Select
      placeholder="Select year"
      disabled={years.length === 0}
     >
      {years.map(year => (
       <Option key={year} value={year}>{year}</Option>
      ))}
     </Select>
    </Form.Item>

    <Form.Item>
     <Button type="primary" htmlType="submit" loading={loading}>
      Generate Report
     </Button>
    </Form.Item>
   </Form>
  </BoxWrapper>
 );
};

export default ReportGenerator;
