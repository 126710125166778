import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Row, Col, Divider, Space, Input, message, DatePicker, Typography, Select } from 'antd';
import { Box } from '@mui/joy';
import { useParams } from 'react-router-dom';
import pageTemplate from '../../components/pageTemplate';
import FormRow from './components/FormRow';
import supabase from '~supabaseConfig';
import LayoutBox from '../../components/LayoutBox';
import FormLayout from '../../components/FormLayout';

const { Option } = Select;

const Nutrition = () => {
 const supabaseTableName = 'wellbeing_consult_nutrition_data';
 const consultSupabaseTable = 'wellbeing_consult_id';
 const supabaseTableId = 'nutrition_data_id';
 const consultIdName = 'wellbeing_consult_id';

 const { farmId, wellbeingConsultId } = useParams();
 const [cannedNotes, setCannedNotes] = useState({});
 const [isLoading, setIsLoading] = useState(true);

 const [arr, setArr] = useState([
  { title: 'BCS', rowName: 'bcs', target: '5.0 at calving | <1 BCS loss at calving  | 0% of herd <3.5 BCS' },
 ]);

 const { form, onFinish } = pageTemplate(
  supabaseTableName,
  consultSupabaseTable,
  supabaseTableId,
  farmId,
  wellbeingConsultId,
  consultIdName,
  'wellbeing_consults'
 );

 const fetchCannedNotes = useCallback(async () => {
  if (Object.keys(cannedNotes).length > 0) return;
  console.log('Fetching canned notes...');
  try {
   const categories = arr.map(item => item.title);
   console.log('Categories we are looking for:', categories);
   const groupedNotes = {};

   const { data: allCategories, error: categoriesError } = await supabase
    .from('wellbeing_canned_notes_categories')
    .select('id, category');

   if (categoriesError) throw categoriesError;

   console.log('All categories from database:', allCategories.map(c => c.category));

   for (const category of categories) {
    console.log(`Searching for category: ${category}`);

    const categoryData = allCategories.find(c =>
     c.category.toLowerCase().trim() === category.toLowerCase().trim() ||
     c.category.toLowerCase().trim().startsWith(category.toLowerCase().trim())
    );

    if (!categoryData) {
     console.log(`Category "${category}" not found in database`);
     continue;
    }

    console.log(`Found category: ${categoryData.category} with id: ${categoryData.id}`);

    const { data, error } = await supabase
     .from('wellbeing_canned_notes')
     .select(`
       id,
       note,
       category (
         id,
         category
       )
     `)
     .eq('category', categoryData.id);

    if (error) {
     console.error(`Error fetching notes for ${category}:`, error);
     continue;
    }

    if (data && data.length > 0) {
     groupedNotes[category] = data.map(item => item.note);
     console.log(`Found ${data.length} notes for category "${category}"`);
    } else {
     console.log(`No notes found for category "${category}"`);
    }
   }

   console.log('Final grouped notes:', groupedNotes);
   setCannedNotes(groupedNotes);

   setArr(prevArr => prevArr.map(item => ({
    ...item,
    notes: groupedNotes[item.title] || [],
   })));

  } catch (error) {
   console.error('Error in fetchCannedNotes:', error);
  }
 }, [cannedNotes, arr]);

 useEffect(() => {
  const fetchAllData = async () => {
   setIsLoading(true);
   await fetchCannedNotes();
   setIsLoading(false);
  };

  fetchAllData();
 }, [fetchCannedNotes]);

 const handleNoteChange = (value, rowName) => {
  form.setFieldsValue({ [rowName]: value.join('\n') });
 };

 if (isLoading) return <div>Loading...</div>;

 console.log('Rendering Nutrition with cannedNotes:', cannedNotes);

 return (
  <FormLayout title="Nutrition" form={form} onFinish={onFinish}>
   {arr.map((row) => (
    <FormRow
     key={row.rowName}
     title={row.title}
     rowName={row.rowName}
     notes={cannedNotes[row.title] || []}
     target={row.target}
     form={form}
    >
     <Form.Item label="Canned Notes">
      <Select
       mode="multiple"
       style={{ width: '100%' }}
       placeholder="Select notes"
       onChange={(value) => handleNoteChange(value, row.rowName)}
      >
       {(cannedNotes[row.title] || []).map((note, index) => (
        <Option key={index} value={note}>
         {note}
        </Option>
       ))}
      </Select>
     </Form.Item>
    </FormRow>
   ))}
  </FormLayout>
 );
};

export default Nutrition;