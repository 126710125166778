import React, { useEffect, useState } from 'react';
import supabase from '~supabaseConfig';

export default function Test() {
  const [animals, setAnimals] = useState([]);

  useEffect(() => {
    fetchTest();
  }, []);

  const fetchTest = async () => {
    try {
      const { data, error } = await supabase
        .from('test')
        .select('*');

      if (error) {
        throw error;
      }

      if (data) {
        setAnimals(data);
      }
    } catch (error) {
      console.error('Error fetching animals:', error);
    }
  };

  return (
    <div>
      {animals.length > 0 ? (
        <>
          {animals.map((animal, index) => {
            console.log(animal);
            return (
              <p key={index}>{animal.animals}</p>
            );
          })}
        </>
      ) : (
        <>
          No animals
        </>
      )}
    </div>
  );
}
