import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Input, Checkbox, Select, Form, InputNumber, message } from 'antd';
import ClassificationTable from './components/ClassificationTable';
import CalvingTable from './components/CalvingTable';
import pageTemplate from '../../../components/pageTemplate';
import FormLayout from '../../../components/FormLayout';

const { Option } = Select;
const { TextArea } = Input;

const checkboxOptions = [
  { label: 'Spring', value: 'spring' },
  { label: 'Autumn', value: 'autumn' },
];

const recommendedFollowUp = [
  "Milking time visit post calving",
  "Early season mastitis review",
  "Milk quality consult/s",
  "On farm training",
  "Milking efficiency visit",
];

const recommendedTesting = [
  "Herd testing",
  "Milk culture mastitis cases",
  "Milk culture sub-clinical cases",
  "Bioscreen",
  "Antibiogram",
  "On farm testing with Mastaplex",
];

const dryCowReasons = [
  "BMSCC average ≥250,000",
  "Late season BMSCC >300,000 independent of OAD",
  "BMSCC grading late lactation",
  "≥10 cases/100 cows in first month lactation",
  "≥8 cases/100 cows from second month of lactation",
  "≥18 cases/100 cows over whole lactation",
  "≥25% herd with ICSCC ≥150,000 at herd tests in first 6 months lactation",
  "≥35% cows/heifers with ICSCCC ≥150/120,000 in late lactation",
  "New IMI rate of ≥10% cows per month in the late lactation",
];

const DryCowProtocol = () => {
  const { farmId, milkQualityConsultId } = useParams();
  const [blanket, setBlanket] = useState(false);
  const { form, onFinish } = pageTemplate(
    'milk_quality_consult_dry_cow_data',
    'milk_quality_consult_id',
    'dry_cow_id',
    farmId,
    milkQualityConsultId,
    'milk_quality_consult_id',
    'milk_quality_consults'
  );

  const handleSelectChange = (value) => {
    if (value.includes("Blanket")) {
      setBlanket(true);
    } else {
      setBlanket(false);
    }
  };

  const formContent = (
    <>
      <Form.Item name="dry_cow_season" label="Dry cow season">
        <Checkbox.Group options={checkboxOptions} />
      </Form.Item>

      <CalvingTable />

      <Form.Item name="dry_off_diagnostic_technique" label="Diagnostic technique to be used to identify cows at dry off:">
        <Select 
          mode="tags" 
          allowClear 
          placeholder="Select an option or begin typing"
          options={[
            { value: "Herd test", label: "Herd test" },
            { value: "RMT", label: "RMT" },
            { value: "Bacto screen", label: "Bacto screen" },
            { value: "In line conductivity", label: "In line conductivity" },
          ]}
        />
      </Form.Item>

      <Form.Item name="treatment_recommendation" label="Dry off treatment recommendation">
        <Select allowClear placeholder="Select an option">
          <Option value="Selective with teatsealant">Selective with teatsealant</Option>
          <Option value="Blanket without teatsealant">Blanket without teatsealant</Option>
          <Option value="Blanket with teatsealant">Blanket with teatsealant</Option>
          <Option value="Other">Other</Option>
        </Select>
      </Form.Item>

      <Form.Item name="treatment_plan" label="Dry off treatment plan">
        <Select allowClear placeholder="Select an option" onChange={handleSelectChange}>
          <Option value="Selective without teatsealant">Selective without teatsealant</Option>
          <Option value="Selective with teatsealant">Selective with teatsealant</Option>
          <Option value="Modified selective with teatsealant">Modified selective with teatsealant</Option>
          <Option value="Blanket without teatsealant">Blanket without teatsealant</Option>
          <Option value="Blanket with teatsealant">Blanket with teatsealant</Option>
          <Option value="Other">Other</Option>
        </Select>
      </Form.Item>

      {blanket && (
        <Form.Item name="blanket_dry_cow_reasons" label="Reasons for prescribing blanket dry cow therapy (if selected above)">
          <Select mode="tags" allowClear placeholder="Select an option or begin typing">
            {dryCowReasons.map((reason) => (
              <Option key={reason} value={reason}>{reason}</Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <ClassificationTable form={form} />

      <Form.Item label="Authorisation valid until:" name="authorisation_date">
        <input type="date" />
      </Form.Item>

      <Form.Item name="recommended_follow_up" label="Recommended follow up">
        <Checkbox.Group>
          {recommendedFollowUp.map((followup) => (
            <Checkbox key={followup} value={followup}>{followup}</Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>

      <Form.Item name="recommended_testing" label="Recommended testing">
        <Checkbox.Group>
          {recommendedTesting.map((testing) => (
            <Checkbox key={testing} value={testing}>{testing}</Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>

      <Form.Item name="notes" label="Notes">
        <TextArea />
      </Form.Item>
    </>
  );

  return (
    <FormLayout 
      title="Dry Cow Protocol" 
      form={form} 
      onFinish={onFinish}
      extraContent={formContent}
      saveButton={true}
    />
  );
};

export default DryCowProtocol;