import React from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input, Checkbox, Row, Col } from 'antd';
import pageTemplate from '../../../components/pageTemplate';
import FormLayout from '../../../components/FormLayout';

const MineralSupplementation = () => {
 const { farmId, rvmId } = useParams();
 const { form, onFinish } = pageTemplate(
  'rvm_mineral_supplementation_data',
  'rvm_id',
  'mineral_supplementation_id',
  farmId,
  rvmId,
  'rvm_id',
  'rvms'
 );

 const formContent = (
  <>
   <Row gutter={16} style={{ textAlign: 'left' }}>
    <Col span={12}>
     <Form.Item label="Magnesium" name="magnesium">
      <Input />
     </Form.Item>
    </Col>
    <Col span={12}>
     <Form.Item label="Calcium" name="calcium">
      <Input />
     </Form.Item>
    </Col>
   </Row>
   <Row gutter={16} style={{ textAlign: 'left' }}>
    <Col span={12}>
     <Form.Item label="Zinc" name="zinc">
      <Input />
     </Form.Item>
    </Col>
    <Col span={12}>
     <Form.Item label="Copper" name="copper">
      <Input />
     </Form.Item>
    </Col>
   </Row>
   <Row gutter={16} style={{ textAlign: 'left' }}>
    <Col span={12}>
     <Form.Item label="Selenium" name="selenium">
      <Input />
     </Form.Item>
    </Col>
    <Col span={12}>
     <Form.Item label="Cobalt/B12" name="cobal_b12">
      <Input />
     </Form.Item>
    </Col>
   </Row>
   <Row gutter={16} style={{ textAlign: 'left' }}>
    <Col span={8}>
     <Form.Item name="dosatron" valuePropName="checked">
      <Checkbox>Dosatron?</Checkbox>
     </Form.Item>
    </Col>
    <Col span={8}>
     <Form.Item name="copacaps" valuePropName="checked">
      <Checkbox>Copacaps</Checkbox>
     </Form.Item>
    </Col>
    <Col span={8}>
     <Form.Item name="agvance" valuePropName="checked">
      <Checkbox>Agvance</Checkbox>
     </Form.Item>
    </Col>
    <Col span={8}>
     <Form.Item name="ruminate" valuePropName="checked">
      <Checkbox>Ruminate</Checkbox>
     </Form.Item>
    </Col>
   </Row>
   <Row gutter={16} style={{ textAlign: 'left' }}>
    <Col span={24}>
     <Form.Item label="Trace mineral mix used" name="trace_mineral_mix_used">
      <Input />
     </Form.Item>
    </Col>
   </Row>
   <Row gutter={16} style={{ textAlign: 'left' }}>
    <Col span={12}>
     <Form.Item label="Date of last mineral check" name="last_mineral_check">
      <input type="date" />
     </Form.Item>
    </Col>
    <Col span={12}>
     <Form.Item label="Next planned mineral check" name="next_mineral_check">
      <input type="date" />
     </Form.Item>
    </Col>
   </Row>
  </>
 );

 return (
  <FormLayout
   title="Mineral Supplementation"
   form={form}
   onFinish={onFinish}
   extraContent={formContent}
  />
 );
};

export default MineralSupplementation;