import React from 'react';
import { Button, DatePicker, Form, message } from 'antd';
import supabase from '~supabaseConfig';

const { RangePicker } = DatePicker;

const YearPicker = ({ farmId, onDateAdded, tableName, direction }) => {
  const onFinish = async (values) => {
    try {
      const startYear = values.dateRange[0].format('YYYY');
      const endYear = values.dateRange[1].format('YYYY');

      const { data, error } = await supabase.from(tableName).insert([
        {
          start_date: startYear,
          end_date: endYear,
          farm_id: farmId,
        },
      ]);

      if (error) {
        throw error;
      }

      message.success(`New ${tableName} entry created successfully`);

      if (onDateAdded) {
        onDateAdded();
      }
    } catch (error) {
      console.error('Error creating entry:', error.message);
      message.error('Failed to create entry. Please try again.');
    }
  };

  return (
    <Form
      onFinish={onFinish}
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: direction === 'column' ? 'flex-start' : 'center',
        flexDirection: direction,
      }}
    >
   <Form.Item name="dateRange" style={{ marginRight: 20 }}>
    <RangePicker picker="year" />
   </Form.Item>
   <Form.Item>
    <Button type="primary" htmlType="submit">
     Save
    </Button>
   </Form.Item>
  </Form>
 );
};

export default YearPicker;