import React, { useEffect, useState } from 'react';
import './Routes.css';
import { Routes, Route } from 'react-router-dom';
import Home from '../home/Home';
import FarmObjectives from '../farm/consults/rvm/pages/farmObjectives/FarmObjectives.jsx';
import RvmLayout from '../farm/consults/rvm/RvmLayout.jsx';
import MilkQualityData from '../farm/consults/rvm/pages/milkQualityData/MilkQualityData.jsx';
import Repro from '../farm/consults/rvm/pages/repro/Repro.jsx';
import Health from '../farm/consults/rvm/pages/health/Health.jsx';
import Mineral from '../farm/consults/rvm/pages/mineralSupplementation/MineralSupplementation.jsx';
import KeyActions from '../farm/consults/rvm/pages/keyActions/KeyActions.jsx';
import RvmItemsTable from '../farm/consults/rvm/pages/rvm/Rvm.jsx';
import AnimalHealthPlanner from '../farm/consults/rvm/pages/animalHealthPlanner/AnimalHealthPlanner.jsx';
import Profile from '../profile/Profile';
import Farm from '../farm/index.jsx';
import Sidebar from './components/Sidebar';
import Box from '@mui/joy/Box';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Header from './components/Header';
import MilkQualityConsultLayout from '../farm/consults/milkQualityConsult/MilkQualityLayout';
import LSMQR from '../farm/consults/milkQualityConsult/pages/lsmqr/LSMQR';
import DryCowProtocol from '../farm/consults/milkQualityConsult/pages/dryCowProtocol/DryCowProtocol';
import WellbeingLayout from '../farm/consults/wellbeing/WellbeingLayout';
import Nutrition from '../farm/consults/wellbeing/pages/Nutrition';
import Behaviour from '../farm/consults/wellbeing/pages/Behaviour';
import WellbeingHealth from '../farm/consults/wellbeing/pages/WellbeingHealth';
import Environment from '../farm/consults/wellbeing/pages/Environment';
import YoungStockHealth from '../farm/consults/wellbeing/pages/YoungstockHealth';
import Vets from '../settings/pages/Vets'
import AnimalHealthSettings from '../settings/pages/AnimalHealthSettings'
import Settings from '../settings/Index'
import Test from './Test'
import RvmMedication from '../settings/pages/RvmMedication';
import Admin from '../settings/pages/Admin.jsx';
import supabase from '~supabaseConfig';
import { Navigate, Outlet } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import Reports from './reports/Reports';
import ErrorTest from '../ErrorTest';
import RvmNew from '../farm/consults/rvmNew/rvmNew';

const ProtectedRoute = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const { instance } = useMsal();

    useEffect(() => {
        checkAuthStatus();
    }, []);

    const checkAuthStatus = async () => {
        try {
            const userAccounts = instance.getAllAccounts();
            setAccounts(userAccounts);
            setIsAuthenticated(userAccounts.length > 0);
            setLoading(false);

            if (userAccounts.length > 0) {
                const email = userAccounts[0].username;
                await checkSupabaseSession(email);
            }
        } catch (error) {
            console.error('Error checking auth status:', error);
            setLoading(false);
        }
    };

    const checkSupabaseSession = async (email) => {
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();

        if (!session) {
            console.log("No Supabase session found, logging in user.");
            const { error: loginError } = await supabase.auth.signInWithPassword({
                email
                // password: process.env.REACT_APP_DEFAULT_USER_PASSWORD
            });

            if (loginError) {
                console.error("Error logging in to Supabase:", loginError);
                setIsAuthenticated(false);
                setLoading(false);
            } else {
                console.log("Successfully logged into Supabase.");
                setIsAuthenticated(true);
            }
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return isAuthenticated ? <Outlet /> : <Navigate to="/" replace />;
};


const ProtectedAdminRoute = () => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log('checking admin status ')
        checkAdminStatus();
    }, []);

    const checkAdminStatus = async () => {
        try {
            const { data: { user } } = await supabase.auth.getUser();
            console.log(user);  // Log the user data to ensure it's available

            if (user) {
                const { data: userData, error } = await supabase
                    .from('users')
                    .select('admin')
                    .eq('id', user.id)
                    .single();

                if (error) throw error;

                console.log(userData);  // Log the fetched user data
                setIsAdmin(userData.admin);
            } else {
                console.log('no user');
                setLoading(false);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error checking admin status:', error);
            setLoading(false);
        }
    };


    return isAdmin ? <Outlet /> : <Navigate to="/" replace />;
};

const ProtectedSuperAdminRoute = () => {
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        checkSuperAdminStatus()
    }, []);

    const checkSuperAdminStatus = async () => {
        try {
            const { data: { user } } = await supabase.auth.getUser();
            if (user) {
                const { data, error } = await supabase
                    .from('users')
                    .select('super_admin')
                    .eq('id', user.id)
                    .single();

                if (error) throw error;

                setIsSuperAdmin(data.super_admin);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error checking super admin status:', error);
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return isSuperAdmin ? <Outlet /> : <Navigate to="/" replace />;
};

const DashboardRoutes = () => {

    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline />

            <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
                <Sidebar />
                <Header />
                <Box
                    component="main"
                    className="MainContent"
                    sx={{
                        pt: { xs: 'calc(12px + var(--Header-height))', md: 3 },
                        pb: { xs: 2, sm: 2, md: 3 },
                        pr: { md: 2 },
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '100dvh',
                        gap: 1,
                        overflow: 'auto',
                    }}
                >
                    <Routes>
                        <Route element={<ProtectedRoute />}>
                            <Route path='new-rvm' element={<RvmNew />} />

                            <Route path="/" element={<Home />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/test" element={<Test />} />
                            <Route path="/reports" element={<Reports />} />
                            <Route path="/farm/:farmId" element={<Farm />} />
                            <Route path="/errortesting" element={<ErrorTest />} />
                            {/* settings routes */}

                            <Route element={<ProtectedAdminRoute />}>
                                <Route path="/settings" element={<Settings />}>
                                    <Route path="" element={<Vets />} />
                                    <Route path="vets" element={<Vets />} />
                                    <Route path="animal-health" element={<AnimalHealthSettings />} />
                                    <Route path="rvm-medication" element={<RvmMedication />} />
                                    <Route element={<ProtectedSuperAdminRoute />}>
                                        <Route path="admin" element={<Admin />} />
                                    </Route>
                                </Route>
                            </Route>

                            <Route
                                path="/farm/:farmId/milk_quality_consult/:milkQualityConsultId/*"
                                element={<MilkQualityConsultLayout />}
                            >
                                <Route path="" element={<LSMQR />} />
                                <Route path="LSMQR" element={<LSMQR />} />
                                <Route path="DryCowProtocol" element={<DryCowProtocol />} />
                            </Route>
                            <Route
                                path="/farm/:farmId/wellbeing_consult/:wellbeingConsultId/*"
                                element={<WellbeingLayout />}
                            >
                                <Route path="" element={<Nutrition />} />
                                <Route path="nutrition" element={<Nutrition />} />
                                <Route path="behaviour" element={<Behaviour />} />
                                <Route path="health" element={<WellbeingHealth />} />
                                <Route path="environment" element={<Environment />} />
                                <Route path="youngstock-health" element={<YoungStockHealth />} />
                            </Route>
                            <Route
                                path="/farm/:farmId/rvm/:rvmId/*"
                                element={<RvmLayout />}
                            >
                                <Route path="" element={<FarmObjectives />} />
                                <Route path="FarmObjectives" element={<FarmObjectives />} />
                                <Route path="MilkQualityData" element={<MilkQualityData />} />

                                <Route path="Repro" element={<Repro />} />
                                <Route path="Health" element={<Health />} />
                                <Route
                                    path="MineralSupplementation"
                                    element={<Mineral />}
                                />
                                <Route path="KeyActions" element={<KeyActions />} />
                                <Route path="Rvm" element={<RvmItemsTable />} />
                                <Route path="AnimalHealthPlanner" element={<AnimalHealthPlanner />} />
                            </Route>
                        </Route>
                    </Routes>
                </Box>
            </Box>

        </CssVarsProvider>
    );
};

export default DashboardRoutes;
