import React, { useEffect, useState, useCallback, useMemo } from 'react';
import YearPicker from './consults/components/YearPicker';
import ConsultList from './consults/components/ConsultList';
import { Card, Descriptions, Typography, Row, Col, Divider, Form, Input, Button, Select, Tabs, Flex, Spin, message, Modal } from 'antd';
import { Box } from '@mui/joy';
import supabase from '~supabaseConfig';
import { useMsal, useAccount } from '@azure/msal-react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Sentry from "@sentry/react";

export default function Farm() {
 const { farmId } = useParams();
 const [farmData, setFarmData] = useState(null);
 const [consultData, setConsultData] = useState({});
 const [clinicData, setClinicData] = useState([]);
 const [error, setError] = useState(null);
 const [isLoading, setIsLoading] = useState({});
 const [form] = Form.useForm();
 const [isEditing, setIsEditing] = useState(false);
 const [vets, setVets] = useState([]);
 const [farmVet, setFarmVet] = useState('');
 const [clinics, setClinics] = useState([]);
 const { instance, accounts } = useMsal();
 const user = useAccount(accounts[0]);
 const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
 const navigate = useNavigate();


 const fetchConsultData = useCallback(async (tableName) => {
  try {
   setIsLoading((prevState) => ({ ...prevState, [tableName]: true }));
   setError(null);

   const { data, error } = await supabase.from(tableName).select().eq('farm_id', farmId);

   if (error) {
    throw new Error(`Error fetching ${tableName} data: ${error.message}`);
   }

   setConsultData((prevState) => ({ ...prevState, [tableName]: data }));
  } catch (error) {
   console.error(error);
   setError(`Failed to fetch ${tableName} data. Please try again.`);
  } finally {
   setIsLoading((prevState) => ({ ...prevState, [tableName]: false }));
  }
 }, [farmId]);

 const fetchVets = useCallback(async () => {
  try {
   const { data, error } = await supabase
    .from('users')
    .select('id, name')
    .eq('vet', true);

   if (error) {
    throw new Error(`Error fetching vets: ${error.message}`);
   }
   setVets(data);
  } catch (error) {
   console.error('Error fetching vets:', error);
  }
 }, []);

 const fetchFarmVet = useCallback(async (id) => {
  try {
   const { data, error } = await supabase
    .from('users')
    .select('id, name')
    .eq('id', id);

   if (error) {
    throw new Error(`Error fetching vets: ${error.message}`);
   }
   setFarmVet(data);
  } catch (error) {
   console.error('Error fetching vets:', error);
  }
 }, []);

 const fetchClinics = useCallback(async () => {
  try {
   const { data, error } = await supabase.from('clinics').select('id, clinic_name');

   if (error) {
    throw new Error(`Error fetching clinics: ${error.message}`);
   }

   setClinics(data);
  } catch (error) {
   console.error('Error fetching clinics:', error);
  }
 }, []);

 const fetchFarmData = useCallback(async () => {
  try {
   setError(null);
   const { data, error } = await supabase
    .from('farms')
    .select('*')
    .eq('id', farmId)
    .single();

   if (error) {
    throw new Error(`Error fetching farm data: ${error.message}`);
   }

   setFarmData(data);

   if (data.clinic_id) {
    const { data: clinicData, error: clinicError } = await supabase
     .from('clinics')
     .select('*')
     .eq('id', data.clinic_id);

    if (!clinicError) {
     setClinicData(clinicData);
    }
   }

   if (data.vet) {
    fetchFarmVet(data.vet);
   }
  } catch (error) {
   console.error(error);
   setError('Failed to fetch farm data. Please try again.');
  }
 }, [farmId, fetchFarmVet]);

 useEffect(() => {
  fetchFarmData();
  fetchVets();
  fetchClinics();
 }, [fetchFarmData, fetchVets, fetchClinics]);

 useEffect(() => {
  if (farmData) {
   fetchConsultData('rvms');
   fetchConsultData('milk_quality_consults');
   fetchConsultData('wellbeing_consults');
  }
 }, [farmData, fetchConsultData]);

 const onFinish = async (values) => {
  try {
   const { error } = await supabase
    .from('farms')
    .update({ ...values, vet: values.vet })
    .eq('id', farmId);

   if (error) {
    throw new Error(`Error updating farm data: ${error.message}`);
   }

   fetchFarmData();
   form.resetFields();
   setIsEditing(false);

   message.success({
    content: 'Farm data saved successfully',
    style: {
     marginTop: '20vh',
     zIndex: 9999,
    },
   });
  } catch (error) {
   console.error('Error saving farm data:', error);
   message.error({
    content: 'Error saving farm data',
    style: {
     marginTop: '20vh',
     zIndex: 9999,
    },
   });
  }
 };

 const handleEdit = () => {
  setIsEditing(true);
  form.setFieldsValue(farmData);
 };
 const handleDelete = async () => {
  try {
   const { error } = await supabase
    .from('farms')
    .delete()
    .eq('id', farmId);

   if (error) {
    throw new Error(`Error deleting farm: ${error.message}`);
   }

   message.success({
    content: 'Farm deleted successfully',
    style: {
     marginTop: '20vh',
     zIndex: 9999,
    },
   });

   // Redirect to a list of farms or home page
   navigate('/');
  } catch (error) {
    console.error('Error deleting farm:', error);
    
    // Capture and send error to Sentry
    Sentry.captureException(error, {
      tags: {
        component: 'Farm',
        action: 'handleDelete',
      },
      extra: {
        farmId: farmId,
      },
    });

    message.error({
      content: `Error deleting farm: ${error.message}`,
      style: {
        marginTop: '20vh',
        zIndex: 9999,
      },
    });
  }
};

 const showDeleteConfirm = () => {
  setIsDeleteModalVisible(true);
 };
 const renderConsultTab = useCallback((tableName, title) => {
  return (
   <Tabs.TabPane tab={title} key={tableName}>
    {isLoading[tableName] ? (
     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
      <Spin size="large" />
     </div>
    ) : error ? (
     <Typography.Text type="danger">{error}</Typography.Text>
    ) : consultData[tableName] && consultData[tableName].length ? (
     <ConsultList
      data={consultData[tableName]}
      onDateChanged={() => fetchConsultData(tableName)}
      tableName={tableName}
      linkRoute={tableName === 'rvms' ? 'rvm' : `${tableName.slice(0, -1)}`}
      farmId={farmId}
     />
    ) : (
     <Box
      style={{
       display: 'flex',
       flexDirection: 'column',
       justifyContent: 'space-between',
       alignItems: 'flex-start',
      }}
     >
      <Typography.Text style={{ marginBottom: 20 }}>There are no {title} available.</Typography.Text>
      <YearPicker
       farmId={farmId}
       onDateAdded={() => fetchConsultData(tableName)}
       tableName={tableName}
       direction="column"
      />
     </Box>
    )}
   </Tabs.TabPane>
  );
 }, [consultData, error, farmId, fetchConsultData, isLoading]);

 const farmInfoContent = useMemo(() => {
  if (!farmData) return null;

  return isEditing ? (
   <Form form={form} onFinish={onFinish} initialValues={farmData}>
    <Row gutter={16}>
     <Col xs={24} md={12}>
      <Descriptions column={1} bordered>
       <Descriptions.Item label="Farm name">
        <Form.Item name='farm_name'>
         <Input />

        </Form.Item>
       </Descriptions.Item>
       <Descriptions.Item label="Farmer">
        <Form.Item name="farmer_name">
         <Input />
        </Form.Item>
       </Descriptions.Item>
       <Descriptions.Item label="Clinic">
        <Form.Item name="clinic_id">
         <Select allowClear placeholder="Select a clinic">
          {clinics.map((clinic) => (
           <Select.Option key={clinic.id} value={clinic.id}>
            {clinic.clinic_name}
           </Select.Option>
          ))}
         </Select>
        </Form.Item>
       </Descriptions.Item>
       <Descriptions.Item label="Vet">
        <Form.Item name="vet">
         <Select allowClear placeholder="Select a vet">
          {vets.map((vet) => (
           <Select.Option key={vet.id} value={vet.id}>
            {vet.name}
           </Select.Option>
          ))}
         </Select>
        </Form.Item>
       </Descriptions.Item>
       <Descriptions.Item label="Address">
        <Form.Item name="address">
         <Input />
        </Form.Item>
       </Descriptions.Item>
       <Descriptions.Item label="Email">
        <Form.Item name="email">
         <Input />
        </Form.Item>
       </Descriptions.Item>
       <Descriptions.Item label="Client code">
        <Form.Item name="client_code">
         <Input />
        </Form.Item>
       </Descriptions.Item>
       <Descriptions.Item label="Supply number">
        <Form.Item name="supply_number">
         <Input />
        </Form.Item>
       </Descriptions.Item>
      </Descriptions>
     </Col>
     <Col xs={24} md={12}>
      <Descriptions column={1} bordered>
       <Descriptions.Item label="Effective farm size (ha)">
        <Form.Item name="farm_size">
         <Input />
        </Form.Item>
       </Descriptions.Item>
       <Descriptions.Item label="Herd Size">
        <Form.Item name="herd_size">
         <Input />
        </Form.Item>
       </Descriptions.Item>
       <Descriptions.Item label="Heifer Size">
        <Form.Item name="heifer_size">
         <Input />
        </Form.Item>
       </Descriptions.Item>
      </Descriptions>
     </Col>
    </Row>
    <Divider />
    <Row justify="space-between">
     <Col>
      <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
       Save
      </Button>
      <Button onClick={() => setIsEditing(false)}>
       Cancel
      </Button>
     </Col>
     <Col>
      <Button onClick={showDeleteConfirm} danger>
       Delete
      </Button>
     </Col>
    </Row>
   </Form>
  ) : (
   <div style={{ textAlign: 'left' }}>
    <Flex justify={'flex-start'} align={'center'}>
     <Typography.Title level={2} style={{ margin: 0 }}>
      {farmData.farm_name}
     </Typography.Title>
     <Button type="primary" size='small' onClick={() => setIsEditing(true)} style={{ marginLeft: 10 }}>
      Edit
     </Button>
    </Flex>
    <Row gutter={16} align={'middle'}>
     <Col xs={24} md={12}>
      <Descriptions column={1} bordered>
       <Descriptions.Item label="Farmer">{farmData.farmer_name}</Descriptions.Item>
       <Descriptions.Item label="Clinic">
        {clinicData.length > 0 && clinicData[0].clinic_name
         ? clinicData[0].clinic_name
         : 'No Clinic Name'}
       </Descriptions.Item>
       <Descriptions.Item label="Vet">
        {farmVet.length > 0 && farmVet[0].name
         ? farmVet[0].name
         : 'No Vet assigned'}
       </Descriptions.Item>
       <Descriptions.Item label="Address">{farmData.address}</Descriptions.Item>
       <Descriptions.Item label="Email">{farmData.email}</Descriptions.Item>
      </Descriptions>
     </Col>
     <Col xs={24} md={12}>
      <Descriptions column={1} bordered>
       <Descriptions.Item label="Client code">{farmData.client_code}</Descriptions.Item>
       <Descriptions.Item label="Supply number">{farmData.supply_number}</Descriptions.Item>
       <Descriptions.Item label="Effective farm size (ha)">{farmData.farm_size}</Descriptions.Item>
       <Descriptions.Item label="Herd size (peak)">{farmData.herd_size}</Descriptions.Item>
       <Descriptions.Item label="Number heifers (peak)">{farmData.heifer_size}</Descriptions.Item>
      </Descriptions>
     </Col>
    </Row>
   </div>
  );
 }, [farmData, isEditing, form, onFinish, clinicData, farmVet, vets, clinics, setIsEditing]);

 if (!farmData) {
  return (
   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
    <Spin size="large" />
   </div>
  );
 }


 return (
  <Box
   component="main"
   className="MainContent"
   sx={{
    px: { xs: 2, md: 6 },
    pt: { xs: 'calc(24px + var(--Header-height))', md: 4 },
    pb: { xs: 2, md: 4 },
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    height: '100dvh',
    gap: 2,
   }}
  >
   <Card style={{ borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
    {farmInfoContent}
   </Card>

   <Tabs defaultActiveKey="rvm">
    {renderConsultTab('rvms', 'RVM Consults')}
    {renderConsultTab('milk_quality_consults', 'Milk Quality Consults')}
    {renderConsultTab('wellbeing_consults', 'Wellbeing Consults')}
   </Tabs>

   <Modal
    title="Confirm Delete"
    visible={isDeleteModalVisible}
    onOk={handleDelete}
    onCancel={() => setIsDeleteModalVisible(false)}
    okText="Yes, delete"
    cancelText="Cancel"
   >
    <p>Are you sure you want to delete this farm? This action cannot be undone.</p>
   </Modal>
  </Box>
 );
}
