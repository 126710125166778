/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Button from '@mui/joy/Button';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import TwoSidedLayout from './TwoSidedLayout';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import logo from './logo.png'

export default function Home() {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch(e => {
      console.log(e);
    });
  }

  return (
    <TwoSidedLayout reversed>
      <img src={logo} alt="FVS Dairy Data App" style={
        {
          width: '100%',
          maxWidth: '500px',
          height: 'auto',
          objectFit: 'contain'
        }
      } />
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
        Dairy Data App
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        Streamlining Dairy Farm Consultations and Herd Tracking for the Franklin Vets Team
      </Typography>
      <Button
        size="lg"
        endDecorator={<ArrowForward fontSize="xl" />}
        sx={{ mt: 2, mb: 1 }}
        onClick={() => handleLogin()}
      >
        Login
      </Button>
    </TwoSidedLayout>
  );
}
