import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Switch, message, Typography } from 'antd';
import { Box } from '@mui/joy';
import supabase from '~supabaseConfig';

const { Search } = Input;
const { Title } = Typography;

export default function Vets() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async (search = '') => {
    setLoading(true);
    let query = supabase.from('users').select('*');
    
    if (search) {
      query = query.or(`email.ilike.%${search}%,name.ilike.%${search}%`);
    }

    const { data, error } = await query;

    if (error) {
      console.error('Error fetching users:', error);
      message.error('Failed to fetch users');
    } else {
      setUsers(data);
    }
    setLoading(false);
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    fetchUsers(value);
  };

  const toggleVetStatus = async (record) => {
    const { data, error } = await supabase
      .from('users')
      .update({ vet: !record.vet })
      .eq('id', record.id)
      .select()
      .single();

    if (error) {
      console.error('Error updating vet status:', error);
      message.error('Failed to update vet status');
    } else {
      setUsers(users.map(user => user.id === data.id ? data : user));
      message.success(`Vet status updated for ${data.email}`);
    }
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Vet',
      dataIndex: 'vet',
      key: 'vet',
      render: (vet, record) => (
        <Switch
          checked={vet}
          onChange={() => toggleVetStatus(record)}
        />
      ),
      sorter: (a, b) => (a.vet === b.vet) ? 0 : a.vet ? -1 : 1,
    },
  ];

  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        px: { xs: 2, md: 6 },
        pt: {
          xs: 'calc(12px + var(--Header-height))',
          sm: 'calc(12px + var(--Header-height))',
          md: 3,
        },
        pb: { xs: 2, sm: 2, md: 3 },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <Title level={2}>Manage Vets</Title>
      <Search
        placeholder="Search users by email or name"
        onSearch={handleSearch}
        style={{ marginBottom: '20px' }}
        enterButton
      />
      <Table
        columns={columns}
        dataSource={users}
        rowKey="id"
        loading={loading}
        pagination={{ pageSize: 10 }}
      />
    </Box>
  );
}