import React, { useCallback } from 'react';
import { Button, Form, Row, Col, Typography } from 'antd';
import LayoutBox from './LayoutBox';
import { debounce } from 'lodash';

const { Title } = Typography;

const FormLayout = ({ title, children, form, onFinish, onLoadRvmData, consultDate, extraContent, saveButton }) => {
  // Debounced autosave function
  const autosave = useCallback(
    debounce(async () => {
      try {
        const values = form.getFieldsValue(); // Retrieve the current form values
        console.log('Autosaving data:', values);
        // Trigger form submission logic for autosave
        if (onFinish) {
          onFinish(values); // Call onFinish with current form values
        }
      } catch (error) {
        console.error('Autosave failed:', error);
      }
    }, 1000), // Adjust the debounce time as needed
    [form, onFinish] // Add onFinish to dependency array
  );

  // Event handler for form value changes
  const handleValuesChange = () => {
    autosave(); // Trigger autosave when form values change
  };

  return (
    <LayoutBox>
      <Row gutter={16} style={{ textAlign: 'left' }}>
        <Col span={12}>
          <Title level={2} style={{ margin: 0 }}>{title}</Title>
        </Col>
        <Col span={12}>
          {onLoadRvmData && (
            consultDate !== '' ? (
              <Button onClick={onLoadRvmData}>Load {consultDate} RVM Consult Data</Button>
            ) : (
              <Button onClick={onLoadRvmData}>Load RVM consult data</Button>
            )
          )}
        </Col>
      </Row>
      <Form 
        form={form} 
        onFinish={onFinish} 
        layout="vertical" 
        onValuesChange={handleValuesChange} // Use onValuesChange for autosave
      >
        {children}
        {extraContent}
        {saveButton !== false && (
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        )}
      </Form>
    </LayoutBox>
  );
};

export default FormLayout;