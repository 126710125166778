const excelValues = (combinedData) => {
 console.log('Combined data in excelValues:', combinedData);
 const { consultData } = combinedData;
 if (!consultData) {
  console.error('consultData is undefined in excelValues');
  return {};
 }

 const { farms, nutrition_data_id, health_data_id, youngstock_health_data_id, behaviour_data_id, environment_data_id } = consultData;

 return {
  Report: {
   B6: farms.farm_name || '',
   B7: farms.supply_number || '',
   B9: farms.vet?.name || '',

   // nutrition
   B12: nutrition_data_id?.bcs_farm_result || '',
   A14: nutrition_data_id?.bcs_plan || '',

   // health
   B19: health_data_id?.udder_health_farm_result || '',
   A21: health_data_id?.udder_health_plan || '',
   B24: health_data_id?.tail_injury_farm_result || '',
   A26: health_data_id?.tail_injury_plan || '',
   B29: health_data_id?.lameness_farm_result || '',
   A32: health_data_id?.lameness_plan || '',
   B35: health_data_id?.amr_farm_result || '',
   A37: health_data_id?.amr_plan || '',
   B40: health_data_id?.mortality_farm_result || '',
   A42: health_data_id?.mortality_plan || '',
   B45: health_data_id?.disease_farm_result || '',
   A47: health_data_id?.disease_plan || '',
   B50: health_data_id?.vaccination_farm_result || '',
   A52: health_data_id?.vaccination_plan || '',
   B55: health_data_id?.cull_cow_preperation_farm_result || '',
   A57: health_data_id?.cull_cow_preperation_plan || '',

   // repro
   B62: health_data_id?.["6_week_in_calf_rate"] || '',
   B63: health_data_id?.not_in_calf_rate || '',
   B64: health_data_id?.mating_length || '',
   A66: health_data_id?.repro_plan || '',

   // calf health 
   B71: youngstock_health_data_id?.calf_health_farm_result || '',
   A73: youngstock_health_data_id?.calf_health_plan || '',
   B76: youngstock_health_data_id?.calf_nutrition_farm_result || '',
   A78: youngstock_health_data_id?.calf_nutrition_plan || '',
   B81: youngstock_health_data_id?.calf_housing_farm_result || '',
   A83: youngstock_health_data_id?.calf_housing_plan || '',

   // youngstock parasite management 
   B88: youngstock_health_data_id?.youngstock_parasite_management_farm_result || '',
   A90: youngstock_health_data_id?.youngstock_parasite_management_plan || '',
   B93: youngstock_health_data_id?.youngstock_lwt_targets_farm_result || '',
   A95: youngstock_health_data_id?.youngstock_lwt_targets_plan || '',
   B98: youngstock_health_data_id?.heifer_health_farm_result || '',
   A100: youngstock_health_data_id?.heifer_health_plan || '',
   B103: youngstock_health_data_id?.youngstock_grazing_farm_result || '',
   A105: youngstock_health_data_id?.youngstock_grazing_plan || '',
   B108: youngstock_health_data_id?.heifer_mating_farm_result || '',
   A110: youngstock_health_data_id?.heifer_mating_plan || '',

   // behaviour 
   B115: behaviour_data_id?.behaviour_result?.join(', ') || '',
   A117: behaviour_data_id?.behaviour_plan || '',
   // environment
   B122: environment_data_id?.environment_result?.includes('Heavy rain') ? '✓' : '',
   B123: environment_data_id?.environment_result?.includes('Floods') ? '✓' : '',
   B124: environment_data_id?.environment_result?.includes('Strong winds') ? '✓' : '',
   B125: environment_data_id?.environment_result?.includes('Drought') ? '✓' : '',
   B126: environment_data_id?.environment_result?.includes('Snow cold') ? '✓' : '',
   C122: environment_data_id?.environment_plan || '',

   // heat mitigation 
   B129: environment_data_id?.heat_mitigation_result?.includes('Low') ? '✓' : '',
   B130: environment_data_id?.heat_mitigation_result?.includes('Medium') ? '✓' : '',
   B131: environment_data_id?.heat_mitigation_result?.includes('High') ? '✓' : '',
   B132: environment_data_id?.heat_mitigation_result?.includes('Extreme') ? '✓' : '',
   C129: environment_data_id?.heat_mitigation_plan || '',

   // managing ghg emissions 
   C135: environment_data_id?.ghg_emissions_1 || '',
   C136: environment_data_id?.ghg_emissions_2 || '',
   C137: environment_data_id?.ghg_emissions_3 || '',
   C138: environment_data_id?.ghg_emissions_4 || '',
   C139: environment_data_id?.ghg_emissions_5 || '',

   // sign off details 
   B142: farms.vet?.name || '',
  }
 };
};

export default excelValues;
