import React, { useState, useEffect } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { Link } from 'react-router-dom';
import supabase from '~supabaseConfig';
import { closeSidebar } from '../utils';

import { GlobalStyles, Avatar, Box, Divider, IconButton, Input, List, ListItem, ListItemButton, ListItemContent, Typography, Sheet } from '@mui/joy';
import { listItemButtonClasses } from '@mui/joy/ListItemButton';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import DescriptionIcon from '@mui/icons-material/Description';

import Logo from '../assets/logo.png';

const ADMIN_EMAILS = ['ijennens@fvs.co.nz', 'ilysej@fvs.co.nz', 'hamishtest@fvs.co.nz'];

const SidebarOverlay = () => (
 <Box
  className="Sidebar-overlay"
  sx={{
   position: 'fixed',
   zIndex: 9998,
   top: 0,
   left: 0,
   width: '100vw',
   height: '100vh',
   opacity: 'var(--SideNavigation-slideIn)',
   backgroundColor: 'var(--joy-palette-background-backdrop)',
   transition: 'opacity 0.4s',
   transform: {
    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
    lg: 'translateX(-100%)',
   },
  }}
  onClick={() => closeSidebar()}
 />
);

const SidebarHeader = () => (
 <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', flexDirection: 'column' }}>
  <img
   src={Logo}
   alt="FVS Logo"
   style={{ height: 'auto', width: '75px', maxWidth: '200px', marginBottom: '8px' }}
  />
  <Typography level="title-lg">Dairy Data App</Typography>
 </Box>
);

const SearchBox = ({ searchQuery, handleSearchChange, isSearchDropdownOpen, searchResults }) => (
 <Box sx={{ position: 'relative' }}>
  <Input
   size="sm"
   startDecorator={<SearchRoundedIcon />}
   placeholder="Search"
   value={searchQuery}
   onChange={handleSearchChange}
  />
  {isSearchDropdownOpen && searchResults.length > 0 && (
   <Box
    sx={{
     position: 'absolute',
     top: '100%',
     left: 0,
     right: 0,
     zIndex: 1,
     backgroundColor: 'background.surface',
     borderRadius: 'sm',
     boxShadow: 'md',
     mt: 1,
    }}
   >
    <List>
     {searchResults.map((farm) => (
      <ListItem key={farm.id}>
       <ListItemButton component={Link} to={`/farm/${farm.id}`}>
        {farm.farm_name}
       </ListItemButton>
      </ListItem>
     ))}
    </List>
   </Box>
  )}
 </Box>
);

const NavigationList = ({ isAdmin }) => (
 <Box
  sx={{
   minHeight: 0,
   overflow: 'hidden auto',
   flexGrow: 1,
   display: 'flex',
   flexDirection: 'column',
   [`& .${listItemButtonClasses.root}`]: {
    gap: 1.5,
   },
  }}
 >
  <List
   size="sm"
   sx={{
    gap: 1,
    '--List-nestedInsetStart': '30px',
    '--ListItem-radius': (theme) => theme.vars.radius.sm,
   }}
  >
   <ListItem>
    <ListItemButton component='a' href='/'>
     <HomeRoundedIcon />
     <ListItemContent>
      <Typography level="title-sm">
       Home
      </Typography>
     </ListItemContent>
    </ListItemButton>
   </ListItem>
     <ListItem>
      <ListItemButton component="a" href="/reports">
       <DescriptionIcon />
       <ListItemContent>
        <Typography level="title-sm">
         Reports
        </Typography>
       </ListItemContent>
      </ListItemButton>
     </ListItem>
   {isAdmin && (
    <>
     <ListItem>
      <ListItemButton component="a" href="/settings">
       <SettingsRoundedIcon />
       <ListItemContent>
        <Typography level="title-sm">
         Settings
        </Typography>
       </ListItemContent>
      </ListItemButton>
     </ListItem>
    </>
   )}
  </List>
 </Box>
);

const UserInfo = ({ user, profileImageSrc, handleLogout }) => (
 <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
  <Avatar
   variant="outlined"
   size="sm"
   src={profileImageSrc}
  />
  <Box sx={{ minWidth: 0, flex: 1 }}>
   <Typography level="title-sm">{user.name}</Typography>
   <Typography level="body-xs">{user.username}</Typography>
  </Box>
  <IconButton size="sm" variant="plain" color="neutral" onClick={handleLogout}>
   <LogoutRoundedIcon />
  </IconButton>
 </Box>
);

const Sidebar = () => {
 const { instance, accounts } = useMsal();
 const user = useAccount(accounts[0]);
 const [profileImageSrc, setProfileImageSrc] = useState(null);
 const [searchQuery, setSearchQuery] = useState('');
 const [searchResults, setSearchResults] = useState([]);
 const [isAdmin, setIsAdmin] = useState(false);
 const [isSearchDropdownOpen, setIsSearchDropdownOpen] = useState(false);

 useEffect(() => {
  if (accounts.length > 0 && user) {
   fetchProfilePhoto(user);
   checkAdminStatus();
  }
 }, [accounts, user]);

 useEffect(() => {
  document.addEventListener('click', handleClickOutside);
  return () => {
   document.removeEventListener('click', handleClickOutside);
  };
 }, []);

 const checkAdminStatus = async () => {
  try {
   if (user && user.username) {
    const { data, error } = await supabase
     .from('users')
     .select('admin, super_admin')
     .eq('email', user.username.toLowerCase())
     .single();

    if (error) throw error;

    const isAdminUser = data.admin || data.super_admin || ADMIN_EMAILS.includes(user.username.toLowerCase());

    setIsAdmin(isAdminUser);
    console.log('Admin status:', isAdminUser);
   }
  } catch (error) {
   console.error('Error checking admin status:', error);
  }
 };

 const handleClickOutside = (event) => {
  if (event.target.closest('.search-dropdown') === null) {
   setIsSearchDropdownOpen(false);
  }
 };

 const handleSearchChange = async (event) => {
  const query = event.target.value;
  setSearchQuery(query);
  setIsSearchDropdownOpen(query.length > 0);

  if (query.length > 0) {
   try {
    const { data, error } = await supabase
     .from('farms')
     .select('id, farm_name')
     .ilike('farm_name', `%${query}%`)
     .limit(5);

    if (error) throw error;
    setSearchResults(data);
   } catch (error) {
    console.error('Error searching farms:', error);
   }
  } else {
   setSearchResults([]);
  }
 };

 const handleLogout = async () => {
  try {
   await supabase.auth.signOut();
   instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  } catch (error) {
   console.error('Error during logout:', error);
  }
 };

 const fetchProfilePhoto = async (account) => {
  try {
   const response = await instance.acquireTokenSilent({
    scopes: ["User.Read"],
    account,
   });

   const photoResponse = await fetch(
    "https://graph.microsoft.com/v1.0/me/photos/48x48/$value",
    {
     method: "GET",
     headers: {
      Authorization: `Bearer ${response.accessToken}`,
     },
    }
   );

   if (photoResponse.ok) {
    const blob = await photoResponse.blob();
    const url = URL.createObjectURL(blob);
    setProfileImageSrc(url);
   } else {
    console.error("Error fetching profile photo");
   }
  } catch (error) {
   if (error instanceof InteractionRequiredAuthError) {
    instance.acquireTokenRedirect({ scopes: ["User.Read"] });
   } else {
    console.error("Error acquiring access token", error);
   }
  }
 };

 return (
  <Sheet
   className="Sidebar"
   sx={{
    position: { xs: 'fixed', md: 'sticky' },
    transform: {
     xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
     md: 'none',
    },
    transition: 'transform 0.4s, width 0.4s',
    zIndex: 1000,
    height: '100dvh',
    width: 'var(--Sidebar-width)',
    top: 0,
    p: 2,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    borderRight: '1px solid',
    borderColor: 'divider',
   }}
  >
   <GlobalStyles
    styles={(theme) => ({
     ':root': {
      '--Sidebar-width': '220px',
      [theme.breakpoints.up('lg')]: {
       '--Sidebar-width': '240px',
      },
     },
    })}
   />
   <SidebarOverlay />
   <SidebarHeader />
   <SearchBox
    searchQuery={searchQuery}
    handleSearchChange={handleSearchChange}
    isSearchDropdownOpen={isSearchDropdownOpen}
    searchResults={searchResults}
   />
   <NavigationList isAdmin={isAdmin} />
   <Divider />
   <UserInfo user={user} profileImageSrc={profileImageSrc} handleLogout={handleLogout} />
  </Sheet>
 );
};

export default Sidebar;