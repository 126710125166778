import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Outlet } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ConsultNavbar from '../../consults/components/ConsultNavbar';
import ConsultBottomNav from '../../consults/components/ConsultBottomNav';
import excelValues from './components/excel';
import supabase from '~supabaseConfig';

const cloudflareWorkerUrl = import.meta.env.VITE_CLOUDFLARE_WORKER_WELLBEING_URL;

const fetchWellbeingData = async (tableName, columnName, value) => {
 console.log(`Fetching data from ${tableName} where ${columnName} = ${value}`);
 try {
  const { data, error } = await supabase.from(tableName).select(`
    *,
    farms:farm_id (
      id,
      farm_name,
      farm_size,
      email,
      phone_number,
      address,
      herd_size,
      heifer_size,
      farmer_name,
      clinic_id (
        id,
        clinic_name,
        address
      ),
      vet (
        id,
        name,
        email
      ),
      supply_number
    ),
    nutrition_data_id (*),
    behaviour_data_id (*),
    health_data_id (*),
    environment_data_id (*),
    youngstock_health_data_id (*)
  `)
  .eq(columnName, value)
  .single();

  if (error) {
   console.error('Supabase query error:', error);
   throw error;
  }

  if (!data) {
   console.warn(`No data found for ${columnName} ${value} in ${tableName}`);
   throw new Error(`No data found for ${columnName} ${value} in ${tableName}`);
  }

  console.log('Fetched wellbeing data:', data);
  return data;
 } catch (error) {
  console.error(`Error in fetchWellbeingData for ${tableName}:`, error);
  throw error;
 }
};

const WellbeingLayout = () => {
 const { farmId, wellbeingConsultId } = useParams();

 const navItems = [
  {
   label: <Link to={`/farm/${farmId}/`}><ArrowBackIosIcon /></Link>,
   key: 'Back',
  },
  {
   label: <Link to={`/farm/${farmId}/wellbeing_consult/${wellbeingConsultId}/nutrition`}>Nutrition</Link>,
   key: 'nutrition',
  },
  {
   label: <Link to={`/farm/${farmId}/wellbeing_consult/${wellbeingConsultId}/health`}>Health</Link>,
   key: 'health',
  },
  {
   label: <Link to={`/farm/${farmId}/wellbeing_consult/${wellbeingConsultId}/behaviour`}>Behaviour</Link>,
   key: 'behaviour',
  },
  {
   label: <Link to={`/farm/${farmId}/wellbeing_consult/${wellbeingConsultId}/youngstock-health`}>Youngstock health</Link>,
   key: 'youngstock-health',
  },
  {
   label: <Link to={`/farm/${farmId}/wellbeing_consult/${wellbeingConsultId}/environment`}>Environment</Link>,
   key: 'environment',
  },
 ];

 return (
  <>
   <ConsultNavbar
    items={navItems}
    consultType="wellbeing"
    consultIdParam="wellbeingConsultId"
    supabaseTable="wellbeing_consults"
   />
   <Outlet />
   <ConsultBottomNav
    consultType="wellbeing"
    consultIdParam="wellbeingConsultId"
    supabaseTable="wellbeing_consults"
    cloudflareWorkerUrl={cloudflareWorkerUrl}
    excelGenerator={excelValues}
    reportName="Wellbeing"
    fetchDataFunction={fetchWellbeingData}
   />
  </>
 );
};

export default WellbeingLayout;