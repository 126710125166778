import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Input, Typography, Checkbox, Select, Form, InputNumber, message } from 'antd';
import { Table } from '@mui/joy';
import pageTemplate from '../../../components/pageTemplate';
import FormLayout from '../../../components/FormLayout';

const { Text } = Typography;
const { Option } = Select;

const createData = (name, input1, input2, input3, input4, avg) => ({ name, input1, input2, input3, input4, avg });

const rows = [
  createData('BMSCC', 'bmscc_early_1st_month', 'bmscc_mid_2nd_to_5th_month', 'bmscc_late_6th_to_10th_month', null, 'bmscc_average'),
  createData('Clinical Mastitis - Cows', 'cow_mastitis_early_1st_month', 'cow_mastitis_mid_2nd_to_5th_month', 'cow_mastitis_late_6th_to_10th_month', 'cow_mastitis_dry_period', 'cow_mastitis_average'),
  createData('Clinical Mastitis - Heifers', 'heifer_mastitis_early_1st_month', 'heifer_mastitis_mid_2nd_to_5th_month', 'heifer_mastitis_late_6th_to_10th_month', 'heifer_mastitis_dry_period', 'heifer_mastitis_average'),
  createData('% Cows with iSCC < 150,000', 'cow_iscc_early_1st_month', 'cow_iscc_mid_2nd_to_5th_month', 'cow_iscc_late_6th_to_10th_month', null, 'cow_iscc_average'),
  createData('% Heifers with iSCC < 150,000', 'heifer_iscc_bmscc_early_1st_month', 'heifer_iscc_mid_2nd_to_5th_month', 'heifer_iscc_late_6th_to_10th_month', null, null),
];

const LSMQR = () => {
  const { farmId, milkQualityConsultId } = useParams();
  const [herdSize, setHerdSize] = useState(0);
  const [heiferSize, setHeiferSize] = useState(0);
  const [cowMastitisEarly, setCowMastitisEarly] = useState(0);
  const [cowMastitisMid, setCowMastitisMid] = useState(0);
  const [cowMastitisLate, setCowMastitisLate] = useState(0);
  const [cowMastitisDry, setCowMastitisDry] = useState(0);
  const [cowMastitisTotal, setCowMastitisTotal] = useState(0);
  const [heiferMastitisEarly, setHeiferMastitisEarly] = useState(0);
  const [heiferMastitisMid, setHeiferMastitisMid] = useState(0);
  const [heiferMastitisLate, setHeiferMastitisLate] = useState(0);
  const [heiferMastitisDry, setHeiferMastitisDry] = useState(0);
  const [heiferMastitisTotal, setHeiferMastitisTotal] = useState(0);

  const { form, onFinish } = pageTemplate(
    'milk_quality_consult_lsmqr_data',
    'milk_quality_consult_id',
    'lsmqr_id',
    farmId,
    milkQualityConsultId,
    'milk_quality_consult_id',
    'milk_quality_consults'
  );

  // useEffect(() => {
  //   const fetchFarmData = async () => {
  //     const { data: farmData, error: farmError } = await supabase
  //       .from('farms')
  //       .select('herd_size, heifer_size')
  //       .eq('id', farmId);

  //     if (farmError) {
  //       console.error('Error fetching farm data:', farmError);
  //       return;
  //     }

  //     if (farmData && farmData.length > 0) {
  //       if (farmData[0].herd_size > 0) {
  //         setHerdSize(farmData[0].herd_size);
  //       }
  //       if (farmData[0].heifer_size > 0) {
  //         setHeiferSize(farmData[0].heifer_size);
  //       }
  //     } else {
  //       console.log('No farm data found.');
  //     }
  //   };

  //   fetchFarmData();
  // }, [farmId]);

  // useEffect(() => {
  //   const cowTotal = cowMastitisEarly + cowMastitisMid + cowMastitisLate;
  //   setCowMastitisTotal(cowTotal);

  //   const heiferTotal = heiferMastitisEarly + heiferMastitisMid + heiferMastitisLate;
  //   setHeiferMastitisTotal(heiferTotal);
  // }, [
  //   cowMastitisEarly,
  //   cowMastitisMid,
  //   cowMastitisLate,
  //   heiferMastitisEarly,
  //   heiferMastitisMid,
  //   heiferMastitisLate,
  // ]);

  const LSMQRTable = () => (
    <Table variant="soft" borderAxis="bothBetween">
      <thead>
        <tr>
          <th style={{ width: '40%', textAlign: 'center' }}>{null}</th>
          <th style={{ width: '20%', textAlign: 'center' }}>Early - 1st Month</th>
          <th style={{ width: '20%', textAlign: 'center' }}>Mid - 2nd to 5th Month</th>
          <th style={{ width: '20%', textAlign: 'center' }}>Late - 6th to 10th Month</th>
          <th style={{ width: '20%', textAlign: 'center' }}>Average/Total</th>
          <th style={{ width: '20%', textAlign: 'center' }}>Dry period</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.name}>
            <th scope="row" style={{ textAlign: 'center' }}>{row.name}</th>
            {[row.input1, row.input2, row.input3].map((input, index) => (
              <td key={input} style={{ textAlign: 'center' }}>
                <Form.Item name={input}>
                  <InputNumber
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      const setterFunction = {
                        'cow_mastitis_early_1st_month': setCowMastitisEarly,
                        'cow_mastitis_mid_2nd_to_5th_month': setCowMastitisMid,
                        'cow_mastitis_late_6th_to_10th_month': setCowMastitisLate,
                        'heifer_mastitis_early_1st_month': setHeiferMastitisEarly,
                        'heifer_mastitis_mid_2nd_to_5th_month': setHeiferMastitisMid,
                        'heifer_mastitis_late_6th_to_10th_month': setHeiferMastitisLate,
                      }[input];
                      if (setterFunction) {
                        setterFunction(value);
                      }
                      form.setFieldsValue({ [input]: value });
                    }}
                  />
                </Form.Item>
                {(row.name.includes('Clinical Mastitis') && (herdSize !== 0 || heiferSize !== 0)) && (
                  <Text>
                    {((form.getFieldValue(input) / (row.name.includes('Cows') ? herdSize : heiferSize)) * 100).toFixed(2)}%
                  </Text>
                )}
              </td>
            ))}
            <td style={{ textAlign: 'center' }}>
              {row.avg && (
                <Form.Item name={row.avg} style={{ textAlign: 'center' }}>
                  <InputNumber 
                    readOnly={row.name.includes('Clinical Mastitis')}
                    value={row.name === 'Clinical Mastitis - Cows' ? cowMastitisTotal : row.name === 'Clinical Mastitis - Heifers' ? heiferMastitisTotal : undefined}
                  />
                </Form.Item>
              )}
              {row.name.includes('Clinical Mastitis') && (
                <Text>
                  {(((row.name === 'Clinical Mastitis - Cows' ? cowMastitisTotal : heiferMastitisTotal) / (row.name.includes('Cows') ? herdSize : heiferSize)) * 100).toFixed(2)}%
                </Text>
              )}
            </td>
            <td style={{ textAlign: 'center' }}>
              {row.input4 && (
                <Form.Item name={row.input4}>
                  <InputNumber
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      const setterFunction = {
                        'cow_mastitis_dry_period': setCowMastitisDry,
                        'heifer_mastitis_dry_period': setHeiferMastitisDry,
                      }[row.input4];
                      if (setterFunction) {
                        setterFunction(value);
                      }
                      form.setFieldsValue({ [row.input4]: value });
                    }}
                  />
                </Form.Item>
              )}
              {row.name.includes('Clinical Mastitis') && (row.input4 && (herdSize !== 0 || heiferSize !== 0)) && (
                <Text>
                  {((form.getFieldValue(row.input4) / (row.name.includes('Cows') ? herdSize : heiferSize)) * 100).toFixed(2)}%
                </Text>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  const formContent = (
    <>
      <LSMQRTable />
      <Form.Item label="Number of cows with repeated mastitis treatments:" name="number_of_cows_with_repeated_mastitis_treatments">
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item label="Number of cows culled for mastitis or high SCC:" name="number_of_cows_culled_for_mastitis_or_scc">
        <InputNumber />
      </Form.Item>
      <Form.Item label="Primary pathogens detected:" name="pathogens_detected">
        <Select mode="multiple" allowClear placeholder="Select options">
          <Option value="environmental">Environmental</Option>
          <Option value="commensal">Commensal</Option>
          <Option value="contagious">Contagious</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Mastitis control measures in place:" name="mastitis_control_measures">
        <Select mode="multiple" allowClear placeholder="Select options">
          <Option value="Machine test">Machine test</Option>
          <Option value="Teat scoring">Teat scoring</Option>
          <Option value="Linear changes within recommendations">Linear changes within recommendations</Option>
          <Option value="Teat spray - every cow, every milking">Teat spray - every cow, every milking</Option>
          <Option value="Detection of clinical mastitis">Detection of clinical mastitis</Option>
          <Option value="Environmental risk assessment (yard, races, feedpad, crossings)">Environmental risk assessment (yard, races, feedpad, crossings)</Option>
        </Select>
      </Form.Item>
    </>
  );

  return (
    <FormLayout 
      title="LSMQR" 
      form={form} 
      onFinish={onFinish}
      extraContent={formContent}
      saveButton={true}
    />
  );
};

export default LSMQR;