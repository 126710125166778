import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Outlet } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ConsultNavbar from '../../consults/components/ConsultNavbar';
import ConsultBottomNav from '../../consults/components/ConsultBottomNav';
import excelValues from './components/excel'; // You might need to adjust this import path
import supabase from '~supabaseConfig'; // You might need to adjust this import path

const cloudflareWorkerUrl = import.meta.env.VITE_CLOUDFLARE_WORKER_MILK_QUALITY_URL; // Adjust this as needed

const fetchMilkQualityData = async (tableName, columnName, value) => {
 console.log(`Fetching data from ${tableName} where ${columnName} = ${value}`);
 try {
  const { data, error } = await supabase.from(tableName).select(`
    *,
    farms:farm_id (
      id,
      farm_name,
      farm_size,
      email,
      phone_number,
      address,
      herd_size,
      heifer_size,
      farmer_name,
      clinic_id (
        id,
        clinic_name,
        address
      ),
      vet (
        id,
        name,
        email
      ),
      supply_number
    ),
    lsmqr_id (*),
   dry_cow_id (*),
  `)
   .eq(columnName, value)
   .single();

  if (error) {
   console.error('Supabase query error:', error);
   throw error;
  }

  if (!data) {
   console.warn(`No data found for ${columnName} ${value} in ${tableName}`);
   throw new Error(`No data found for ${columnName} ${value} in ${tableName}`);
  }

  console.log('Fetched wellbeing data:', data);
  return data;
 } catch (error) {
  console.error(`Error in fetchWellbeingData for ${tableName}:`, error);
  throw error;
 }
};

const MilkQualityLayout = () => {
 const { farmId, milkQualityConsultId } = useParams();

 const navItems = [
  {
   label: <Link to={`/farm/${farmId}/`}>
    <ArrowBackIosIcon />
   </Link>,
   key: 'Back',
  },
  {
   label: <Link to={`/farm/${farmId}/milk_quality_consult/${milkQualityConsultId}/LSMQR`}>LSMQR</Link>,
   key: 'lsmqr',
  },
  {
   label: <Link to={`/farm/${farmId}/milk_quality_consult/${milkQualityConsultId}/DryCowProtocol`}>Dry Cow Protcol</Link>,
   key: 'drycowprotocol',
  },
 ];

 return (
  <>
   <ConsultNavbar
    items={navItems}
    consultType="milk_quality_consults"
    consultIdParam="milkQualityConsultId"
    supabaseTable="milk_quality_consults"
   />
   <Outlet />
   <ConsultBottomNav
    consultType="milk_quality_consults"
    consultIdParam="milkQualityConsultId"
    supabaseTable="milk_quality_consults"
    cloudflareWorkerUrl={cloudflareWorkerUrl}
    excelGenerator={excelValues}
    reportName="Milk Quality Consult"
    fetchDataFunction={fetchMilkQualityData}
   />
  </>
 );
};

export default MilkQualityLayout;