import { useState, useEffect } from 'react';
import { Form, message } from 'antd';
import supabase from '~supabaseConfig';
import { v4 as uuidv4 } from 'uuid';

const pageTemplate = (supabaseTableName, consultSupabaseTable, supabaseTableId, farmId, consultId, consultIdName, consultType) => {
 const [form] = Form.useForm();
 const [formInitialized, setFormInitialized] = useState(false);

 message.config({
  top: 100,
  duration: 2,
  maxCount: 1,
 });

 useEffect(() => {
  const fetchData = async () => {
   try {
    const { data, error } = await supabase
     .from(supabaseTableName)
     .select()
     .eq(consultSupabaseTable, consultId);

    if (error) {
     throw new Error(`Error fetching existing data: ${error.message}`);
    }

    if (data && data.length > 0) {
     console.log('Existing data:', data);
     if (!formInitialized) {
      form.setFieldsValue({
       ...data[0],
      });
      setFormInitialized(true);
     }
    } else {
     message.warning('No existing data found.');
    }
   } catch (error) {
    console.error('Error fetching existing data:', error);
    message.error('Error fetching existing data. Please try again later.');
   }
  };

  fetchData();
 }, [farmId, consultId, form, formInitialized, supabaseTableName, consultSupabaseTable]);

 const onFinish = async (values) => {
  console.log('on finish working');
  console.log(values);
  try {
   const formattedValues = {
    ...values,
   };

   const { data: existingData, error: fetchError } = await supabase
    .from(supabaseTableName)
    .select('id')
    .eq(consultSupabaseTable, consultId)
    .limit(1);

   if (fetchError) {
    throw new Error(`Error checking existing data: ${fetchError.message}`);
   }

   let recordId;

   if (existingData && existingData.length > 0) {
    // Update the existing record
    recordId = existingData[0].id;
    const { error: updateError } = await supabase
     .from(supabaseTableName)
     .update(formattedValues)
     .eq('id', recordId);

    if (updateError) {
     throw new Error(`Error updating data: ${updateError.message}`);
    }

    message.success('Data updated successfully');
   } else {
    // Generate a UUID for the new record
    recordId = uuidv4();

    // Insert a new record into the table
    const { error: insertError } = await supabase.from(supabaseTableName).insert([
     { ...formattedValues, [consultIdName]: consultId, id: recordId },
    ]);

    if (insertError) {
     throw new Error(`Error saving data: ${insertError.message}`);
    }

    message.success('Data saved successfully');
   }

   // Check if the consult table has the correct data ID
   const { data: consultData, error: consultError } = await supabase
    .from(consultType)
    .select(supabaseTableId)
    .eq('id', consultId)
    .limit(1);

   if (consultError) {
    throw new Error(`Error checking ${consultType}: ${consultError.message}`);
   }

   if (consultData && consultData.length > 0 && consultData[0][supabaseTableId] !== recordId) {
    // Update the data ID in the consult table
    const { error: updateConsultError } = await supabase
     .from(consultType)
     .update({ [supabaseTableId]: recordId })
     .eq('id', consultId);

    if (updateConsultError) {
     throw new Error(`Error updating ${consultType} with data ID: ${updateConsultError.message}`);
    }
   }
  } catch (error) {
   console.error('Error saving or updating data:', error);
   message.error('Error saving or updating data. Please try again later.');
  }
 };

 return { form, onFinish };
};

export default pageTemplate;