import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Row, Col, Divider, Input, message, Typography, Select } from 'antd';
import { useParams } from 'react-router-dom';
import pageTemplate from '../../components/pageTemplate';
import FormRow from './components/FormRow';
import supabase from '~supabaseConfig';
import LayoutBox from '../../components/LayoutBox';
import FormItem from 'antd/es/form/FormItem';
import FormLayout from '../../components/FormLayout';

const { Title, Text } = Typography;
const { Option } = Select;

const WellbeingHealth = () => {
 const supabaseTableName = 'wellbeing_consult_health_data';
 const consultSupabaseTable = 'wellbeing_consult_id';
 const supabaseTableId = 'health_data_id';
 const consultIdName = 'wellbeing_consult_id';
 const [herdSize, setHerdSize] = useState(null);
 const { farmId, wellbeingConsultId } = useParams();
 const [cannedNotes, setCannedNotes] = useState({});
 const [consultDate, setConsultDate] = useState('')
 const [calfHerdSize, setCalfHerdSize] = useState(null);
 const [rvmData, setRvmData] = useState(null);
 const [isLoading, setIsLoading] = useState(true);
 const [arr, setArr] = useState([
  {
   title: 'Udder health/Mastitis',
   rowName: 'udder_health',
   target: '<10% total season | Average BMSCC <150,000 | <2% clinical cases/month during calving | Days of excellence in milk',
  },
  { title: 'Lameness', rowName: 'lameness', target: '<5% at a single mobility scoring event | <12% total for season ' },
  { title: 'Disease', rowName: 'disease', target: "BVD | Johne's" },
  { title: 'Mortality', rowName: 'mortality', target: '<5% pre-weaned calves | <2% all other stock classes' },
  {
   title: 'AMR',
   rowName: 'amr',
   target: "Antibiotics only for infected cows | Appropriate antibiotic selection | No 'red' milk to calves | No red light antibiotics administered",
  },
  { title: 'Vaccination', rowName: 'vaccination', target: 'Leptospirosis | Clostridial  | Reproductive/Scours Vaccines | BVD' },
  { title: 'Tail injury', rowName: 'tail_injury', target: '<5% prevalence or no increase year on year' },
  { title: 'Cull cow preparation', rowName: 'cull_cow_preperation', target: '' },
 ]);

 const ReproArr = [
  { title: 'Six week in-calf rate', name: '6_week_in_calf_rate', target: '78%' },
  { title: 'Not in-calf rate', name: 'not_in_calf_rate', target: '6%' },
  { title: 'Mating length', name: 'mating_length', target: '<12 Weeks' },
 ];

 const fetchRvmData = async (rvmId) => {
  try {
   const [rvmMilkQualityResponse, rvmHealthResponse, rvmReproResponse, rvmFarmObjectivesResponse] = await Promise.all([
    supabase.from('rvm_milk_quality_data').select('*').eq('rvm_id', rvmId),
    supabase.from('rvm_health_data').select('*').eq('rvm_id', rvmId),
    supabase.from('rvm_repro_data').select('*').eq('rvm_id', rvmId),
    supabase.from('rvm_farm_objectives_data').select('*').eq('rvm_id', rvmId),
   ]);

   const rvmMilkQualityData = rvmMilkQualityResponse.data.length > 0 ? rvmMilkQualityResponse.data[0] : null;
   const rvmHealthData = rvmHealthResponse.data.length > 0 ? rvmHealthResponse.data[0] : null;
   const rvmReproData = rvmReproResponse.data.length > 0 ? rvmReproResponse.data[0] : null;
   const rvmFarmObjectives = rvmFarmObjectivesResponse.data.length > 0 ? rvmFarmObjectivesResponse.data[0] : null;


   return {
    rvmMilkQualityData,
    rvmHealthData,
    rvmReproData,
    rvmFarmObjectives,
   };
  } catch (error) {
   console.error('Error fetching RVM data:', error);
   message.error('Error fetching RVM data. Please try again later.');
   return null;
  }
 };

 // const calculatePercentage = (value, total) => {
 //  if (value === null || total === null || total === 0) {
 //   return null;
 //  }
 //  return ((value / total) * 100).toFixed(2) + '%';
 // };

 // const fillFormFields = () => {
 //  if (!rvmData) {
 //   message.warning('RVM data not available. Please try again later.');
 //   return;
 //  }

 //  const { rvmMilkQualityData, rvmHealthData, rvmReproData, rvmFarmObjectives } = rvmData;

 //  const loadedFields = [];

  // const fillFormFields = () => {
  //  if (!rvmData) {
  //   message.warning('RVM data not available. Please try again later.');
  //   return;
  //  }

  //  console.log('rvm data')
  //  console.log(rvmData)


  //  const { rvmMilkQualityData, rvmHealthData, rvmReproData, rvmFarmObjectives } = rvmData;

  //  const loadedFields = [];

  //  if (rvmMilkQualityData && rvmMilkQualityData.clinical_mastitis !== null && herdSize !== null) {
  //   const percentage = calculatePercentage(rvmMilkQualityData.clinical_mastitis, herdSize);
  //   form.setFieldsValue({ udder_health_farm_result: percentage });
  //   loadedFields.push('Udder Health Farm Result');
  //   console.log(percentage);
  //  }

  //  if (rvmMilkQualityData && rvmMilkQualityData.plan) {
  //   form.setFieldsValue({ udder_health_plan: rvmMilkQualityData.plan });
  //   loadedFields.push('Udder Health Plan');
  //  }

  //  if (rvmHealthData && rvmHealthData.cow_lame !== null && herdSize !== null) {
  //   const percentage = calculatePercentage(rvmHealthData.cow_lame, herdSize);
  //   form.setFieldsValue({ lameness_farm_result: percentage });
  //   loadedFields.push('Lameness Farm Result');
  //  }

  //  if (rvmHealthData && rvmHealthData.cow_lame_plan) {
  //   form.setFieldsValue({ lameness_plan: rvmHealthData.cow_lame_plan });
  //   loadedFields.push('Lameness Plan');
  //  }

  //  if (rvmHealthData && rvmHealthData.cow_deaths !== null && herdSize !== null) {
  //   const percentage = calculatePercentage(rvmHealthData.cow_deaths, herdSize);
  //   form.setFieldsValue({ mortality_farm_result: percentage });
  //   loadedFields.push('Mortality Farm Result');
  //  }

  //  if (rvmHealthData && rvmHealthData.calf_deaths !== null && calfHerdSize !== null) {
  //   const percentage = calculatePercentage(rvmHealthData.calf_deaths, calfHerdSize);
  //   form.setFieldsValue({ calf_mortality_farm_result: percentage });
  //   loadedFields.push('Calf Mortality Farm Result');
  //  }

  //  if (rvmHealthData && rvmHealthData.cow_death_plan) {
  //   form.setFieldsValue({ mortality_plan: rvmHealthData.cow_death_plan });
  //   loadedFields.push('Mortality Plan');
  //  }

  //  if (rvmReproData && rvmReproData.six_week_icr !== null) {
  //   form.setFieldsValue({ '6_week_in_calf_rate': rvmReproData.six_week_icr });
  //   loadedFields.push('Six Week In-Calf Rate');
  //  }

  //  if (rvmReproData && rvmReproData.mating_length_weeks !== null) {
  //   form.setFieldsValue({ mating_length: rvmReproData.mating_length_weeks });
  //   loadedFields.push('Mating Length');
  //  }

  //  if (rvmReproData && rvmReproData.empty_rate !== null) {
  //   form.setFieldsValue({ not_in_calf_rate: rvmReproData.empty_rate });
  //   loadedFields.push('Not In-Calf Rate');
  //  }

  //  if (rvmHealthData && rvmHealthData.services) {
  //   const vaccinations = rvmHealthData.services
  //    .filter((service) => ['salvexin', 'rotavec', 'covexin'].includes(service))
  //    .map((service) => service.charAt(0).toUpperCase() + service.slice(1));
  //   form.setFieldsValue({ vaccination_farm_result: vaccinations.join(', ') });
  //   loadedFields.push('Vaccination Farm Result');
  //  }


  //  if (loadedFields.length > 0) {
  //   message.success(`Data loaded successfully for: ${loadedFields.join(', ')}`);
  //  } else {
  //   message.info('No data available to load');
  //  }
  // };

 //  if (loadedFields.length > 0) {
 //   message.success(`Data loaded successfully for: ${loadedFields.join(', ')}`);
 //  } else {
 //   message.info('No data available to load');
 //  }
 // };

 const { form, onFinish } = pageTemplate(
  supabaseTableName,
  consultSupabaseTable,
  supabaseTableId,
  farmId,
  wellbeingConsultId,
  consultIdName,
  'wellbeing_consults'
 );

 const fetchCannedNotes = useCallback(async () => {
  if (Object.keys(cannedNotes).length > 0) return;
  // console.log('Fetching canned notes...');
  try {
   const categories = arr.map(item => item.title);
   // console.log('Categories we are looking for:', categories);
   const groupedNotes = {};

   const { data: allCategories, error: categoriesError } = await supabase
    .from('wellbeing_canned_notes_categories')
    .select('id, category');

   if (categoriesError) throw categoriesError;

   // console.log('All categories from database:', allCategories.map(c => c.category));

   for (const category of categories) {
    // console.log(`Searching for category: ${category}`);

    const categoryData = allCategories.find(c =>
     c.category.toLowerCase().trim() === category.toLowerCase().trim() ||
     c.category.toLowerCase().trim().startsWith(category.toLowerCase().trim())
    );

    if (!categoryData) {
     // console.log(`Category "${category}" not found in database`);
     continue;
    }

    // console.log(`Found category: ${categoryData.category} with id: ${categoryData.id}`);

    const { data, error } = await supabase
     .from('wellbeing_canned_notes')
     .select(`
       id,
       note,
       category (
         id,
         category
       )
     `)
     .eq('category', categoryData.id);

    if (error) {
     console.error(`Error fetching notes for ${category}:`, error);
     continue;
    }

    if (data && data.length > 0) {
     groupedNotes[category] = data.map(item => item.note);
     // console.log(`Found ${data.length} notes for category "${category}"`);
    } else {
     // console.log(`No notes found for category "${category}"`);
    }
   }

   // console.log('Final grouped notes:', groupedNotes);
   setCannedNotes(groupedNotes);

   setArr(prevArr => prevArr.map(item => ({
    ...item,
    notes: groupedNotes[item.title] || [],
   })));

  } catch (error) {
   console.error('Error in fetchCannedNotes:', error);
  }
 }, [cannedNotes, arr]);

 useEffect(() => {
  const fetchAllData = async () => {
   setIsLoading(true);
   await fetchCannedNotes();

   const { data: wellbeingConsultData, error: consultError } = await supabase
    .from('wellbeing_consults')
    .select('*')
    .eq('id', wellbeingConsultId)
    .single();

   if (consultError) {
    console.error('Error fetching wellbeing consult data:', consultError);
    setIsLoading(false);
    return;
   }

   if (wellbeingConsultData) {
    const { start_date, end_date } = wellbeingConsultData;
    setConsultDate(`${start_date} - ${end_date}`);
    const { data: rvmData, error: rvmError } = await supabase
     .from('rvms')
     .select('*')
     .eq('start_date', start_date)
     .eq('farm_id', farmId)
     .eq('end_date', end_date)
     .single();

    if (rvmError) {
     console.error('Error fetching RVM data:', rvmError);
     setIsLoading(false);
     return;
    }

    if (rvmData) {
     // console.log('rvm data', rvmData);
     const rvmDetails = await fetchRvmData(rvmData.id);
     setRvmData(rvmDetails);

     if (rvmDetails.rvmFarmObjectives && rvmDetails.rvmFarmObjectives.herd_size !== null) {
      setHerdSize(rvmDetails.rvmFarmObjectives.herd_size);
     }

     if (rvmDetails.rvmFarmObjectives && rvmDetails.rvmFarmObjectives.calves_reared !== null) {
      setCalfHerdSize(rvmDetails.rvmFarmObjectives.calves_reared);
     }
    }
   }
   setIsLoading(false);
  };

  fetchAllData();
 }, [fetchCannedNotes, farmId, wellbeingConsultId]);

 const handleNoteChange = (value, rowName) => {
  form.setFieldsValue({ [rowName]: value.join('\n') });
 };

 if (isLoading) return <div>Loading...</div>;

 // console.log('Rendering WellbeingHealth with cannedNotes:', cannedNotes);

 return (
  <FormLayout
   title="Health"
   form={form}
   onFinish={onFinish}
   consultDate={consultDate}
   // onLoadRvmData={fillFormFields}
   extraContent={
    <>
     <Row gutter={16}>
      <Title level={3}>Reproduction</Title>
     </Row>
     <Row gutter={16} style={{ textAlign: 'left' }}>
      {ReproArr.map((item) => (
       <Col key={item.title} span={8} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left', paddingLeft: 0 }}>
        <Title level={4}>{item.title}</Title>
        <FormItem label="Result" name={item.name}>
         <Input />
        </FormItem>
        <Title level={5}>Industry target</Title>
        <Text>{item.target}</Text>
       </Col>
      ))}
     </Row>
     <Row gutter={16}>
      <Title level={5}>Plan</Title>
     </Row>
     <Row gutter={16} style={{ textAlign: 'left' }}>
      <Col span={18}>
       <FormItem name={'repro_plan'}>
        <Input.TextArea
         size="large"
         style={{ margin: 0, height: 120, width: '100%' }}
     
         placeholder="Type notes"
         onChange={(e) => form.setFieldsValue({ [`${rowName}_plan`]: e.target.value })}
        />
       </FormItem>
      </Col>
     </Row>
     <Divider />
    </>
   }
  >
   {arr.map((row) => (
    <FormRow
     key={row.rowName}
     title={row.title}
     rowName={row.rowName}
     notes={cannedNotes[row.title] || []}
     target={row.target}
     form={form}
    >
     <Form.Item label="Canned Notes">
      <Select
       mode="multiple"
       style={{ width: '100%' }}
       placeholder="Select notes"
       onChange={(value) => handleNoteChange(value, row.rowName)}
      >
       {(cannedNotes[row.title] || []).map((note, index) => (
        <Option key={index} value={note}>
         {note}
        </Option>
       ))}
      </Select>
     </Form.Item>
    </FormRow>
   ))}
  </FormLayout>
 );
};

export default WellbeingHealth;