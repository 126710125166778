import { Box } from '@mui/joy';
import React from 'react';

export default function LayoutBox({ children, widthDeclaration }) {
  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        px: { xs: 2, md: 6 },
        pt: {
          xs: 'calc(12px + var(--Header-height))',
          sm: 'calc(12px + var(--Header-height))',
          md: 3,
        },
        pb: { xs: 2, sm: 2, md: 3 },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        
        width: widthDeclaration ? widthDeclaration : '70%',
       }}
    >
      {children}
    </Box>
  );
}
