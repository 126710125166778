import React, { useState, useEffect } from 'react';
import { Table, Form, Input, Button, Space, Modal, Select, InputNumber, Flex } from 'antd';
import supabase from '~supabaseConfig';

const { Option } = Select;

const RvmItemsCRUD = () => {
 const [items, setItems] = useState([]);
 const [form] = Form.useForm();
 const [editingId, setEditingId] = useState(null);
 const [isModalVisible, setIsModalVisible] = useState(false);
 const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
 const [itemToDelete, setItemToDelete] = useState(null);
 const [searchQuery, setSearchQuery] = useState('');

 useEffect(() => {
  fetchItems();
 }, []);

 const fetchItems = async () => {
  const { data, error } = await supabase.from('rvm_items').select('*');
  if (error) console.error('Error fetching items:', error);
  else setItems(data);
 };

 const handleSearchChange = (e) => {
  console.log(e)
  setSearchQuery(e.target.value);
 };

 const filteredRvmItems = items.filter(item => item.item.toLowerCase().includes(searchQuery.toLowerCase()));

 const onFinish = async (values) => {
  if (editingId) {
   const { error } = await supabase
    .from('rvm_items')
    .update(values)
    .eq('id', editingId);
   if (error) console.error('Error updating item:', error);
   else {
    setEditingId(null);
    setIsModalVisible(false);
    fetchItems();
   }
  } else {
   const { error } = await supabase.from('rvm_items').insert(values);
   if (error) console.error('Error adding item:', error);
   else {
    setIsModalVisible(false);
    fetchItems();
   }
  }
  form.resetFields();
 };

 const showDeleteConfirm = (id) => {
  setItemToDelete(id);
  setIsDeleteModalVisible(true);
 };

 const handleDeleteConfirm = async () => {
  const { error } = await supabase.from('rvm_items').delete().eq('id', itemToDelete);
  if (error) console.error('Error deleting item:', error);
  else {
   fetchItems();
   setIsDeleteModalVisible(false);
  }
 };

 const handleDeleteCancel = () => {
  setIsDeleteModalVisible(false);
  setItemToDelete(null);
 };

 const editItem = (record) => {
  setEditingId(record.id);
  form.setFieldsValue(record);
  setIsModalVisible(true);
 };

 const columns = [
  { title: 'Item', dataIndex: 'item', key: 'item' },
  {
   title: 'Group',
   dataIndex: 'group',
   key: 'group',
   filters: [
    { text: 'Mastitis - Intramammary', value: 'Mastitis - Intramammary' },
    { text: 'Minerals', value: 'Minerals' },
    { text: 'Miscellaneous', value: 'Miscellaneous' },
    { text: 'Vaccines', value: 'Vaccines' },
    { text: 'Topical', value: 'Topical' },
    { text: 'Dry Cow Therapy', value: 'Dry Cow Therapy' },
    { text: 'Calf Scour', value: 'Calf Scour' },
    { text: 'Vet or Vet Technician to Administer Only', value: 'Vet or Vet Technician to Administer Only' },
    { text: 'Anti - inflammatory', value: 'Anti - inflammatory' },
    { text: 'Vitamins', value: 'Vitamins' },
    { text: 'Injectable Abx', value: 'Injectable Abx' },
   ],
   onFilter: (value, record) => record.group.startsWith(value),
   filterSearch: true,
  },
  {
   title: 'Colour',
   dataIndex: 'colour',
   key: 'colour',
   filters: [
    { text: 'Red', value: 'Red' },
    { text: 'Green', value: 'Green' },
    { text: 'Other', value: 'Other' },
    { text: 'Orange', value: 'Orange' },
   ],
   onFilter: (value, record) => record.colour === value,
  },
  { title: 'Condition', dataIndex: 'condition', key: 'condition' },
  { title: 'Instructions', dataIndex: 'instructions', key: 'instructions' },
  { title: 'WHP Meat', dataIndex: 'whp_meat', key: 'whp_meat' },
  { title: 'WHP Milk', dataIndex: 'whp_milk', key: 'whp_milk' },
  {
   title: 'Actions',
   key: 'actions',
   render: (_, record) => (
    <Space>
     <Button onClick={() => editItem(record)}>Edit</Button>
     <Button danger onClick={() => showDeleteConfirm(record.id)}>Delete</Button>
    </Space>
   ),
  },
 ];

 return (
  <div style={{ padding: '20px' }}>
   <Flex style={{ marginBottom: 20, marginTop: 20 }}>
    <Input
     value={searchQuery}
     onChange={handleSearchChange}
     placeholder="Search items"
    />
    <Button type="primary" onClick={() => setIsModalVisible(true)} style={{ marginLeft: 20 }}>
     Add New Item
    </Button>
   </Flex>

   <Table
    columns={columns}
    dataSource={filteredRvmItems}
    rowKey="id"
    pagination={{ pageSize: 10 }}
   />
   <Modal
    title={editingId ? "Edit Item" : "Add New Item"}
    visible={isModalVisible}
    onCancel={() => {
     setIsModalVisible(false);
     setEditingId(null);
     form.resetFields();
    }}
    footer={null}
   >
    <Form form={form} onFinish={onFinish} layout="vertical">
     <Form.Item name="item" label="Item" rules={[{ required: true }]}>
      <Input />
     </Form.Item>
     <Form.Item name="group" label="Group" rules={[{}]}>
      <Select options={[
       { value: 'Mastitis - Intramammary', label: <span>Mastitis - Intramammary</span> },
       { value: 'Minerals', label: <span>Minerals</span> },
       { value: 'Miscellaneous', label: <span>Miscellaneous</span> },
       { value: 'Vaccines', label: <span>Vaccines</span> },
       { value: 'Topical', label: <span>Topical</span> },
       { value: 'Dry Cow Therapy', label: <span>Dry Cow Therapy</span> },
       { value: 'Calf Scour', label: <span>Calf Scour</span> },
       { value: 'Vet or Vet Technician to Administer Only', label: <span>Vet or Vet Technician to Administer Only</span> },
       { value: 'Anti - inflammatory', label: <span>Anti - inflammatory</span> },
       { value: 'Vitamins', label: <span>Vitamins</span> },
       { value: 'Injectable Abx', label: <span>Injectable Abx</span> },
      ]} />
     </Form.Item>
     <Form.Item name="colour" label="Colour" rules={[{}]}>
      <Select options={[
       { value: 'Red', label: <span>Red</span> },
       { value: 'Green', label: <span>Green</span> },
       { value: 'Other', label: <span>Other</span> },
       { value: 'Orange', label: <span>Orange</span> }
      ]} />
     </Form.Item>
     <Form.Item name="condition" label="Condition" rules={[{}]}>
      <Input />
     </Form.Item>
     <Form.Item name="instructions" label="Instructions" rules={[{}]}>
      <Input.TextArea />
     </Form.Item>
     <Form.Item name="whp_meat" label="WHP Meat" rules={[{}]}>
      <Input />
     </Form.Item>
     <Form.Item name="whp_milk" label="WHP Milk" rules={[{}]}>
      <Input />
     </Form.Item>
     <Form.Item>
      <Button type="primary" htmlType="submit">
       {editingId ? "Update" : "Add"}
      </Button>
     </Form.Item>
    </Form>
   </Modal>
   <Modal
    title="Confirm Delete"
    visible={isDeleteModalVisible}
    onOk={handleDeleteConfirm}
    onCancel={handleDeleteCancel}
    okText="Yes, delete it"
    cancelText="No, keep it"
   >
    <p>Are you sure you want to delete this item? This action cannot be undone.</p>
   </Modal>
  </div>
 );
};

export default RvmItemsCRUD;