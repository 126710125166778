function add12Months(dateString) {
 // Split the date string into day, month, and year components
 var parts = dateString.split('-');
 var day = parts[0];
 var month = parts[1];
 var year = parseInt(parts[2], 10);

 // Add 12 months to the year
 year += 1;

 // Format the new date as a string in dd-mm-yyyy format
 var newDateString = day + "-" + month + "-" + year;
 return newDateString;
}
function formatDate(dateString) {
 // Split the date string into year, month, and day components
 var parts = dateString.split('-');
 var year = parts[0];
 var month = parts[1];
 var day = parts[2];

 // Format the date as a string in dd-mm-yyyy format
 var formattedDateString = day + "-" + month + "-" + year;
 return formattedDateString;
}

const excelValues = (data) => {
 console.log(data)
 let formattedConsultDate;
 if (
  Array.isArray(data.relatedData.rvm_farm_objectives_data) &&
  data.relatedData.rvm_farm_objectives_data.length > 0 &&
  data.relatedData.rvm_farm_objectives_data[0].consult_date
 ) {
  formattedConsultDate = formatDate(data.relatedData.rvm_farm_objectives_data[0].consult_date);
 } else {
  formattedConsultDate = null;
 }

 const medicationData = data.relatedData.rvm_medication_data.reduce((acc, item, index) => {
  const rowIndex = 6 + index;
  acc[`A${rowIndex}`] = item.item;
  acc[`B${rowIndex}`] = item.condition;
  acc[`C${rowIndex}`] = item.instructions;
  acc[`D${rowIndex}`] = (item.whp_milk ? item.whp_milk : '');
  acc[`E${rowIndex}`] = (item.whp_meat ? item.whp_meat : '');
  return acc;
 }, {});

 const rvmScriptData = data.relatedData.rvm_medication_data.reduce((acc, item, index) => {
  console.log(item);
  let rowIndex = 6 + index; // Initialize rowIndex
  if (item.quantity_authorised > 0) {
   acc[`A${rowIndex}`] = item.item;
   acc[`B${rowIndex}`] = item.quantity_authorised;
   acc[`C${rowIndex}`] = 'Dairy, female, adult';
  } else {
   // Skip a line by incrementing rowIndex
   rowIndex++;
  }
  return acc;
 }, {});


 let intramammaryIndex = 12;
 let injectableIndex = 12;
 let antiInflammatoryIndex = 12;

 const rvmCombinationTherapyData = data.relatedData.rvm_medication_data.reduce((acc, item) => {
  console.log(item.group)
  if (item.group && item.group.includes('Intramammary')) {
   acc[`A${intramammaryIndex}`] = item.item;
   intramammaryIndex++;
  } else if (item.group && item.group.includes('Injectable')) {
   acc[`G${injectableIndex}`] = item.item;
   injectableIndex++;
  } else if (item.group && item.group.includes('Anti-inflammatory')) {
   acc[`L${antiInflammatoryIndex}`] = item.item;
   antiInflammatoryIndex++;
  }
  return acc;
 }, {});

 return {
  RVM_Authorisation: {
   C8: data.farmData && data.farmData.farm_name ? data.farmData.farm_name : '',
   C9: data.farmData && data.farmData.address ? data.farmData.address : '',
   F10: data.farmData && data.farmData.farmer_name ? data.farmData.farmer_name : '',
   D11: formattedConsultDate ? formattedConsultDate : '',
   H11: formattedConsultDate ? add12Months(formattedConsultDate) : '',
  },
  RVM_Summary_Sheet: {
   A4: data.farmData && data.farmData.farm_name ? data.farmData.farm_name : '',
   C2: (data.farmData && data.farmData.farmer_name ? data.farmData.farmer_name : '') +
    (data.farmData && data.farmData.address ? ', ' + data.farmData.address : ''),
   A39: data.farmVetData && data.farmVetData.name ? data.farmVetData.name : '',

   // A32: data.clinicData && data.clinicData.clinic_name ? data.clinicData.clinic_name : '',
   A40: 'Farm Services Veterinarian',
   A41: data.clinicData && data.clinicData.address ? data.clinicData.address : '',
   E40: formattedConsultDate ? formattedConsultDate : '',
   ...medicationData,
  },
  RVM_Script: {
   A3: data.farmData && data.farmData.farm_name ? data.farmData.farm_name : '',
   A4: data.farmData
    ? data.farmData.farmer_name && data.farmData.address
     ? `${data.farmData.farmer_name}, ${data.farmData.address}`
     : data.farmData.address
      ? data.farmData.address
      : ''
    : '',
   A40: data.farmVetData && data.farmVetData.name ? data.farmVetData.name : '',
   A41: data.clinicData && data.clinicData.clinic_name ? ('Franklin Vets - ' + data.clinicData.clinic_name) : '',
   A42: data.clinicData && data.clinicData.address ? data.clinicData.address : '',
   C41: formattedConsultDate ? formattedConsultDate : '',
   C42: formattedConsultDate ? add12Months(formattedConsultDate) : '',
   ...rvmScriptData,
  },
  RVM_Combination_Therapy: {
   C8: data.farmData && data.farmData.farm_name ? data.farmData.farm_name : '',
   I8: data.farmData && data.farmData.supply_number ? data.farmData.supply_number : '',
   N8: formattedConsultDate ? formattedConsultDate : '',
   D37: data.farmVetData && data.farmVetData.name ? data.farmVetData.name : '',
   ...rvmCombinationTherapyData,
  },
 };
};
// console.log(excelValues)
export default excelValues;