import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Outlet } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ConsultNavbar from '../../consults/components/ConsultNavbar';
import ConsultBottomNav from '../../consults/components/ConsultBottomNav';
import excelValues from './components/rvm'; // You might need to adjust this import path
import supabase from '~supabaseConfig'; // You might need to adjust this import path

const cloudflareWorkerUrl = import.meta.env.VITE_CLOUDFLARE_WORKER_URL; // Adjust this as needed

const fetchRvmData = async (tableName, columnName, value) => {
 console.log(`Fetching data from ${tableName} where ${columnName} = ${value}`);
 console.log('underneath')
 console.log(tableName);
 console.log(columnName);
 console.log(value);

 try {
  const { data, error } = await supabase.from(tableName).select(`
    *,
    farms:farm_id (
      id,
      farm_name,
      farm_size,
      email,
      phone_number,
      address,
      herd_size,
      heifer_size,
      farmer_name,
      clinic_id (
        id,
        clinic_name,
        address
      ),
      vet (
        id,
        name,
        email
      ),
      supply_number
    ),
    farm_objectives_id (
     *
    ),
    key_actions_id (
     *
    ),
    milk_quality_data_id (
     *
    ),
    mineral_supplementation_id (
     *
    ),
    repro_id (
     *
    ),
    health_id (
     *
    ),
    rvm_id (
     *
    )
  `)
   .eq(columnName, value)
   .single();

  if (error) {
   console.error('Supabase query error:', error);
   throw error;
  }

  if (!data) {
   console.warn(`No data found for ${columnName} ${value} in ${tableName}`);
   throw new Error(`No data found for ${columnName} ${value} in ${tableName}`);
  }

  return data;
 } catch (error) {
  console.error(`Error in fetchWellbeingData for ${tableName}:`, error);
  throw error;
 }
};

// const RvmSelect = () => {
// return(
//   <select>
//     <option>RVM Full</option>
//     <option>RVM Lite</option>
//   </select>
// )
// }

const RvmLayout = () => {
 const { farmId, rvmId } = useParams();

 const navItems = [
  {
   label: <Link to={`/farm/${farmId}/`}><ArrowBackIosIcon /></Link>,
   key: 'Back',
  },
  {
   label: <Link to={`/farm/${farmId}/rvm/${rvmId}/FarmObjectives`}>Farm Objectives</Link>,
   key: 'FarmObjectives',
  },
  {
   label: <Link to={`/farm/${farmId}/rvm/${rvmId}/MilkQualityData`}>Milk Quality Data</Link>,
   key: 'MilkQualityData',
  },
  {
   label: <Link to={`/farm/${farmId}/rvm/${rvmId}/Repro`}>Repro</Link>,
   key: 'Repro',
  },
  {
   label: <Link to={`/farm/${farmId}/rvm/${rvmId}/Health`}>Health</Link>,
   key: 'Health',
  },
  {
   label: <Link to={`/farm/${farmId}/rvm/${rvmId}/MineralSupplementation`}>Mineral Supplementation</Link>,
   key: 'MineralSupplementation',
  },
  {
   label: <Link to={`/farm/${farmId}/rvm/${rvmId}/KeyActions`}>Key Actions</Link>,
   key: 'KeyActions',
  },
  {
   label: <Link to={`/farm/${farmId}/rvm/${rvmId}/RVM`}>RVM</Link>,
   key: 'RVM',
  },
  {
   label: <Link to={`/farm/${farmId}/rvm/${rvmId}/AnimalHealthPlanner`}>Animal Health Planner</Link>,
   key: 'AnimalHealthPlanner',
  },
  
 ];



 return (
  <>
   <ConsultNavbar
    items={navItems}
    consultType="rvm"
    consultIdParam="rvmId"
    supabaseTable="rvms"
   />
   <Outlet />
   <ConsultBottomNav
    consultType="rvm"
    consultIdParam="rvmId"
    supabaseTable="rvms"
    cloudflareWorkerUrl={cloudflareWorkerUrl}
    excelGenerator={excelValues}
    reportName="RVM"
    fetchDataFunction={fetchRvmData}
   />
  </>
 );
};

export default RvmLayout;