import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Input, DatePicker, Typography, Divider, Checkbox, Select } from 'antd';
import { Box } from '@mui/joy';
import { useParams } from 'react-router-dom';
import pageTemplate from '../../components/pageTemplate';
import LayoutBox from '../../components/LayoutBox';
import FormLayout from '../../components/FormLayout';
import FormRow from './components/FormRow';
import supabase from '~supabaseConfig';

const Environment = () => {
 const supabaseTableName = 'wellbeing_consult_environment_data';
 const consultSupabaseTable = 'wellbeing_consult_id';
 const supabaseTableId = 'environment_data_id';
 const consultIdName = 'wellbeing_consult_id';
 const { farmId, wellbeingConsultId } = useParams();
 const { TextArea } = Input;
 const { Title, Text } = Typography;
 const { Option } = Select;

 const [cannedNotes, setCannedNotes] = useState({});

 useEffect(() => {
  fetchCannedNotes();
 }, []);

 const { form, onFinish } = pageTemplate(
  supabaseTableName,
  consultSupabaseTable,
  supabaseTableId,
  farmId,
  wellbeingConsultId,
  consultIdName,
  'wellbeing_consults'
 );

 const environmentArr = [
  { label: 'Heavy rain', value: 'Heavy rain' },
  { label: 'Floods', value: 'Floods' },
  { label: 'Strong winds', value: 'Strong winds' },
  { label: 'Drought', value: 'Drought' },
  { label: 'Snow cold', value: 'Snow cold' },
 ];

 const heatStressArr = [
  { label: 'Low', value: 'Low' },
  { label: 'Medium', value: 'Medium' },
  { label: 'High', value: 'High' },
  { label: 'Extreme', value: 'Extreme' },
 ];

 const ghgArr = [
  "Improve animal health management to increase on-farm efficiency mainly through producing the same or more product with lesser animals",
  "Improving nutrition – increased feed efficiency, ME of feed, increased farm grown feed",
  "Improved animal health – decreasing lameness, Johne's, metabolic issues, other health problems = reduces wastage",
  "Focus on reduced somatic cell counts, mastitis to improve production, reduce wastage and increase on-farm efficiency",
  "Improve repro efficiency to minimize wastage from late calving cows and not-in-calf cows",
 ];
 const fetchCannedNotes = async () => {
  console.log('Fetching canned notes...');
  try {
   const categories = ['Environment', 'Heat Mitigation', 'Managing GHG Emissions'];
   const groupedNotes = {};

   // First, fetch all categories
   const { data: allCategories, error: categoriesError } = await supabase
    .from('wellbeing_canned_notes_categories')
    .select('id, category');

   if (categoriesError) throw categoriesError;

   console.log('All categories:', allCategories);

   for (const category of categories) {
    console.log(`Fetching notes for ${category}...`);

    // Find the matching category
    const categoryData = allCategories.find(c => c.category.toLowerCase() === category.toLowerCase());

    if (!categoryData) {
     console.log(`Category ${category} not found`);
     continue;
    }

    // Fetch notes for this category
    const { data, error } = await supabase
     .from('wellbeing_canned_notes')
     .select(`
          id,
          note,
          category (
            id,
            category
          )
        `)
     .eq('category', categoryData.id);

    if (error) {
     console.error(`Error fetching notes for ${category}:`, error);
     continue;
    }

    if (data) {
     groupedNotes[category] = data.map(item => item.note);
    }
   }

   console.log('Grouped notes:', groupedNotes);
   setCannedNotes(groupedNotes);
  } catch (error) {
   console.error('Error in fetchCannedNotes:', error);
  }
 };

 const handleNoteChange = (value, category) => {
  form.setFieldsValue({ [`${category.toLowerCase().replace(/\s/g, '_')}_plan`]: value.join('\n') });
 };

 return (
  <FormLayout
   title="Environment"
   form={form}
   onFinish={onFinish}
   extraContent={
    <>
     <Row gutter={16}>
      <Col span={24} style={{ textAlign: 'left' }}>
       <Title level={3}>Heat Mitigation</Title>
      </Col>
     </Row>
     <Row gutter={16} style={{ textAlign: 'left' }}>
      <Col span={24}>
       <Form.Item name="heat_mitigation_result">
        <Checkbox.Group options={heatStressArr} />
       </Form.Item>
      </Col>
     </Row>
     <Row gutter={16}>
      <Col span={18}>
       <Form.Item name="heat_mitigation_plan" label="Plan">
        <TextArea rows={4} />
       </Form.Item>
      </Col>
      <Col span={6}>
       <Form.Item label="Canned Notes">
        <Select
         mode="multiple"
         style={{ width: '100%' }}
         placeholder="Select notes"
         onChange={(value) => handleNoteChange(value, 'Heat Mitigation')}
        >
         {cannedNotes['Heat Mitigation']?.map((note, index) => (
          <Option key={index} value={note}>
           {note}
          </Option>
         ))}
        </Select>
       </Form.Item>
      </Col>
     </Row>
     <Row gutter={16}>
      <Col span={24} style={{ textAlign: 'left' }}>
       <Title level={3}>Managing GHG Emissions</Title>
      </Col>
     </Row>
     {ghgArr.map((item, index) => (
      <Row gutter={16} key={index}>
       <Col span={12} style={{ textAlign: 'left' }}>
        <Text>{item}</Text>
       </Col>
       <Col span={12}>
        <Form.Item name={`ghg_emissions_${index + 1}`}>
         <TextArea rows={4} />
        </Form.Item>
       </Col>
      </Row>
     ))}
     <Row gutter={16}>
      <Col span={24}>
       {/* <Form.Item label="Canned Notes"> */}
        {/* <Select
         mode="multiple"
         style={{ width: '100%' }}
         placeholder="Select notes"
         onChange={(value) => handleNoteChange(value, 'Managing GHG Emissions')}
        >
         {cannedNotes['Managing GHG Emissions']?.map((note, index) => (
          <Option key={index} value={note}>
           {note}
          </Option>
         ))}
        </Select> */}
       {/* </Form.Item> */}
      </Col>
     </Row>
    </>
   }
  >
   <Row gutter={16} style={{ textAlign: 'left' }}>
    <Col span={24}>
     <Form.Item name="environment_result">
      <Checkbox.Group options={environmentArr} />
     </Form.Item>
    </Col>
   </Row>
   <Row gutter={16}>
    <Col span={18}>
     <Form.Item name="environment_plan" label="Plan">
      <TextArea rows={4} />
     </Form.Item>
    </Col>
    <Col span={6}>
     <Form.Item label="Canned Notes">
      <Select
       mode="multiple"
       style={{ width: '100%' }}
       placeholder="Select notes"
       onChange={(value) => handleNoteChange(value, 'Environment')}
      >
       {cannedNotes['Environment']?.map((note, index) => (
        <Option key={index} value={note}>
         {note}
        </Option>
       ))}
      </Select>
     </Form.Item>
    </Col>
   </Row>
  </FormLayout>
 );
};

export default Environment;