import React, { useEffect } from 'react';
import { Drawer, Typography, Form, Input, Button, Select } from 'antd';

const { Title } = Typography;

const ConsultDataModal = ({ open, onClose, consultData, onUpdate }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (consultData) {
      form.setFieldsValue({
        start_date: String(consultData.start_date),
        end_date: String(consultData.end_date),
      });
    }
  }, [consultData, form]);

  const handleSubmit = async (values) => {
    try {
      await onUpdate(values);
      onClose();
    } catch (error) {
      console.error('Failed to update consult data:', error);
    }
  };

  if (!consultData) {
    return null;
  }

  return (
    <Drawer
      title="Edit Consult Data"
      placement="right"
      onClose={onClose}
      open={open}
      width={400}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item name="start_date" label="Start Date" rules={[{ required: true }]}>
          <Select>
            <Select.Option value="2023">2023</Select.Option>
            <Select.Option value="2024">2024</Select.Option>
            <Select.Option value="2025">2025</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="end_date" label="End Date" rules={[{ required: true }]}>
          <Select>
            <Select.Option value="2023">2023</Select.Option>
            <Select.Option value="2024">2024</Select.Option>
            <Select.Option value="2025">2025</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ConsultDataModal;
