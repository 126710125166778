import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Card, CardContent, Avatar, Stack, Divider } from '@mui/joy';
import supabase from '~supabaseConfig';

const Profile = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data, error } = await supabase
          .from('users')
          .select('*')
          .eq('id', userId)
          .single();

        if (error) {
          throw new Error(`Error fetching user data: ${error.message}`);
        }

        setUser(data);
      } catch (error) {
        console.error(error);
        setError('Failed to fetch user data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [userId]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="danger">{error}</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <Card sx={{ maxWidth: 600, width: '100%', p: 2 }}>
        <CardContent>
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar alt={user.name} src={user.avatar_url} sx={{ width: 80, height: 80 }} />
            <Stack>
              <Typography level="h4">{user.name}</Typography>
              <Typography level="body2" color="text.secondary">
                {user.email}
              </Typography>
            </Stack>
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Typography level="body1">
            <strong>Bio:</strong> {user.bio}
          </Typography>
          <Typography level="body1">
            <strong>Location:</strong> {user.location}
          </Typography>
          {/* Add more user information as needed */}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Profile;