import React from 'react';
import { Outlet } from "react-router-dom"
import SettingsNavbar from './components/SettingsNavbar';

const SettingsLayout = ({ children }) => {
  return (
    <>
      <SettingsNavbar />
      {/* This is where your content will be injected */}
      <Outlet />
    </>
  );
};

export default SettingsLayout;
