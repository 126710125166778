import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Form, InputNumber, Checkbox, Card, Divider, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import supabase from '~supabaseConfig';
import pageTemplate from '../../../components/pageTemplate';
import FormLayout from '../../../components/FormLayout';

const { Grid } = Card;

const Health = () => {
  const { farmId, rvmId } = useParams();
  const [herdSize, setHerdSize] = useState(0);
  const [calfHerdSize, setCalfHerdSize] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollTimeout = useRef(null);
  const { form, onFinish } = pageTemplate(
    'rvm_health_data',
    'rvm_id',
    'health_id',
    farmId,
    rvmId,
    'rvm_id',
    'rvms'
  );

  const handleWheel = useCallback(() => {
    setIsScrolling(true);
    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current);
    }
    scrollTimeout.current = setTimeout(() => {
      setIsScrolling(false);
    }, 150);
  }, []);

  useEffect(() => {
    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }
    };
  }, [handleWheel]);

  useEffect(() => {
    const fetchFarmData = async () => {
      const { data: farmObjectivesData, error: farmObjectivesError } = await supabase
        .from('rvm_farm_objectives_data')
        .select()
        .eq('rvm_id', rvmId);

      if (farmObjectivesError) {
        console.error('Error fetching existing farm objectives data:', farmObjectivesError);
        return;
      }

      if (farmObjectivesData && farmObjectivesData.length > 0) {
        setHerdSize(farmObjectivesData[0].herd_size);
        setCalfHerdSize(farmObjectivesData[0].calves_reared);
      } else {
        const { data: farmData, error: farmError } = await supabase
          .from('farms')
          .select('herd_size, calves_reared')
          .eq('id', farmId);

        if (farmError) {
          console.error('Error fetching farm data:', farmError);
          return;
        }

        if (farmData && farmData.length > 0) {
          setHerdSize(farmData[0].herd_size);
          setCalfHerdSize(farmData[0].calves_reared);
        } else {
          console.log('No farm data found.');
        }
      }
    };

    fetchFarmData();
  }, [farmId, rvmId]);

  const gridStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-start',
  };

  const renderInputNumber = (name, addonAfter) => (
    <Form.Item name={name}>
      <InputNumber
        onWheel={(e) => e.target.blur()}
        readOnly={isScrolling}
        addonAfter={addonAfter}
      />
    </Form.Item>
  );

  const renderTextArea = (name) => (
    <Form.Item name={name}>
      <TextArea
        placeholder="Plan"
        autoSize={{ minRows: 2, maxRows: 6 }}
        onWheel={(e) => e.target.blur()}
        readOnly={isScrolling}
      />
    </Form.Item>
  );

  const calculatePercentage = (fieldName) => {
    const value = form.getFieldValue(fieldName);
    return value !== undefined && herdSize !== 0 ? `${Math.round((value / herdSize) * 100)}%` : null;
  };

  const calculateCalfPercentage = (fieldName) => {
    const value = form.getFieldValue(fieldName);
    return value !== undefined && calfHerdSize !== 0 ? `${Math.round((value / calfHerdSize) * 100)}%` : null;
  };

  const extraContent = (
    <>
      <Card title={herdSize !== null ? `Cows: ${herdSize}` : 'Cows: N/A'}>
        <Grid style={gridStyle}>
          <p>Aborted</p>
          {renderInputNumber("cow_abort", calculatePercentage("cow_abort"))}
        </Grid>
        <Grid style={gridStyle}>
          <p>Metabolic disease</p>
          {renderInputNumber("cow_metabolic_disease", calculatePercentage("cow_metabolic_disease"))}
        </Grid>
        <Grid style={gridStyle}>
          <p>Retained cleanings</p>
          {renderInputNumber("cow_retained_cleanings", calculatePercentage("cow_retained_cleanings"))}
        </Grid>
        <Grid style={gridStyle}>
          <p>Lame</p>
          {renderInputNumber("cow_lame", calculatePercentage("cow_lame"))}
          <p>Target</p>
          {renderInputNumber("cow_lame_target", calculatePercentage("cow_lame_target"))}
          <p>Plan</p>
          {renderTextArea("cow_lame_plan")}
        </Grid>
        <Grid style={gridStyle}>
          <p>Deaths</p>
          {renderInputNumber("cow_deaths", calculatePercentage("cow_deaths"))}
          <p>Target</p>
          {renderInputNumber("cow_death_target", calculatePercentage("cow_death_target"))}
          <p>Plan</p>
          {renderTextArea("cow_death_plan")}
        </Grid>
      </Card>

      <Divider />

      <Card title={calfHerdSize !== null ? `Calves: ${calfHerdSize}` : 'Calves: N/A'}>
        <Grid style={gridStyle}>
          <p>Scours</p>
          {renderInputNumber("calves_scours", calculateCalfPercentage("calves_scours"))}
          <p>Target</p>
          {renderInputNumber("calves_scours_target", calculateCalfPercentage("calves_scours_target"))}
        </Grid>
        <Grid style={gridStyle}>
          <p>Navel III / Swollen Joints</p>
          {renderInputNumber("calves_navel_swollen_joints", calculateCalfPercentage("calves_navel_swollen_joints"))}
          <p>Target</p>
          {renderInputNumber("calves_navel_swollen_joints_target", calculateCalfPercentage("calves_navel_swollen_joints_target"))}
          {renderTextArea("navel_swollen_joints_plan")}
        </Grid>
        <Grid style={gridStyle}>
          <p>Deaths</p>
          {renderInputNumber("calves_deaths", calculateCalfPercentage("calves_deaths"))}
          <p>Target</p>
          {renderInputNumber("calves_deaths_target", calculateCalfPercentage("calves_deaths_target"))}
          {renderTextArea("calves_deaths_plan")}
        </Grid>
      </Card>

      <Divider />

      <Form.Item name="services">
        <Checkbox.Group
          options={[
            { label: 'Welfarm', value: 'welfarm' },
            { label: 'Transition Plan', value: 'transition_plan' },
            { label: 'Collar Modules', value: 'collar_modules' },
            { label: 'Herd Drench', value: 'herd_drench' },
            { label: 'Salvexin', value: 'salvexin' },
            { label: 'Rotavec', value: 'rotavec' },
            { label: 'Calf wof', value: 'calf_wof' },
            { label: 'Young stock health plan', value: 'young_stock_health_plan' },
            { label: 'Debudding', value: 'debudding' },
            { label: 'Covexin', value: 'covexin' },
          ]}
        />
      </Form.Item>

      <Divider />

      <Form.Item label="Additional comments:" name="comments">
        <TextArea
          placeholder="Controlled autosize"
          autoSize={{ minRows: 3, maxRows: 5 }}
          onWheel={(e) => e.target.blur()}
          readOnly={isScrolling}
        />
      </Form.Item>
    </>
  );

  return (
    <FormLayout title="Health" form={form} onFinish={onFinish} extraContent={extraContent} />
  );
};

export default Health;