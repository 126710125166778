import React, { useState, useEffect } from 'react';
import supabase from '~supabaseConfig';
import { Typography, Table, Row, Col, Form, Select, Input, Button, Modal, message } from 'antd';
import { Box } from '@mui/joy';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
const { TextArea } = Input;
const { confirm } = Modal;

export default function AnimalHealthSettings() {
 const [cannedNotes, setCannedNotes] = useState([]);
 const [categories, setCategories] = useState([]);
 const [form] = Form.useForm();
 const [isModalOpen, setIsModalOpen] = useState(false);
 const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
 const [editingNote, setEditingNote] = useState(null);
 const [searchValue, setSearchValue] = useState('');

 useEffect(() => {
  fetchData();
 }, []);

 const fetchData = async () => {
  try {
   // Fetch canned notes
   const { data: notesData, error: notesError } = await supabase
    .from('wellbeing_canned_notes')
    .select('*');

   if (notesError) throw notesError;

   // Fetch categories
   const { data: categoriesData, error: categoriesError } = await supabase
    .from('wellbeing_canned_notes_categories')
    .select('id, category');

   if (categoriesError) throw categoriesError;

   console.log('Raw notesData:', notesData);
   console.log('Raw categoriesData:', categoriesData);

   // Create a map of category ids to category names
   const categoryMap = Object.fromEntries(
    categoriesData.map(cat => [cat.id, cat.category])
   );

   // Filter and map the notes data
   const filteredData = notesData
    .filter((item) => item.note && item.note.trim().length > 0)
    .map((item) => ({
     ...item,
     category: categoryMap[item.category] || 'Uncategorized',
     category_id: item.category
    }));

   console.log('Filtered and mapped data:', filteredData);

   setCannedNotes(filteredData);
   setCategories(categoriesData);
  } catch (error) {
   console.error('Error fetching data:', error);
   message.error('Error fetching data');
  }
 };

 const handleAddNote = async (values) => {
  try {
   const { data, error } = await supabase
    .from('wellbeing_canned_notes')
    .insert([{ category: values.category, note: values.note }]);

   if (error) throw error;

   message.success('Note added successfully');
   fetchData();
   form.resetFields();
   setIsModalOpen(false);
  } catch (error) {
   console.error('Error adding note:', error);
   message.error('Error adding note');
  }
 };

 const handleUpdateNote = async (values) => {
  try {
   const { data, error } = await supabase
    .from('wellbeing_canned_notes')
    .update({ category: values.category, note: values.note })
    .eq('id', editingNote.id);

   if (error) throw error;

   message.success('Note updated successfully');
   setIsModalOpen(false);
   fetchData();
  } catch (error) {
   console.error('Error updating note:', error);
   message.error('Error updating note');
  }
 };

 // Update the handleEditNote function
 const handleEditNote = (note) => {
  setEditingNote(note);
  setIsModalOpen(true);
  form.setFieldsValue({
   ...note,
   category: note.category_id
  });
 };

 const handleModalCancel = () => {
  setIsModalOpen(false);
  setEditingNote(null);
  form.resetFields();
 };

 const showDeleteConfirm = (noteId) => {
  confirm({
   title: 'Are you sure you want to delete this note?',
   icon: <DeleteOutlined />,
   content: 'This action cannot be undone.',
   okText: 'Yes',
   okType: 'danger',
   cancelText: 'No',
   onOk() {
    handleDeleteNote(noteId);
   },
  });
 };

 const handleDeleteNote = async (noteId) => {
  try {
   const { data, error } = await supabase
    .from('wellbeing_canned_notes')
    .delete()
    .eq('id', noteId);

   if (error) throw error;

   message.success('Note deleted successfully');
   fetchData();
  } catch (error) {
   console.error('Error deleting note:', error);
   message.error('Error deleting note');
  }
 };

 const handleAddCategory = async (values) => {
  try {
   const { data, error } = await supabase
    .from('wellbeing_canned_notes_categories')
    .insert([{ category: values.category }]);

   if (error) throw error;

   message.success('Category added successfully');
   fetchData();
   setIsCategoryModalOpen(false);
  } catch (error) {
   console.error('Error adding category:', error);
   message.error('Error adding category');
  }
 };

 const columns = [
  {
   title: 'Category',
   dataIndex: 'category',
   key: 'category',
   filters: categories.map((category) => ({ text: category.category, value: category.category })),
   filterMode: 'tree',
   filterSearch: true,
   onFilter: (value, record) => record.category === value,
  },
  {
   title: 'Note',
   dataIndex: 'note',
   key: 'note',
   render: (text, record) => (
    <a onClick={() => handleEditNote(record)} style={{ width: 600, maxWidth: 600 }}>
     {text}
    </a>
   ),
  },
  {
   title: 'Action',
   key: 'action',
   render: (text, record) => (
    <Button type="link" danger onClick={() => showDeleteConfirm(record.id)}>
     <DeleteOutlined />
    </Button>
   ),
  },
 ];

 return (
  <Box
   component="main"
   className="MainContent"
   sx={{
    px: { xs: 2, md: 6 },
    pt: { xs: 'calc(12px + var(--Header-height))', sm: 'calc(12px + var(--Header-height))', md: 3 },
    pb: { xs: 2, sm: 2, md: 3 },
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 2,
   }}
  >

   {/* Moved buttons to the top */}
   <Row style={{ width: '100%', maxWidth: '800px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
    <Col>
     <Typography.Title level={4}>Canned Notes</Typography.Title>
    </Col>
    <Col>
     <Button type="primary" onClick={() => setIsModalOpen(true)} style={{ marginRight: '10px' }}>
      Add New Note
     </Button>
     <Button type="primary" onClick={() => setIsCategoryModalOpen(true)}>
      Add New Category
     </Button>
    </Col>
   </Row>

   <Row style={{ width: '100%', maxWidth: '800px' }}>
    <Col span={24}>
     <Table dataSource={cannedNotes} columns={columns} rowKey="id" style={{ width: '100%' }} />
    </Col>
   </Row>

   {/* Note Modal */}
   <Modal
    title={editingNote ? "Edit Note" : "Add New Note"}
    open={isModalOpen}
    onOk={form.submit}
    onCancel={handleModalCancel}
    okText={editingNote ? "Save" : "Add"}
    cancelText="Cancel"
   >
    <Form form={form} onFinish={editingNote ? handleUpdateNote : handleAddNote}>
     <Form.Item name="category" label="Category">
      <Select
       options={categories.map((category) => ({ value: category.id, label: category.category }))}
       allowClear
      />
     </Form.Item>
     <Form.Item name="note" label="Note">
      <TextArea
       placeholder="Enter note"
       autoSize={{
        minRows: 3,
        maxRows: 5,
       }}
      />
     </Form.Item>
    </Form>
   </Modal>

   {/* Category Modal */}
   <Modal
    title="Add New Category"
    open={isCategoryModalOpen}
    onOk={form.submit}
    onCancel={() => setIsCategoryModalOpen(false)}
    okText="Add"
    cancelText="Cancel"
   >
    <Form form={form} onFinish={handleAddCategory}>
     <Form.Item
      name="category"
      label="Category"
      rules={[{ required: true, message: 'Please input the category name!' }]}
     >
      <Input placeholder="Enter category name" />
     </Form.Item>
    </Form>
   </Modal>
  </Box>
 );
}