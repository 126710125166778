import React, { useState, useEffect } from 'react';
import { Button, message, Menu } from 'antd';
import { Link, useParams } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import supabase from '~supabaseConfig';
import './App.css'; // Import your CSS file
import ConsultDataModal from './ConsultDataModal';

const ConsultNavbar = ({
 items,
 consultType,
 consultIdParam,
 supabaseTable
}) => {
 const params = useParams();
 const farmId = params.farmId;
 const consultId = params[consultIdParam];
 const [current, setCurrent] = useState(items[0]?.key || '');
 const [consultData, setConsultData] = useState(null);
 const [open, setOpen] = useState(false);

 useEffect(() => {
  const fetchConsultData = async () => {
   try {
    const { data, error } = await supabase
     .from(supabaseTable)
     .select('*')
     .eq('id', consultId)
     .single();

    if (error) {
     if (error.code === 'PGRST116') {
      console.log(`No data found for the given ${consultType}Id`);
      setConsultData(null);
     } else {
      throw error;
     }
    } else {
     console.log('Fetched data:', data);
     setConsultData(data);
    }
   } catch (error) {
    console.error('Error fetching consult data:', error);
    message.error('Failed to fetch consult data');
   }
  };

  if (consultId) {
   fetchConsultData();
  }
 }, [consultId, supabaseTable, consultType]);

 const handleUpdateConsultData = async (updatedData) => {
  try {
   const { data, error } = await supabase
    .from(supabaseTable)
    .update(updatedData)
    .eq('id', consultId);

   if (error) throw error;
   setConsultData({ ...consultData, ...updatedData });
   message.success('Consult data updated successfully');
  } catch (error) {
   console.error('Error updating consult data:', error);
   message.error('Failed to update consult data');
  }
 };

 const onClick = (e) => {
  setCurrent(e.key);
 };

 return (
  <>
   <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', borderBottom: '1px solid rgba(5, 5, 5, 0.06)' }}>
    <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" style={{ width: '80%', borderBottom: 0 }} items={items} />
    <Button icon={<InfoOutlinedIcon />} onClick={() => setOpen(true)} />
   </div>
   <ConsultDataModal
    open={open}
    onClose={() => setOpen(false)}
    consultData={consultData}
    onUpdate={handleUpdateConsultData}
   />
  </>
 );
};

export default ConsultNavbar;