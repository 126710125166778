import FormLayout from '../../../components/FormLayout';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Table, Spin, Input, Form, Button, message, InputNumber, Divider } from 'antd';
import supabase from '~supabaseConfig';
import { Box } from '@mui/joy';
import { useParams } from 'react-router-dom';
import excelValues from './rvmMeds.js'
const cloudflareWorkerUrl = import.meta.env.VITE_CLOUDFLARE_WORKER_AUTH_URL;

const RvmItemsTable = () => {
 const [rvmItems, setRvmItems] = useState([]);
 const [loading, setLoading] = useState(true);
 const [form] = Form.useForm();
 const [searchQuery, setSearchQuery] = useState('');
 const { rvmId } = useParams();
 const [currentItems, setCurrentItems] = useState([]);
 const [isScrolling, setIsScrolling] = useState(false);
 const scrollTimeout = useRef(null);

 const handleWheel = useCallback(() => {
  setIsScrolling(true);
  if (scrollTimeout.current) {
   clearTimeout(scrollTimeout.current);
  }
  scrollTimeout.current = setTimeout(() => {
   setIsScrolling(false);
  }, 150);
 }, []);

 function removeDuplicates(array, key) {
  return array.filter((obj, index, self) =>
   index === self.findIndex((t) => t[key] === obj[key])
  );
 }

 useEffect(() => {
  window.addEventListener('wheel', handleWheel);
  return () => {
   window.removeEventListener('wheel', handleWheel);
   if (scrollTimeout.current) {
    clearTimeout(scrollTimeout.current);
   }
  };
 }, [handleWheel]);

 useEffect(() => {
  const fetchRvmItemsData = async () => {
   try {
    const [rvmItemsData, medicationData] = await Promise.all([
     supabase.from('rvm_items').select(),
     supabase.from('rvm_medication_data').select().eq('rvm_id', rvmId)
    ]);

    if (rvmItemsData.error) throw new Error(rvmItemsData.error.message);
    if (medicationData.error) throw new Error(medicationData.error.message);

    // Remove duplicates
    const uniqueMedicationData = removeDuplicates(medicationData.data, 'item_id');

    const updatedItems = uniqueMedicationData.map(medication => ({
     ...medication,
     key: medication.id,
    }));

    setCurrentItems(updatedItems);

    if (rvmItemsData.data) {
     const prefillData = rvmItemsData.data.map(item => {
      const medicationItem = updatedItems.find(med => med.item_id === item.id);
      return {
       ...item,
       quantity_authorised: medicationItem?.quantity_authorised || null,
       quantity_on_farm: medicationItem?.quantity_on_farm || null,
       item_id: medicationItem?.item_id || null,
      };
     });

     setRvmItems(prefillData);
    }
   } catch (error) {
    console.error('Error fetching data:', error);
    message.error('Failed to load RVM items data');
   } finally {
    setLoading(false);
   }
  };

  fetchRvmItemsData();
 }, [rvmId]);

 const onFinish = async (values) => {
  try {
   const existingItems = removeDuplicates(currentItems, 'id');
   const validObjects = Object.entries(values)
    .filter(([_, obj]) => obj.quantity_authorised > 0 || obj.quantity_on_farm > 0)
    .map(([id, obj]) => ({ id, ...obj }));

   const emptyObjects = Object.entries(values)
    .filter(([_, obj]) => obj.quantity_authorised === 0 && obj.quantity_on_farm === 0)
    .map(([id, obj]) => ({ id, ...obj }));

   // Update or insert valid objects
   await Promise.all(validObjects.map(async (obj) => {
    const existingItem = existingItems.find(item => item.item_id === obj.id);
    const operation = existingItem ? 'update' : 'insert';
    const { error } = await supabase.from('rvm_medication_data')[operation]({
     item_id: obj.id,
     rvm_id: rvmId,
     quantity_authorised: obj.quantity_authorised,
     quantity_on_farm: obj.quantity_on_farm
    }).eq(operation === 'update' ? 'item_id' : 'id', obj.id);

    if (error) throw error;
   }));

   // Delete empty objects
   await Promise.all(emptyObjects.map(async (obj) => {
    const existingItem = existingItems.find(item => item.item_id === obj.id);
    if (existingItem) {
     const { error } = await supabase.from('rvm_medication_data')
      .delete()
      .eq('item_id', obj.id)
      .eq('rvm_id', rvmId);
     if (error) throw error;
    }
   }));

   // Fetch updated data
   const { data: updatedMedicationData, error: updatedMedicationError } = await supabase
    .from('rvm_medication_data')
    .select()
    .eq('rvm_id', rvmId);

   if (updatedMedicationError) throw updatedMedicationError;

   setCurrentItems(updatedMedicationData);
   message.success('RVM Medication updated successfully');
  } catch (error) {
   console.error('Error updating RVM Medication:', error);
   message.error('Failed to update RVM Medication');
  }
 };

 const handleExcelButtonClick = async () => {
  setLoading(true);
  try {
   await form.validateFields();
   const values = await form.getFieldsValue();
   await onFinish(values);

   const relatedTables = ['rvm_farm_objectives_data', 'rvm_medication_data'];
   const [rvmData, farmData, clinicData] = await Promise.all([
    fetchData('rvms', 'id', rvmId),
    fetchData('farms', 'id', rvmData.farm_id),
    fetchData('clinics', 'id', farmData.clinic_id)
   ]);

   const farmVetData = farmData.vet ? await fetchData('users', 'id', farmData.vet) : null;

   const [medicationData, itemsData] = await Promise.all([
    supabase.from('rvm_medication_data').select('*').eq('rvm_id', rvmId),
    supabase.from('rvm_items').select('*')
   ]);

   if (medicationData.error) throw new Error('Error fetching medication data');
   if (itemsData.error) throw new Error('Error fetching items data');

   const medicationDataWithItems = medicationData.data.map((medication) => ({
    ...medication,
    ...itemsData.data.find((item) => item.id === medication.item_id)
   }));

   const relatedData = await Promise.all(
    relatedTables.map((tableName) => fetchRelatedData(tableName, rvmId))
   );

   const combinedData = {
    rvmData,
    farmData,
    clinicData,
    farmVetData,
    relatedData: relatedData.reduce((acc, curr, index) => {
     acc[relatedTables[index]] = relatedTables[index] === 'rvm_medication_data' ? medicationDataWithItems : curr;
     return acc;
    }, {})
   };

   const excelData = excelValues(combinedData);
   const response = await fetch(cloudflareWorkerUrl, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(excelData),
   });

   if (!response.ok) throw new Error('Network response was not ok');

   const blob = await response.blob();
   const downloadUrl = window.URL.createObjectURL(blob);
   const a = document.createElement('a');
   a.href = downloadUrl;
   a.download = `${combinedData.farmData.farm_name}-RVM-Authorisation-${combinedData.rvmData.start_date}-${combinedData.rvmData.end_date}.xlsx`;
   document.body.appendChild(a);
   a.click();
   window.URL.revokeObjectURL(downloadUrl);
   message.success('RVM report downloaded successfully');
  } catch (error) {
   console.error('Error fetching data:', error);
   message.error('Error downloading RVM report');
  } finally {
   setLoading(false);
  }
 };

 const columns = [
  {
   title: 'Item',
   dataIndex: 'item',
   key: 'item_id',
   sorter: (a, b) => a.item.localeCompare(b.item),
  },
  {
   title: 'Group',
   dataIndex: 'group',
   key: 'group',
   filters: [
    {
     text: 'Anti-inflammatory',
     value: 'Anti',
    },
    {
     text: 'Calf Scour',
     value: 'Calf',
    },
    {
     text: 'Dry Cow Therapy',
     value: 'Dry',
    },
    {
     text: 'Injectable Abx',
     value: 'Inj',
    },
    {
     text: 'Mastitis - Intramammary',
     value: 'Mast',
    },
    {
     text: 'Minerals',
     value: 'Mine',
    },
    {
     text: 'Miscellaneous',
     value: 'Misc',
    },
    {
     text: 'Topical',
     value: 'Top',
    },
    {
     text: 'Vaccines',
     value: 'Vacc',
    },
    {
     text: 'Vet or Vet Technician to Administer Only',
     value: 'Vet',
    },
    {
     text: 'Vitamins',
     value: 'Vita',
    },
   ],
   onFilter: (value, record) => record.group.startsWith(value),
   filterSearch: true,
   sorter: (a, b) => a.group.localeCompare(b.group),
  },
  {
   title: 'Quantity Authorised',
   dataIndex: 'quantity_authorised',
   key: 'quantityAuthorised',
   sorter: (a, b) => (a.quantity_authorised || 0) - (b.quantity_authorised || 0),
   render: (text, record) => (
    <Form.Item
     name={[record.id, 'quantity_authorised']}
     initialValue={record.quantity_authorised}
    >
     <InputNumber
      min={0}
      onWheel={(e) => e.target.blur()}
      readOnly={isScrolling}
     />
    </Form.Item>
   ),
  },
  {
   title: 'Quantity on Farm',
   dataIndex: 'quantity_on_farm',
   key: 'quantity_on_farm',
   sorter: (a, b) => (a.quantity_on_farm || 0) - (b.quantity_on_farm || 0),
   render: (text, record) => (
    <Form.Item
     name={[record.id, 'quantity_on_farm']}
     initialValue={record.quantity_on_farm}
    >
     <InputNumber
      min={0}
      onWheel={(e) => e.target.blur()}
      readOnly={isScrolling}
     />
    </Form.Item>
   ),
  },
 ];

 const formContent = (
  <>
   <Input
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
    placeholder="Search items"
    style={{ marginBottom: '1em' }}
   />
   <Table
    dataSource={rvmItems.filter(item => item.item.toLowerCase().includes(searchQuery.toLowerCase()))}
    columns={columns}
    rowKey="id"
    pagination={false}
    scroll={{ y: '60vh' }}
   />
   <Divider />
   <Button type="primary" htmlType="submit">Save</Button>
   <Divider type='vertical' />
   <Button type="default" onClick={handleExcelButtonClick} disabled={loading}>
    RVM authorisation documents
   </Button>
  </>
 );

 return (
  <Spin spinning={loading}>
   <FormLayout
    title="RVM Items"
    form={form}
    onFinish={onFinish}
    extraContent={formContent}
    saveButton={false}
   />
  </Spin>
 );
};

export default RvmItemsTable;