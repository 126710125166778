import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Switch, message } from 'antd';
import supabase from '~supabaseConfig'; // Make sure this path is correct

const { Search } = Input;

export default function Admin() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async (search = '') => {
    setLoading(true);
    let query = supabase.from('users').select('*');
    
    if (search) {
      query = query.or(`email.ilike.%${search}%,name.ilike.%${search}%`);
    }

    const { data, error } = await query;

    if (error) {
      console.error('Error fetching users:', error);
      message.error('Failed to fetch users');
    } else {
      console.log(data);
      setUsers(data);
    }
    setLoading(false);
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    fetchUsers(value);
  };

  const toggleAdminStatus = async (record) => {
    const { data, error } = await supabase
      .from('users')
      .update({ admin: !record.admin })
      .eq('id', record.id)
      .select()
      .single();

    if (error) {
      console.error('Error updating admin status:', error);
      message.error('Failed to update admin status');
    } else {
      setUsers(users.map(user => user.id === data.id ? data : user));
      message.success(`Admin status updated for ${data.email}`);
    }
  };

  const toggleSuperAdminStatus = async (record) => {
    const { data, error } = await supabase
      .from('users')
      .update({ super_admin: !record.super_admin })
      .eq('id', record.id)
      .select()
      .single();

    if (error) {
      console.error('Error updating super admin status:', error);
      message.error('Failed to update super admin status');
    } else {
      setUsers(users.map(user => user.id === data.id ? data : user));
      message.success(`Super Admin status updated for ${data.email}`);
    }
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Admin Status',
      dataIndex: 'admin',
      key: 'admin',
      render: (admin, record) => (
        <Switch
          checked={admin}
          onChange={() => toggleAdminStatus(record)}
          disabled={record.super_admin} // Prevent changing admin status for super admins
        />
      ),
      sorter: (a, b) => a.admin - b.admin,
    },
    {
      title: 'Super Admin Status',
      dataIndex: 'super_admin',
      key: 'super_admin',
      render: (superAdmin, record) => (
        <Switch
          checked={superAdmin}
          onChange={() => toggleSuperAdminStatus(record)}
        />
      ),
      sorter: (a, b) => a.super_admin - b.super_admin,
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <h1>Admin Management</h1>
      <Search
        placeholder="Search users by email or name"
        onSearch={handleSearch}
        style={{ marginBottom: '20px' }}
        enterButton
      />
      <Table
        columns={columns}
        dataSource={users}
        rowKey="id"
        loading={loading}
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
}