import React, { useEffect, useState } from 'react';
import {  Typography, Select, Form, InputNumber, message, Row, Col } from 'antd';
import { useParams } from 'react-router-dom';
import supabase from '~supabaseConfig';
import TextArea from 'antd/es/input/TextArea';
import pageTemplate from '../../../components/pageTemplate';
import FormLayout from '../../../components/FormLayout';

const { Text } = Typography;
const { Option } = Select;
const FormItem = Form.Item;

function MilkQualityData() {
 const [formInitialized, setFormInitialized] = useState(false);
 const { farmId, rvmId } = useParams();
 const [herdNumber, setHerdSize] = useState(0);
 const [mastitisNumber, setMastitisNumber] = useState(0);
 const { form, onFinish } = pageTemplate(
  'rvm_milk_quality_data',
  'rvm_id',
  'milk_quality_data_id',
  farmId,
  rvmId,
  'rvm_id',
  'rvms'
 );

 const fetchMilkQualityData = async () => {
  try {
   const { data, error } = await supabase
    .from('rvm_milk_quality_data')
    .select()
    .eq('rvm_id', rvmId);

   if (error) {
    throw new Error(`Error fetching existing Milk Quality Data: ${error.message}`);
   }

   if (data && data.length > 0) {
    console.log('Existing Milk Quality Data data:', data);
    if (!formInitialized) {
     form.setFieldsValue(data[0]);
     setFormInitialized(true);
    }
   } else {
    message.warning('No existing Milk Quality Data data found.');
   }
  } catch (error) {
   console.error('Error fetching existing Milk Quality Data:', error);
   message.error('Error fetching existing Milk Quality Data. Please try again later.');
  }
 };

 const fetchFarmObjectivesData = async () => {
  try {
   const { data, error } = await supabase
    .from('rvm_milk_quality_data')
    .select('herd_size')
    .eq('rvm_id', rvmId)
    .limit(1);

   if (error) {
    throw new Error(`Error fetching farm objectives data: ${error.message}`);
   }
   if (data && data.length > 0 && data[0].herd_size > 1) {
    console.log('farm obj herd size')
    console.log(data)
    setHerdSize(data[0].herd_size);
   } else {
    console.log('fetching farm data herd size')
    fetchFarmData();
   }
  } catch (error) {
   console.error('Error fetching farm objectives data:', error);
   fetchFarmData();
  }
 };

 const fetchFarmData = async () => {
  try {
   const { data, error } = await supabase
    .from('farms')
    .select('herd_size')
    .eq('id', farmId)
    .limit(1);

   if (error) {
    throw new Error(`Error fetching farm data: ${error.message}`);
   }
   if (data) {
    console.log('farm obj size')
    console.log(data)
    setHerdSize(data[0].herd_size);
   }
  } catch (error) {
   console.error('Error fetching farm data:', error);
  }
 };

 useEffect(() => {
  fetchFarmObjectivesData();
 }, [farmId, rvmId]);

 useEffect(() => {
  fetchMilkQualityData();
 }, [farmId, rvmId, form, formInitialized]);


 // const onFinish = async (values) => {
 //  try {
 //   // Generate a UUID
 //   const milkQualityDataId = uuidv4();

 //   // Check if the record already exists in the milk_quality_data table
 //   const { data: existingData, error: selectError } = await supabase
 //    .from('rvm_milk_quality_data')
 //    .select('id')
 //    .eq('rvm_id', rvmId)
 //    .limit(1);

 //   if (selectError) {
 //    throw new Error(`Error checking existing Milk Quality Data: ${selectError.message}`);
 //   }

 //   if (existingData && existingData.length > 0) {
 //    // Update the existing record
 //    const { error: updateError } = await supabase
 //     .from('rvm_milk_quality_data')
 //     .update({ ...values })
 //     .eq('id', existingData[0].id);

 //    if (updateError) {
 //     throw new Error(`Error updating Milk Quality Data: ${updateError.message}`);
 //    }
 //   } else {
 //    // Upsert the new record into the Milk Quality Data table
 //    const { error: upsertError } = await supabase
 //     .from('rvm_milk_quality_data')
 //     .upsert([{ ...values, rvm_id: rvmId, id: milkQualityDataId }]);

 //    if (upsertError) {
 //     throw new Error(`Error saving Milk Quality Data: ${upsertError.message}`);
 //    }

 //    // Update the corresponding record in the rvms table with the milk_quality_data_id
 //    const { error: updateRvmError } = await supabase
 //     .from('rvms')
 //     .update({ milk_quality_data_id: milkQualityDataId })
 //     .eq('id', rvmId);

 //    if (updateRvmError) {
 //     throw new Error(`Error updating rvm with Milk Quality Data id: ${updateRvmError.message}`);
 //    }
 //   }

 //   message.success({
 //    content: 'Milk Quality Data saved successfully',
 //    style: {
 //     marginTop: '20vh',
 //     zIndex: 9999,
 //    },
 //   });
 //  } catch (error) {
 //   console.error('Error saving Milk Quality Data:', error);
 //   message.error({
 //    content: 'Error saving Milk Quality Data. Please try again later.',
 //    style: {
 //     marginTop: '20vh',
 //     zIndex: 9999,
 //    },
 //   });
 //  }
 // };
 return (
  <FormLayout title="Milk Quality Data" form={form} onFinish={onFinish}>
   <Row gutter={16} style={{ textAlign: 'left' }}>
    <Col span={12}>
     <FormItem
      label="Average BMSCC"
      name="average_bmscc"

      type="number"
     >
      <InputNumber style={{ width: '100%' }} placeholder="Average BMSCC" type="number" />
     </FormItem>
     <Text>(&lt;150,000)</Text>
    </Col>
    <Col span={12}>
     <Form.Item label="Clinical Mastitis - Total no. of cases" name="clinical_mastitis">
      <InputNumber style={{ width: '100%' }} type="number" />

     </Form.Item>
     <Text>
      Herd percentage: {herdNumber !== 0 ? ((mastitisNumber / herdNumber) * 100).toFixed(2) : 0}%
     </Text>
    </Col>
   </Row>
   <Row gutter={16} style={{ textAlign: 'left' }}>
    <Col span={12}>
     <FormItem
      label="Farm Target %"
      name="farm_target"
     >
      <InputNumber style={{ width: '100%' }} placeholder="Farm Target %" type="number" />
     </FormItem>
    </Col>
    <Col span={12}>
     <FormItem
      label="# of cows culled for mastitis or high SCC"
      name="number_cows_culled_mastitis"

     >
      <InputNumber style={{ width: '100%' }} />
     </FormItem>
    </Col>
   </Row>
   <Row gutter={16} style={{ textAlign: 'left' }}>
    <Col span={24}>
     <FormItem name="services" label="Tick services planning to use:">
      <Select
       mode="multiple"
       allowClear
       style={{
        width: '100%',
       }}
       placeholder="Please select"
       options={[
        { value: 'heifersafe_teatseal', label: 'Heifersafe Teatseal' },
        { value: 'esmqr_report', label: 'ESMQR Report' },
        { value: 'lsmqr_report', label: 'LSMQR Report' },
        { value: 'cultures', label: 'Cultures' },
        { value: 'antibiogram', label: 'Antibiogram' },
        { value: 'multimin_at_calving', label: 'Multimin at Calving' },
        { value: 'milking_assessment', label: 'Milking Assessment' },
        { value: 'teat_scoring', label: 'Teat Scoring' },
       ]}
      />
     </FormItem>
    </Col>
   </Row>
   <Typography.Title level={5} >Lactation treatment</Typography.Title>
   <Row gutter={16} style={{ textAlign: 'left' }}>
    <Col span={12}>
     <FormItem
      label="First case"
      name="first_case"
     >
      <Select allowClear>
       <Option value={"Intracillin MC"}>Intracillin MC</Option>
       <Option value={"Clavulox"}>Clavulox</Option>
       <Option value={"Spectrazol"}>Spectrazol</Option>
       <Option value={"Penclox"}>Penclox</Option>
       <Option value={"Orbenin LA"}>Orbenin LA</Option>
       <Option value={"Albiotic"}>Albiotic</Option>
       <Option value={"Mastiplan"}>Mastiplan</Option>
      </Select>
     </FormItem>
    </Col>
    <Col span={12}>
     <FormItem
      label="Repeat case"
      name="repeat_case"
     >
      <Select allowClear>
       <Option value={"Intracillin MC"}>Intracillin MC</Option>
       <Option value={"Clavulox"}>Clavulox</Option>
       <Option value={"Spectrazol"}>Spectrazol</Option>
       <Option value={"Penclox"}>Penclox</Option>
       <Option value={"Orbenin LA"}>Orbenin LA</Option>
       <Option value={"Albiotic"}>Albiotic</Option>
       <Option value={"Mastiplan"}>Mastiplan</Option>
       <Option value={"Mastalone"}>Mastalone</Option>
       <Option value={"Tylan"}>Tylan</Option>
      </Select>
     </FormItem>
    </Col>
   </Row>
   <Row gutter={16} style={{ textAlign: 'left' }}>
    <Col span={12}>
     <FormItem
      label="Multiple quarters"
      name="multiple_quarters"
     >
      <Select allowClear>
       <Option value={"Mamyzin"}>Mamyzin</Option>
       <Option value={"Penethaject"}>Penethaject</Option>
      </Select>
     </FormItem>
    </Col>
    <Col span={12}>
     <FormItem
      label="Anti Inflammatory"
      name="anti_inflammatory"
     >
      <Select allowClear>
       <Option value={"Metacam 40"}>Metacam 40</Option>
       <Option value={"Ketomax"}>Ketomax</Option>
      </Select>
     </FormItem>
    </Col>
   </Row>
   <Row gutter={16} style={{ textAlign: 'left' }}>
    <Col span={24}>
     <FormItem
      label="Plan"
      name="plan"
     >
      <TextArea
       placeholder="Controlled autosize"
       autoSize={{ minRows: 3, maxRows: 5 }}
      />
     </FormItem>
    </Col>
   </Row>
  </FormLayout >
 );
}

export default MilkQualityData;
