import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Divider, Space, Input, DatePicker, Typography, Select, Checkbox } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Box } from '@mui/joy';
import { useParams } from 'react-router-dom';
import pageTemplate from '../../components/pageTemplate';
import BehaviourColumn from './components/BehaviourColumn';
import LayoutBox from '../../components/LayoutBox';
import FormLayout from '../../components/FormLayout';
import supabase from '~supabaseConfig';

const { TextArea } = Input;
const { Option } = Select;

const Behaviour = () => {
 const supabaseTableName = 'wellbeing_consult_behaviour_data';
 const consultSupabaseTable = 'wellbeing_consult_id';
 const supabaseTableId = 'behaviour_data_id';
 const consultIdName = 'wellbeing_consult_id';
 const { farmId, wellbeingConsultId } = useParams();
 const { form, onFinish } = pageTemplate(
  supabaseTableName,
  consultSupabaseTable,
  supabaseTableId,
  farmId,
  wellbeingConsultId,
  consultIdName,
  'wellbeing_consults'
 );

 const [cannedNotes, setCannedNotes] = useState([]);

 const arr = [
  { label: 'Polled', value: 'Polled' },
  { label: 'Heat tolerance', value: 'Heat tolerance' },
  { label: 'Facial eczema', value: 'Facial eczema' },
  { label: 'Udder health', value: 'Udder health' },
  { label: 'Lameness', value: 'Lameness' },
  { label: 'Dairy beef', value: 'Dairy beef' },
  { label: 'Sexed semen', value: 'Sexed semen' },
  { label: 'Other', value: 'Other' }
 ];

 useEffect(() => {
  fetchCannedNotes();
 }, []);

 const fetchCannedNotes = async () => {
  try {
   // First, fetch the UUID for the "BCS" category
   const { data: categoryData, error: categoryError } = await supabase
    .from('wellbeing_canned_notes_categories')
    .select('id')
    .eq('category', 'Behaviour')
    .single();

   if (categoryError) throw categoryError;

   if (!categoryData) {
    console.error('BCS category not found');
    return;
   }

   // Now use the category UUID to fetch the notes
   const { data, error } = await supabase
    .from('wellbeing_canned_notes')
    .select(`
         id,
         note,
         category (
           id,
           category
         )
       `)
    .eq('category', categoryData.id);

   if (error) throw error;

   if (data) {
    // Map the data to extract notes
    const notes = data.map(item => item.note);
    setCannedNotes(notes);
   }
  } catch (error) {
   console.error('Error fetching canned notes:', error);
  }
 };

 const handleNoteChange = (value) => {
  form.setFieldsValue({ behaviour_plan: value.join('\n') });
 };

 return (
  <FormLayout title="Behaviour" form={form} onFinish={onFinish}>
   <Form.Item name="behaviour_result">
    <Checkbox.Group options={arr} />
   </Form.Item>
   <Row gutter={16}>
    <Col span={18}>
     <Form.Item name="behaviour_plan" label="Plan">
      <TextArea rows={4} />
     </Form.Item>
    </Col>
    <Col span={6}>
     <Form.Item label="Canned Notes">
      <Select
       mode="multiple"
       style={{ width: '100%' }}
       placeholder="Select notes"
       onChange={handleNoteChange}
      >
       {cannedNotes.map((note, index) => (
        <Option key={index} value={note}>
         {note}
        </Option>
       ))}
      </Select>
     </Form.Item>
    </Col>
   </Row>
  </FormLayout>
 );
};

export default Behaviour;