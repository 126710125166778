import React, { useState } from 'react';
import { Form, Row, Col, Input, DatePicker, Typography, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
const { Option } = Select;

export default function FormRow({ title, rowName, notes, form, target }) {
 const { Title, Text } = Typography;

 const [selectedNotes, setSelectedNotes] = useState([]);

 const handleNoteChange = (value) => {
  setSelectedNotes(value);
  const newValue = value.join('\n');
  form.setFieldsValue({ [`${rowName}_plan`]: newValue });
 };

 return (
  <>
   <Row gutter={16}>
    <Col span={24} style={{ textAlign: 'left' }}>
     <Title level={3}>{title}</Title>
    </Col>
   </Row>
   <Row gutter={16}>
    <Col span={12}>
     <Form.Item name={`${rowName}_farm_result`} label={`Current farm result`}>
      <Input />
     </Form.Item>
    </Col>
   </Row>
   <Row gutter={16}>
    <Col style={{ textAlign: 'left' }} span={14}>
     <Title level={5} style={{ marginTop: 0 }}>Industry target</Title>
    </Col>
   </Row>
   <Row gutter={16} style={{marginBottom: 20}}>
    <Col style={{ textAlign: 'left' }} span={16} >
     <Text>{target}</Text>
    </Col>
   </Row >
   {/* <Col span={8}>
          <Form.Item name={`${rowName}_season_target`} label={`Target this season`}>
            <Input />
          </Form.Item>
        </Col> */}
   <Row gutter={16}>
    <Col style={{ textAlign: 'left' }} span={14}>
     <Title level={5} style={{ marginTop: 0 }}>Plan</Title>
    </Col>
   </Row>
   <Row gutter={16} >
    <Col span={14}>
     <Form.Item name={`${rowName}_plan`}>
      <Input.TextArea
       size="large"
       style={{ height: 120 }}
       autoSize={{
        minRows: 2,
        maxRows: 6,
       }}
       placeholder="Type notes"
       onChange={(e) => form.setFieldsValue({ [`${rowName}_plan`]: e.target.value })}
      />
     </Form.Item>
    </Col>
    <Col span={10}>
     {notes && notes.length > 0 && (
      <Form.Item label="Canned Notes">
       <Select
        mode="multiple"
        style={{ width: '100%' }}
        placeholder="Select notes"
        onChange={handleNoteChange}
        value={selectedNotes}
       >
        {notes.map((note, index) => (
         <Option key={index} value={note}>
          {note}
         </Option>
        ))}
       </Select>
      </Form.Item>
     )}
    </Col>
   </Row >
  </>
 );
}