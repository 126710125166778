import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Row, Col, Divider, Space, Input, message, DatePicker, Typography, Select } from 'antd';
import { Box } from '@mui/joy';
import { useParams } from 'react-router-dom';
import pageTemplate from '../../components/pageTemplate';
import FormRow from './components/FormRow';
import supabase from '~supabaseConfig';
import LayoutBox from '../../components/LayoutBox';
import FormLayout from '../../components/FormLayout';

const { Title, Text } = Typography;

const YoungStockHealth = () => {
 const supabaseTableName = 'wellbeing_consult_youngstock_health_data';
 const consultSupabaseTable = 'wellbeing_consult_id';
 const supabaseTableId = 'youngstock_health_data_id';
 const consultIdName = 'wellbeing_consult_id';

 const { farmId, wellbeingConsultId } = useParams();
 const [cannedNotes, setCannedNotes] = useState()
 const [rvmData, setRvmData] = useState(null);
 const [consultDate, setConsultDate] = useState('')

 const [arr, setArr] = useState([
  { title: 'Calf health', rowName: 'calf_health', target: 'Describe hygiene practices for: Between and within pens | Feeding equipment | Pen to paddock | <10% clinical disease to weaning' },
  { title: 'Calf nutrition', rowName: 'calf_nutrition', target: 'Feed colostrum: >22% Brix 10% bodyweight within 12hr | Ad lib clean water and high quality hard feed | Weaned at breed target weight' },
  { title: 'Calf housing', rowName: 'calf_housing', target: 'Space per calf target 1.5-2.5m2 | Group size per pen | Substrate/Bedding (material and condition) | Quarantine area | Outdoor shelter ' },
  { title: 'Youngstock parasite management', rowName: 'youngstock_parasite_management', target: 'Parasite management programme including FEC testing and FECRTs' },
  { title: 'Youngstock LWT targets', rowName: 'youngstock_lwt_targets', target: '30% by six months, 60% by 15 months, 90% by 22 months and 5.5BCS at calving ' },
  { title: 'Heifer health plan', rowName: 'heifer_health', target: 'Trace elements | Nutrition ' },
  { title: 'Youngstock grazing plan', rowName: 'youngstock_grazing', target: 'Grazier contract OR plan for grazing at run-off ' },
  { title: 'Heifer mating plan', rowName: 'heifer_mating', target: 'Synchrony | AB plan | Bull management | 5.5 BCS at calving' },
 ]);
 const fetchRvmData = async (rvmId) => {
  try {
   const [rvmHealthData, rvmFarmObjectives] = await Promise.all([
    supabase.from('rvm_health_data').select('*').eq('rvm_id', rvmId).single(),
    supabase.from('rvm_farm_objectives_data').select('*').eq('rvm_id', rvmId).single(),
   ]);

   return {
    rvmHealthData: rvmHealthData.data,
    rvmFarmObjectives: rvmFarmObjectives.data,
   };
  } catch (error) {
   console.error('Error fetching RVM data:', error);
   message.error('Error fetching RVM data. Please try again later.');
   return null;
  }
 };

 const fetchWellbeingConsultData = async () => {
  try {
   const { data, error } = await supabase
    .from('wellbeing_consults')
    .select('start_date, end_date')
    .eq('id', wellbeingConsultId)
    .single();

   if (error) {
    throw new Error(`Error fetching wellbeing consult data: ${error.message}`);
   }

   return data;
  } catch (error) {
   console.error('Error fetching wellbeing consult data:', error);
   message.error('Error fetching wellbeing consult data. Please try again later.');
   return null;
  }
 };

 const fetchRvmId = async (startYear, endYear) => {
  try {
   const { data, error } = await supabase
    .from('rvms')
    .select('id')
    .eq('start_date', startYear)
    .eq('end_date', endYear);

   if (error) {
    throw new Error(`Error fetching RVM ID: ${error.message}`);
   }

   if (data.length === 0) {
    console.warn('No RVM found for the specified date range');
    return null;
   } else if (data.length > 1) {
    console.warn('Multiple RVMs found for the specified date range, using the first one');
    return data[0].id;
   } else {
    return data[0].id;
   }
  } catch (error) {
   console.error('Error fetching RVM ID:', error);
   message.error('Error fetching RVM ID. Please try again later.');
   return null;
  }
 };

 useEffect(() => {
  const fetchData = async () => {
   const wellbeingConsultData = await fetchWellbeingConsultData();
   if (wellbeingConsultData) {
    const { start_date, end_date } = wellbeingConsultData;
    const rvmId = await fetchRvmId(start_date, end_date);
    setConsultDate(`${start_date} - ${end_date}`)
    if (rvmId) {
     const fetchedRvmData = await fetchRvmData(rvmId);
     setRvmData(fetchedRvmData);
    }
   }
  };

  fetchData();
  fetchCannedNotes();
 }, []);

 const calculatePercentage = (value, total) => {
  if (value === null || total === null || total === 0) {
   return null;
  }
  return ((value / total) * 100).toFixed(2) + '%';
 };

 const fillFormFields = () => {
  if (!rvmData) {
   message.warning('RVM data not available. Please try again later.');
   return;
  }

  const { rvmHealthData, rvmFarmObjectives } = rvmData;

  const loadedFields = [];

  if (rvmHealthData && rvmFarmObjectives && rvmFarmObjectives.calves_reared !== null) {
   const calfScourPercentage = calculatePercentage(rvmHealthData.calves_navel_swollen_joints, rvmFarmObjectives.calves_reared);
   const navelIIIPercentage = calculatePercentage(rvmHealthData.calves_scours, rvmFarmObjectives.calves_reared);

   let calfHealthFarmResult = '';

   if (calfScourPercentage !== null) {
    calfHealthFarmResult += `Navel III / Swollen Joints: ${calfScourPercentage}`;
   }

   if (navelIIIPercentage !== null) {
    if (calfHealthFarmResult !== '') {
     calfHealthFarmResult += ', ';
    }
    calfHealthFarmResult += `Scours: ${navelIIIPercentage}`;
   }

   if (calfHealthFarmResult !== '') {
    form.setFieldsValue({ calf_health_farm_result: calfHealthFarmResult });
    loadedFields.push('Calf Health Farm Result');
   }
  }

  if (loadedFields.length > 0) {
   message.success(`Data loaded successfully for: ${loadedFields.join(', ')}`);
  } else {
   message.info('No data available to load');
  }
 };

 const { form, onFinish } = pageTemplate(
  supabaseTableName,
  consultSupabaseTable,
  supabaseTableId,
  farmId,
  wellbeingConsultId,
  consultIdName,
  'wellbeing_consults'
 );



 const categoriesArr = []

 arr.map((note) => {
  categoriesArr.push(note.title)
 })

 console.log(categoriesArr);

 const fetchCannedNotes = async () => {
  console.log('Fetching canned notes...');
  try {
    const categories = arr.map(item => item.title);
    console.log('Categories we are looking for:', categories);
    const groupedNotes = {};

    const { data: allCategories, error: categoriesError } = await supabase
      .from('wellbeing_canned_notes_categories')
      .select('id, category');

    if (categoriesError) throw categoriesError;

    console.log('All categories from database:', allCategories.map(c => c.category));

    for (const category of categories) {
      console.log(`Searching for category: ${category}`);

      const categoryData = allCategories.find(c => c.category.toLowerCase() === category.toLowerCase());

      if (!categoryData) {
        console.log(`Category "${category}" not found in database`);
        continue;
      }

      console.log(`Found category: ${categoryData.category} with id: ${categoryData.id}`);

      const { data, error } = await supabase
        .from('wellbeing_canned_notes')
        .select(`
          id,
          note,
          category (
            id,
            category
          )
        `)
        .eq('category', categoryData.id);

      if (error) {
        console.error(`Error fetching notes for ${category}:`, error);
        continue;
      }

      if (data && data.length > 0) {
        groupedNotes[category] = data.map(item => item.note);
        console.log(`Found ${data.length} notes for category "${category}"`);
      } else {
        console.log(`No notes found for category "${category}"`);
      }
    }

    console.log('Final grouped notes:', groupedNotes);
    setCannedNotes(groupedNotes);

    // Update arr with the fetched notes
    setArr(prevArr => prevArr.map(item => ({
      ...item,
      notes: groupedNotes[item.title] || [],
    })));

  } catch (error) {
    console.error('Error in fetchCannedNotes:', error);
  }
};
 return (
  <FormLayout
   title="Youngstock Health"
   form={form}
   onFinish={onFinish}
   consultDate={consultDate}
   onLoadRvmData={fillFormFields}
  >
   {arr.map((row) => {
    return (
     <FormRow
      key={row.rowName}
      title={row.title}
      rowName={row.rowName}
      notes={row.notes}
      target={row.target}
      form={form}
     />
    );
   })}
  </FormLayout>
 );
};

export default YoungStockHealth;