import React from 'react';
import { Button, Table, Modal } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import supabase from '~supabaseConfig';
import YearPicker from './YearPicker';

const { confirm } = Modal;

const ConsultList = ({ data, onDateChanged, tableName, linkRoute }) => {
  const { farmId } = useParams();

  const handleDeleteConsult = async (consultId) => {
    confirm({
      title: 'Are you sure you want to delete this consult?',
      content: 'This action cannot be undone.',
      onOk: async () => {
        try {
          const { data, error } = await supabase
            .from(tableName)
            .delete()
            .match({ id: consultId });

          if (error) {
            throw error;
          }

          console.log('Consult deleted successfully', data);

          if (onDateChanged) {
            onDateChanged();
          }
        } catch (error) {
          console.error('Error deleting consult:', error.message);
        }
      },
      onCancel() {
        console.log('Delete cancelled');
      },
    });
  };

  const columns = [
    {
      title: 'Year Range',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (text, record) => (
        <Link to={`/farm/${farmId}/${linkRoute}/${record.id}/`}>
          {record.start_date} - {record.end_date}
        </Link>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record, index) => (
        <Button
          type="link"
          key={`delete-button-${index}`}
          onClick={() => handleDeleteConsult(record.id)}
        >
          <CloseOutlined />
        </Button>
      ),
    },
  ];

  if (!data) {
    return (
      <div>
        <p>No consults available</p>
      </div>
    );
  }

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowKey={(record) => record.id.toString()}
      pagination={false}
      footer={() => (
        <YearPicker
          farmId={farmId}
          onDateAdded={onDateChanged}
          tableName={tableName}
        />
      )}
    />
  );
};

export default ConsultList;