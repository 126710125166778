import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import supabase from '~supabaseConfig';
import { Typography, Card, Empty, Form, Button, message, Table } from 'antd';
import { CalendarOutlined, SaveOutlined } from '@ant-design/icons';

const { Title } = Typography;
const testingOutputMapping = {
    'Herd': { price: 1, title: 'BVD Herd Monitoring - first test' },
    'Heifers': { price: 1, title: 'BVD Testing Heifers', },
    'Calves': { price: 1, title: 'BVD Testing Calves' },
    'Bulls': { price: 1, title: 'BVD Testing Bulls' },
};

const vaccinationOutputMapping = {
    'Herd': { price: 1, title: 'BVD Vaccination Herd' },
    'Heifers': [
        { price: 1, title: 'BVD Vacc - Heifers - first' },
        { price: 1, title: 'BVD Vacc - Heifers - second' }
    ],
    'Calves': [
        { price: 1, title: 'BVD Vacc - Calves - first' },
        { price: 1, title: 'BVD Vacc - Calves - second' }
    ],
    'Bulls': { price: 1, title: 'BVD Vaccination Bulls' }
};

const serviceOutputMapping = {
    'heifersafe_teatseal': { price: 1, title: 'Heifersafe Teatseal' },
    'esmqr_report': { price: 1, title: 'Early season mastitis report' },
    'lsmqr_report': { price: 1, title: 'Late season mastitis report' },
    'cultures': { price: 1, title: 'Mastitis milk cultures' },
    'antibiogram': { price: 1, title: 'Antibiogram' },
    'multimin_at_calving': { price: 1, title: 'Multimin at Calving' },
    'milking_assessment': { price: 1, title: 'Mastitis Milking Assessment' },
    'teat_scoring': { price: 1, title: 'Teat Scoring' },
    'FVS Early': [
        { price: 1, title: 'Pregnancy Testing early scan' },
        { price: 1, title: 'Pregnancy Testing final scan' }
    ],
    'FVS late only': [
        { price: 1, title: 'Pregnancy Testing' },
        { price: 1, title: 'Pregnancy Testing Heifers' }
    ],
    'dry_off_guide': { price: 1, title: 'Dry off guide' },
    'heifer_synchrony': { price: 1, title: 'Heifer synchrony' },
    'Metrichecked': { price: 1, title: 'Metrichecking - first visit' },
    'Why Wait': { price: 1, title: 'Why Wait - first visit' },
    'NVO Treatment': { price: 1, title: 'Non cycler treatment - first visit' },
    'Bull Fertility Test': { price: 1, title: 'Bull Fertility Testing' },
    'Teaser Bulls': { price: 1, title: 'Teaser Bull Surgery' },
    'Plan to Mate': { price: 1, title: 'Plan to Mate Consult' },
    'Repro review': { price: 1, title: 'Repro Review Consult' },
    'Incalf program': [
        { price: 1, title: 'Pre-Calving InCalf Visit' },
        { price: 1, title: 'Pre-Mating InCalf Visit' },
        { price: 1, title: 'Mid Lactation InCalf Visit' },
        { price: 1, title: 'Late Lactation InCalf Visit' }
    ],
    'welfarm': [
        { price: 1, title: 'BCS Pre-calving' },
        { price: 1, title: 'BCS Pre-mating' },
        { price: 1, title: 'BCS Mid Lactation' },
        { price: 1, title: 'BCS Pre Dry off' },
        { price: 1, title: 'Tail Score' },
        { price: 1, title: 'Locomotion Score' }
    ],
    'transition_plan': { price: 1, title: 'Transition Consult' },
    'transition_bloods': { price: 1, title: 'Transition Bloods' },
    'collar_modules': [
        { price: 1, title: 'Transition Collar Module' },
        { price: 1, title: 'Mating Collar Module' }
    ],
    'johnes_consult': { price: 1, title: 'Johnes Consult' },
    'healthy_hoof': { price: 1, title: 'Healthy Hoof' },
    'herd_drench': { price: 1, title: 'Herd Drench' },
    'salvexin': [
        { price: 1, title: 'Salvexin Vacc Herd' },
        { price: 1, title: 'Salvexin Vacc Heifers' },
        { price: 1, title: 'Salvexin Vacc Calves - first' },
        { price: 1, title: 'Salvexin Vacc Calves - second' }
    ],
    'rotavec': { price: 1, title: 'Rotavec Vacc' },
    'calf_wof': { price: 1, title: 'Calf wof' },
    'young_stock_health_plan': { price: 1, title: 'Youngstock health plan' },
    'debudding': { price: 1, title: 'Debudding' },
    'covexin': [
        { price: 1, title: 'Covexin Vacc Calves - first' },
        { price: 1, title: 'Covexin Vacc Calves - second' }
    ],
    'custom_clostridial': [
        { price: 1, title: 'Clostridial Vacc Calves - first' },
        { price: 1, title: 'Clostridial Vacc Calves - second' },
    ],
    'copacaps': { price: 1, title: 'Copacaps' },
    'agvance': { price: 1, title: 'Agvance' },
    'ruminate': { price: 1, title: 'Ruminate' },
    'next_planned_mineral_check': { price: 1, title: 'Liver biopsies' },
    'collar modules': [
        { price: 1, title: 'Transition Collar Module' },
        { price: 1, title: 'Mating Collar Module' }
    ],
    'johnes consult': { price: 1, title: 'Johnes Consult' },
    'healthy hoof': { price: 1, title: 'Healthy Hoof' },
    'all': [
        { price: 1, title: "Lepto Vacc Herd" },
        { price: 1, title: "Lepto Vacc Heifers" },
        { price: 1, title: "Lepto Vacc Calves - first" },
        { price: 1, title: "Lepto Vacc Calves - second" },
        { price: 1, title: "Lepto Vacc Calves - third" },
        { price: 1, title: "Leptosafe Consult" },
    ],
    'dry cow': [
        { price: 1, title: "Milking time visit post calving" },
        { price: 1, title: "Early season mastitis review" },
        { price: 1, title: "Milk Quality consult/s" },
        { price: 1, title: "On farm training" },
        { price: 1, title: "Milking efficiency visit" },
    ]

};

// Updated target arrays to match the database format
const targetArrays = {
    milk_quality_data_id: {
        field: "services",
        label: "Milk Quality Data",
        array: [
            "heifersafe_teatseal", "esmqr_report", "lsmqr_report", "cultures",
            "antibiogram", "multimin_at_calving", "milking_assessment", "teat_scoring"
        ]
    },
    repro_id_scanning: {
        field: "scanning",
        label: "Repro Scanning Data",
        array: ["FVS Early", "FVS late only"]
    },
    repro_id_services: {
        field: "services",
        label: "Repro Services Data",
        array: [
            "dry_off_guide", "heifer_synchrony", "Metrichecked", "Why Wait",
            "NVO Treatment", "Bull Fertility Test", "Teaser Bulls",
            "Plan to Mate", "Repro review", "Incalf program"
        ]
    },
    repro_id_herd_animals_vaccinated: {
        field: "herd_animals_vaccinated",
        label: "Repro Vaccination Data",
        array: ["Herd", "Heifers", "Calves", "Bulls"]
    },
    repro_id_herd_animals_tested: {
        field: "herd_animals_tested",
        label: "Repro Testing Data",
        array: ["Herd", "Heifers", "Calves", "Bulls"]
    },
    health_id_services: {
        field: "services",
        label: "Health Services Data",
        array: [
            "welfarm", "transition_plan", "transition_bloods", "collar_modules",
            "johnes_consult", "healthy_hoof", "herd_drench", "salvexin",
            "rotavec", "calf_wof", "young_stock_health_plan", "debudding",
            "covexin", "copacaps", "agvance", "ruminate", "next_planned_mineral_check"
        ]
    }
};

// Updated process data function to handle different mappings based on category
const processData = (dataField, label, targetArray) => {
    // Standardize `dataField` to an array and extract names if they are objects
    const services = dataField
        ? Array.isArray(dataField)
            ? dataField.map(item => (typeof item === 'string' ? item : item.name))
            : [typeof dataField === 'string' ? dataField : dataField.name]
        : [];

    if (services.length === 0) {
        console.log(`No valid services for ${label}`);
        return [];
    }

    const getMapping = (service) => {
        switch (label) {
            case "Repro Testing Data":
                return testingOutputMapping[service] || [];
            case "Repro Vaccination Data":
                return vaccinationOutputMapping[service] || [];
            default:
                const mapping = serviceOutputMapping[service];
                if (Array.isArray(mapping)) {
                    return mapping; // Return the full array of objects
                }
                return mapping ? [mapping] : []; // Return as array for consistency
        }
    };

    let mappedOutputs = services.flatMap(service => {
        const normalizedService = normalizeServiceName(service);
        // Check both original and normalized service names
        const mapping = getMapping(service) || getMapping(normalizedService);
        return mapping || [];
    });

    // If "covexin" is not present and we're processing health services, add the custom_clostridial data
    if (label === "Health Services Data" && !services.includes('covexin')) {
        const clostridialMapping = getMapping('custom_clostridial');
        if (clostridialMapping) {
            mappedOutputs = [...mappedOutputs, ...clostridialMapping];
        }
    }

    // Log the processed output for debugging
    console.log(`Processed ${label}:`, mappedOutputs);
    return mappedOutputs;
};

// Update the normalizeServiceName function to be more robust
const normalizeServiceName = (service) => {
    if (!service) return '';
    return service
        .toLowerCase()
        .replace(/[_\s-]/g, '') // Remove underscores, spaces, and hyphens
        .trim();
};

const getColumnName = (service) => {
    return service
        .toLowerCase()
        .replace(/-/g, '_') // Replace hyphens with underscores
        .replace(/[^a-z0-9\s_]/g, '') // Remove special characters, keeping underscores
        .replace(/\s+/g, '_') // Replace spaces with single underscores
        .replace(/__+/g, '_') // Collapse multiple underscores into a single one
        .concat('_date'); // Add _date suffix
};



const AnimalHealthPlanner = () => {
    const { farmId, rvmId } = useParams();
    const [form] = Form.useForm();
    const [rvmData, setRvmData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [existingPlannerData, setExistingPlannerData] = useState(null);
    const [milkQualityServicesState, setMilkQualityServicesState] = useState([]);
    const [reproServicesState, setReproServicesState] = useState([]);
    const [reproVaccinationState, setReproVaccinationState] = useState([]);
    const [reproTestingState, setReproTestingState] = useState([]);
    const [reproScanningState, setReproScanningState] = useState([]);
    const [healthServicesState, setHealthServicesState] = useState([]);
    const [allServicesState, setAllServicesState] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [milkQualityData, setMilkQualityData] = useState();
    const [dryCowData, setDryCowData] = useState();

    const calculateServiceTotal = (services) => {
        if (!services || services.length === 0) return 0;
        return services.reduce((sum, service) => {
            const price = typeof service === 'string' ? 1 : (service.price || 1);
            return sum + price;
        }, 0);
    };

    const fetchExistingPlannerData = async () => {
        try {
            const { data, error } = await supabase
                .from('rvm_animal_health_planner_data')
                .select('*')
                .eq('rvm_id', rvmId)
                .single();

            if (error && error.code !== 'PGRST116') { // PGRST116 is the "not found" error code
                throw error;
            }

            if (data) {
                setExistingPlannerData(data);
                form.setFieldsValue(data);
            }
        } catch (error) {
            console.error('Error fetching existing planner data:', error);
            message.error('Failed to load existing planner data');
        }
    };

    const fetchRvmData = async () => {
        try {
            const { data, error } = await supabase
                .from('rvms')
                .select(`
                    start_date,
                    end_date,
          farms:farm_id (
            id,
            farm_name,
            farm_size,
            email,
            phone_number,
            address,
            herd_size,
            heifer_size,
            farmer_name,
            clinic_id (
              id,
              clinic_name,
              address
            ),
            vet (
              id,
              name,
              email
            ),
            supply_number
          ),
          milk_quality_data_id (
            services
          ),
          repro_id (
            scanning,
            services,
            herd_testing,
            herd_animals_tested,
            herd_animals_vaccinated
          ),
          health_id (
            services
          )
        `)
                .eq('id', rvmId) // Query based on rvmId from URL params
                .single();

            if (error) {
                console.error('Supabase query error:', error);
                throw error;
            }

            if (!data) {
                console.warn(`No data found for rvmId ${rvmId}`);
                throw new Error(`No data found for rvmId ${rvmId}`);
            }

            setRvmData(data); // Store the data in state
            setLoading(false);
            if (data.start_date && data.start_date) {
                setStartDate(data.start_date)
                setEndDate(data.end_date)
                fetchMilkQualityData()
            }
        } catch (error) {
            console.error('Error fetching RVM data:', error);
            setLoading(false);
        }
    };

    const fetchMilkQualityData = async () => {
        console.log('fetching milk consult data')
        try {
            const { data, error } = await supabase
                .from('milk_quality_consults')
                .select('*')
                .eq('farm_id', farmId)
                .gte('start_date', startDate)
                .lte('end_date', endDate);// Query based on rvmId from URL params

            if (error) {
                console.error('Supabase query error:', error);
                throw error;
            }

            if (!data) {
                console.warn(`No data found for rvmId ${rvmId}`);
                throw new Error(`No data found for rvmId ${rvmId}`);
            }

            setMilkQualityData(data); // Store the data in state
            console.log('fetched milk quality data')
            console.log(data)
            setLoading(false);
        } catch (error) {
            console.error('Error fetching Milk Quality data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (rvmId) {
            fetchRvmData();
            fetchExistingPlannerData();
        }
    }, [rvmId]);

    useEffect(() => {
        if (rvmData) {
            console.log('rvm data below ')
            console.log(rvmData)
            // Process and update state for each service category
            setMilkQualityServicesState(processData(
                rvmData.milk_quality_data_id?.services,
                targetArrays.milk_quality_data_id.label,
                targetArrays.milk_quality_data_id.array
            ));

            setReproServicesState(processData(
                rvmData.repro_id?.services,
                targetArrays.repro_id_services.label,
                targetArrays.repro_id_services.array
            ));

            setReproScanningState(processData(
                rvmData.repro_id?.scanning,
                targetArrays.repro_id_scanning.label,
                targetArrays.repro_id_scanning.array
            ));

            setReproVaccinationState(processData(
                rvmData.repro_id?.herd_animals_vaccinated,
                targetArrays.repro_id_herd_animals_vaccinated.label,
                targetArrays.repro_id_herd_animals_vaccinated.array
            ));

            setReproTestingState(processData(
                rvmData.repro_id?.herd_animals_tested,
                targetArrays.repro_id_herd_animals_tested.label,
                targetArrays.repro_id_herd_animals_tested.array
            ));

            setHealthServicesState(processData(
                rvmData.health_id?.services,
                targetArrays.health_id_services.label,
                targetArrays.health_id_services.array
            ));

            setAllServicesState(serviceOutputMapping['all']);
            setDryCowData(serviceOutputMapping['dry cow']);
        }
    }, [rvmData]);


    if (loading) {
        return <div>Loading...</div>;
    }
    const handleFormSubmit = async (values) => {
        console.log(values)
        setSaving(true);
        try {
            // Convert any moment objects to ISO strings
            const formattedValues = Object.entries(values).reduce((acc, [key, value]) => {
                acc[key] = value && value.toISOString ? value.toISOString() : value;
                return acc;
            }, {});

            // Add rvm_id to the data
            const dataToSubmit = {
                ...formattedValues,
                rvm_id: rvmId,
                updated_at: new Date().toISOString()
            };

            let operation;
            if (existingPlannerData?.id) {
                // Update existing record
                operation = supabase
                    .from('rvm_animal_health_planner_data')
                    .update(dataToSubmit)
                    .eq('rvm_id', rvmId);
            } else {
                // Insert new record
                operation = supabase
                    .from('rvm_animal_health_planner_data')
                    .insert([dataToSubmit]);
            }

            const { data, error } = await operation;

            if (error) throw error;

            message.success('Saved successfully');

            // Refresh existing data
            fetchExistingPlannerData();

        } catch (error) {
            console.error('Error saving data:', error);
            message.error('Failed to save data');
        } finally {
            setSaving(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }
    const columns = [
        {
            title: 'Service',
            dataIndex: 'title',
            key: 'title',
            width: '50%',
        },
        {
            title: 'Date',
            dataIndex: 'title',
            key: 'date',
            width: '30%',
            render: (title) => (
                <Form.Item
                    name={getColumnName(title)}
                    className="m-0"
                    style={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'flex-start'
                    }}
                >
                    <input
                        type="date"
                        className="w-full px-2 py-1 border rounded"
                    />
                </Form.Item>
            ),
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            width: '20%',
            align: 'right',
            render: (price) => `$${price.toFixed(2)}`,
        },
    ];

    const getAllTotals = () => {
        const totals = [
            { category: 'Milk Quality Services', total: calculateServiceTotal(milkQualityServicesState) },
            { category: 'Repro Services', total: calculateServiceTotal(reproServicesState) },
            { category: 'Repro Vaccinations', total: calculateServiceTotal(reproVaccinationState) },
            { category: 'Repro Scanning', total: calculateServiceTotal(reproScanningState) },
            { category: 'Repro Testing', total: calculateServiceTotal(reproTestingState) },
            { category: 'Health Services', total: calculateServiceTotal(healthServicesState) },
            { category: 'Lepto Vaccination Services', total: calculateServiceTotal(allServicesState) },
            { category: 'Dry Cow Services', total: calculateServiceTotal(dryCowData) },
        ];

        const grandTotal = totals.reduce((sum, item) => sum + item.total, 0);
        return { categoryTotals: totals, grandTotal };
    };

    const totalColumns = [
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            width: '70%',
        },
        {
            title: 'Total Cost',
            dataIndex: 'total',
            key: 'total',
            width: '30%',
            align: 'right',
            render: (total) => `$${total.toFixed(2)}`,
        },
    ];

    const ServiceTable = ({ title, services }) => {
        if (!services || services.length === 0) return null;

        const tableData = services.map((service, index) => ({
            key: index,
            title: typeof service === 'string' ? service : service.title,
            price: typeof service === 'string' ? 1 : (service.price || 1),
        }));

        const totalPrice = tableData.reduce((sum, item) => sum + item.price, 0);

        const summary = (pageData) => {
            return (
                <Table.Summary fixed>
                    <Table.Summary.Row className="bg-gray-50 font-medium">
                        <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                        <Table.Summary.Cell index={2} align="right">
                            ${totalPrice.toFixed(2)}
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                </Table.Summary>
            );
        };

        return (
            <div className="mb-8">
                <h2 className="text-xl font-bold mb-4">{title}</h2>
                <Table
                    columns={columns}
                    dataSource={tableData}
                    pagination={false}
                    bordered
                    size="middle"
                    className="shadow-sm"
                    summary={summary}
                />
            </div>
        );
    };

    console.log('s')

    return (
        <div style={{
            padding: '0 2em 0 2em'
        }}>
            <h1 className="text-2xl font-bold mb-6">Animal Health Planner Data</h1>
            <Form
                form={form}
                onFinish={handleFormSubmit}
                layout="vertical"
            >
                <div className="space-y-8">
                    <ServiceTable title="Milk Quality Services" services={milkQualityServicesState} />
                    <ServiceTable title="Repro Services" services={reproServicesState} />
                    <ServiceTable title="Repro Vaccinations" services={reproVaccinationState} />
                    <ServiceTable title="Repro Scanning" services={reproScanningState} />
                    <ServiceTable title="Repro Testing" services={reproTestingState} />
                    <ServiceTable title="Health Services" services={healthServicesState} />
                    <ServiceTable title="Lepto Vaccination Services" services={allServicesState} />
                    <ServiceTable title="Dry Cow Services" services={dryCowData} />

                    {/* Add Summary Table */}
                    <div className="mt-12">
                        <h2 className="text-xl font-bold mb-4">Total Summary</h2>
                        <Table
                            columns={totalColumns}
                            dataSource={getAllTotals().categoryTotals}
                            pagination={false}
                            bordered
                            size="middle"
                            className="shadow-sm"
                            summary={() => (
                                <Table.Summary fixed>
                                    <Table.Summary.Row className="bg-gray-100 font-bold">
                                        <Table.Summary.Cell index={0}>Grand Total</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1} align="right">
                                            ${getAllTotals().grandTotal.toFixed(2)}
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                            )}
                        />
                    </div>
                </div>

                <div className="fixed bottom-6 right-6">
                    <Button
                        type="primary"
                        icon={<SaveOutlined />}
                        loading={saving}
                        onClick={() => form.submit()}
                        size="large"
                        className="shadow-lg"
                    >
                        Save All Changes
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default AnimalHealthPlanner;