import React, { useState } from 'react';
import { Box, Table } from '@mui/joy';
import { InputNumber, Form, Select, Input, } from 'antd';

const FormItem = Form.Item;
const antibioticProducts = [
 "Cefa-Safe (tube)",
 "Cefamaster (tube)",
 "Cepravin (tube)",
 "Dryclox (tube)",
 "Dryclox Xtra (tube)",
 "Orbenin DC (tube)",
 "Orbenin Enduro DC (tube)",
]


const teatsealantProducts = [
 "Teatseal (tube)",
 "ShutOut (tube)",
 "sdfsdfds"
]

export default function ClassificationTable({ form }) {
console.log(form)
 function createData(name, number, antibiotic, teatsealant, threshold) {
  return { name, number, antibiotic, teatsealant, threshold };
 }

 const rows = [
  createData(
   'Uninfected',
   'uninfected_number_cows',
   'uninfected_antibiotic',
   'uninfected_teatsealant',
   'uninfected_threshold'
  ),
  createData(
   'Infected',
   'infected_number_cows',
   'infected_antibiotic',
   'infected_teatsealant',
   'infected_threshold'
  ),
  createData(
   'Infected high risk ',
   'infected_high_risk_number_cows',
   'infected_high_risk_antibiotic',
   'infected_high_risk_teatsealant',
   'infected_high_risk_threshold'
  ),
  createData(
   'Chronic',
   'chronic_number_cows',
   'chronic_antibiotic',
   'chronic_teatsealant',
   'chronic_threshold'
  ),
  createData(
   'Maiden Heifer',
   'heifer_number_cows',
   'heifer_antibiotic',
   'heifer_teatsealant',
   'heifer_threshold'
  ),
 ];

 return (
  <Box sx={{ overflowX: 'auto' }}>
   <Table variant="soft" borderAxis="bothBetween">
    <thead>
     <tr>
      <th style={{ width: '20%' }}>Classification</th>
      <th style={{ width: '20%' }}>No. cows</th>
      <th style={{ width: '20%' }}>Antibiotic</th>
      <th style={{ width: '20%' }}>Teatsealant</th>
      <th style={{ width: '20%' }}>Threshold</th>
     </tr>
    </thead>
    <tbody>
     {rows.map((row) => {
      return (
       <tr key={row.name} style={{ width: '100%' }}>
        <th scope="row">{row.name}</th>
        <td>
         <FormItem name={row.number} style={{ width: '100%' }}>
          <InputNumber min={0} style={{ width: '100%' }} />
         </FormItem>
        </td>
        <td style={{ width: '100%' }}>
         <FormItem name={row.antibiotic} style={{ width: '100%' }}>
          <Select placeholder="Select an option" style={{ width: '100%' }} allowClear>
           {antibioticProducts.map((product) => (
            <Option key={product} value={product}>
             {product}
            </Option>
           ))}
          </Select>
         </FormItem>
        </td>
        <td style={{ width: '100%' }}>
         <FormItem name={row.teatsealant} style={{ width: '100%' }}>
          <Select
           placeholder="Select an option"
           style={{ width: '100%' }}
           allowClear
           defaultValue={form.getFieldValue(row.teatsealant)}
          >
           {teatsealantProducts.map((product) => (
            <Option key={product} value={product}>
             {product}
            </Option>
           ))}
          </Select>
         </FormItem>
        </td>
        <td>
         <FormItem name={row.threshold} style={{ width: '100%' }}>
          <Input style={{ width: '100%' }} />
         </FormItem>
        </td>
       </tr>
      );
     })}
    </tbody>
   </Table>
  </Box>
 );
}