import React from 'react';
import './styles/App.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import Dashboard from './authenticated/dashboard/Routes'
import { BrowserRouter } from 'react-router-dom';
import Home from './unauthenticated/Home';
import { ConfigProvider } from 'antd';


const MainContent = () => {
 return (
  <div className="App">
   <AuthenticatedTemplate>
    <ConfigProvider direction="ltr">
     <Dashboard />
    </ConfigProvider>
   </AuthenticatedTemplate>

   <UnauthenticatedTemplate>
    <Home />
   </UnauthenticatedTemplate>
  </div>
 );
};

export default function App() {
 return (
  <React.StrictMode>
   <BrowserRouter>
    <MainContent />
   </BrowserRouter>
  </React.StrictMode>
 );
}