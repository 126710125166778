import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import { Link } from 'react-router-dom';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton'; // Import iconButtonClasses from '@mui/joy/IconButton'
import Typography from '@mui/joy/Typography';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import CircularProgress from '@mui/joy/CircularProgress'; // Import CircularProgress from @mui/joy
import { useEffect } from 'react';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
 if (b[orderBy] < a[orderBy]) {
  return -1;
 }
 if (b[orderBy] > a[orderBy]) {
  return 1;
 }
 return 0;
}

interface FarmTableProps {
 farms: Farm[];
 clinics: Clinic[];
 searchQuery: string;
 isLoading: boolean; // Add isLoading prop
}

type Order = 'asc' | 'desc';

interface Farm {
 id: string;
 farm: string;
 address: string;
 farmer: string;
 email: string;
}

interface Clinic {
 id: string;
 clinic_name: string;
}

function getComparator<Key extends keyof any>(
 order: Order,
 orderBy: Key,
): (
 a: { [key in Key]: number | string },
 b: { [key in Key]: number | string },
) => number {
 return order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
 const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
 stabilizedThis.sort((a, b) => {
  const order = comparator(a[0], b[0]);
  if (order !== 0) {
   return order;
  }
  return a[1] - b[1];
 });
 return stabilizedThis.map((el) => el[0]);
}

interface FarmTableProps {
 farms: Farm[];
 clinics: Clinic[];
 searchQuery: string;
}

export default function FarmTable({ farms, clinics, searchQuery, isLoading }: FarmTableProps) {
 const [order, setOrder] = React.useState<Order>('desc');
 const [open, setOpen] = React.useState(false);
 const [currentPage, setCurrentPage] = React.useState(1); // State to track current page
 useEffect(() => {
  console.log('FarmTable received updated farms:', farms);
 }, [farms]);

 const itemsPerPage = 15; // Number of items per page
 const filteredFarms = React.useMemo(() => {
  return farms.filter(farm => farm.farm.toLowerCase().includes(searchQuery.toLowerCase()));
 }, [farms, searchQuery]);
 const renderPageNumbers = () => {
  const displayPages = 5; // Number of page numbers to display
  const middlePage = Math.ceil(displayPages / 2); // Middle page number
  const totalPages = Math.ceil(filteredFarms.length / itemsPerPage); // Calculate total number of pages based on filteredFarms

  let startPage = Math.max(currentPage - middlePage + 1, 1); // Calculate the start page number
  let endPage = Math.min(startPage + displayPages - 1, totalPages); // Calculate the end page number

  if (endPage - startPage < displayPages - 1) {
   startPage = Math.max(endPage - displayPages + 1, 1);
  }

  return Array.from({ length: endPage - startPage + 1 }, (_, index) => {
   const pageNumber = startPage + index;
   return (
    <IconButton
     key={pageNumber}
     size="sm"
     variant={currentPage === pageNumber ? 'outlined' : 'plain'}
     color="neutral"
     onClick={() => setCurrentPage(pageNumber)}
    >
     {pageNumber}
    </IconButton>
   );
  });
 };

 const renderRows = () => {
  // Calculate starting index and ending index for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredFarms.length); // Ensure endIndex doesn't exceed the total number of filteredFarms

  return stableSort(filteredFarms, getComparator(order, 'id'))
   .slice(startIndex, endIndex)
   .map((farm) => {
    return (
     <tr key={farm.id}>
      <td>
       <Typography level="body-xs" sx={{ textAlign: 'left' }}>
        <Link to={`/farm/${farm.id}`}>{farm.farm}</Link>
       </Typography>
      </td>
      <td>
       <Typography level="body-xs">{farm.address}</Typography>
      </td>
      <td>
       <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <div style={{ textAlign: 'center' }}>
         <Typography level="body-xs">{farm.farmer}</Typography>
        </div>
       </Box>
      </td>
      <td>
       <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Link level="body-xs" component="button">
         {farm.email}
        </Link>
        {/* <RowMenu /> */}
       </Box>
      </td>
     </tr>
    )
   });
 };

 const handlePrevPage = () => {
  setCurrentPage((prevPage) => Math.max(prevPage - 1, 1)); // Ensure currentPage doesn't go below 1
 };

 const handleNextPage = () => {
  setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages)); // Ensure currentPage doesn't exceed totalPages
 };


 const renderFilters = () => (
  <React.Fragment>
   <FormControl size="md">
    <FormLabel>Clinic</FormLabel>
    <Select  size="sm" placeholder="All">
     <Option value="all">All</Option>
     {clinics.map((clinic) => {
      return (
       <Option key={clinic.id} value={clinic.clinic_name}>{clinic.clinic_name}</Option>
      )
     })}
    </Select>
   </FormControl>
  </React.Fragment>
 );

 const totalPages = Math.ceil(filteredFarms.length / itemsPerPage); // Calculate total number of pages based on filteredFarms

 return (
  <React.Fragment>

   <Sheet
    className="FarmTableContainer"
    variant="outlined"
    sx={{
     width: '100%',
     borderRadius: 'sm',
     flexShrink: 1,
     overflow: 'auto',
     minHeight: 0,
    }}
   >
    {isLoading ? ( // Render loading spinner if isLoading is true
     <Box
      sx={{
       display: 'flex',
       justifyContent: 'center',
       alignItems: 'center',
       minHeight: '200px',
      }}
     >
      <CircularProgress />
     </Box>
    ) : (
     <Table
      aria-labelledby="tableTitle"
      stickyHeader
      hoverRow
      sx={{
       '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
       '--Table-headerUnderlineThickness': '1px',
       '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
       '--TableCell-paddingY': '4px',
       '--TableCell-paddingX': '8px',
      }}
     >
      <thead>
       <tr>
        <th style={{ width: 140, padding: '12px 6px', textAlign: 'left' }}>
         <Link
          underline="none"
          color="primary"
          component="button"
          onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
          fontWeight="lg"
          // endDecorator={<ArrowDropDownIcon />}
          sx={{
           '& svg': {
            transition: '0.2s',
            transform:
             order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
           },
          }}
         >
          Farm
         </Link>
        </th>
        <th style={{ width: 140, padding: '12px 6px', textAlign: 'center' }}>Address</th>
        {/* <th style={{ width: 140, padding: '12px 6px' }}>Status</th> */}
        <th style={{ width: 140, padding: '12px 6px', textAlign: 'center' }}>Farmer</th>
        <th style={{ width: 140, padding: '12px 6px', textAlign: 'center' }}>Email</th>
       </tr>
      </thead>
      <tbody>
       {renderRows()}
      </tbody>
     </Table>
    )}
   </Sheet>
   <Box
    className="Pagination-laptopUp"
    sx={{
     pt: 2,
     gap: 1,
     [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
     display: {
      xs: 'none',
      md: 'flex',
     },
    }}
   >
    <Button
     size="sm"
     variant="outlined"
     color="neutral"
     startDecorator={<KeyboardArrowLeftIcon />}
     onClick={handlePrevPage} // Attach onClick handler for Previous button
    >
     Previous
    </Button>

    <Box sx={{ flex: 1 }} />
    {renderPageNumbers()}

    <Box sx={{ flex: 1 }} />

    <Button
     size="sm"
     variant="outlined"
     color="neutral"
     // endDecorator={<KeyboardArrowRightIcon />}
     onClick={handleNextPage} // Attach onClick handler for Next button
    >
     Next
    </Button>
   </Box>
  </React.Fragment>
 );
}
