import React from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import pageTemplate from '../../../components/pageTemplate';
import FormLayout from '../../../components/FormLayout';

const KeyActions = () => {
  const { farmId, rvmId } = useParams();
  const { form, onFinish } = pageTemplate(
    'rvm_key_actions_data',
    'rvm_id',
    'key_actions_id',
    farmId,
    rvmId,
    'rvm_id',
    'rvms'
  );

  const formContent = (
    <>
      <Form.Item label="Key Action 1" name="key_action_one">
        <Input />
      </Form.Item>
      <Form.Item label="Key Action 2" name="key_action_two">
        <Input />
      </Form.Item>
      <Form.Item label="Key Action 3" name="key_action_three">
        <Input />
      </Form.Item>
      <Form.Item label="Notes" name="notes">
        <TextArea
          placeholder="Controlled autosize"
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </Form.Item>
    </>
  );

  return (
    <FormLayout 
      title="Key Actions" 
      form={form} 
      onFinish={onFinish}
      extraContent={formContent}
    />
  );
};

export default KeyActions;