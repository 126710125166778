import React, { useEffect, useState } from 'react';
import supabase from '~supabaseConfig';
import Box from '@mui/joy/Box';
import OrderList from './components/OrderList';
import FarmTable from './components/FarmTable';
import { useMsal, useAccount } from "@azure/msal-react";
import SearchIcon from '@mui/icons-material/Search';
import Input from '@mui/joy/Input'; // Import Input from @mui/joy
import FormLabel from '@mui/joy/FormLabel';
import { Button, message } from 'antd';
import FarmForm from './components/FarmForm';

const vetNames = [
 "amberrowansanders",
 "belindaborman",
 "blairvandyk",
 "brentneal",
 "brucenicol",
 "danfitzharris",
 "daniellethomson",
 "davecurnow",
 "davidhawkins",
 "davidmoors",
 "gemmawithers",
 "georgiapaterson",
 "graemecharteris",
 "greglindsay",
 "heathmacleod",
 "ilysejennens",
 "ivanpetch",
 "jasonfayers",
 "jenettaforsyth",
 "jennystone",
 "jordanlay",
 "kristinebrownlee",
 "laralambert",
 "leahwakeford",
 "matthewwong",
 "melissajones",
 "melissaveltman",
 "neilmurray",
 "nicktimms",
 "sarahclews",
 "stephaniereid",
 "hamishittest"
];

const Home = () => {
 const [farms, setFarms] = useState([]);
 const [open, setopen] = useState(false);
 const [clinics, setClinics] = useState([]);
 const [searchQuery, setSearchQuery] = useState('');
 const { instance, accounts } = useMsal();

 const azureUser = useAccount(accounts[0]);

 const handleSearchChange = (e) => {
  setSearchQuery(e.target.value);
  console.log(e.target.value); // Check if searchQuery updates as you type
 };

 // Call the function to check authentication state
 useEffect(() => {
  async function authUser() {
   const accountId = azureUser.localAccountId;
   const username = azureUser.username;
   const name = azureUser.name;
   const nameToCheck = azureUser.name.replace(/\s/g, '').toLowerCase();

   console.log(name)
   console.log(vetNames.includes(nameToCheck))
   //Hamish Henare


   try {
    const { data: { user } } = await supabase.auth.getUser();

    if (user) {
     // Check if the user exists in the public users table
     const { data: existingUser, error: selectError } = await supabase
      .from('users')
      .select('id')
      .eq('email', user.email);

     if (selectError) {
      throw selectError;
     }

     if (existingUser.length === 0) {
      // User doesn't exist in the public users table, insert the user data
      const { data: insertData, error: insertError } = await supabase
       .from('users')
       .insert({
        email: user.email,
        name: name,
        vet: vetNames.includes(nameToCheck) ? true : false
       });

      if (insertError) {
       throw insertError;
      }

      console.log('User data inserted successfully:', insertData);
     } else {
      console.log('User already exists in the public users table');
     }
    } else {
     try {
      const { data, error } = await supabase.auth.signInWithPassword({
       email: username,
       password: accountId,
      });

      if (error) {
       throw error;
      }

      message.success('User logged in successfully');
     } catch (error) {
      console.error('Error signing in:', error);
      message.error('Failed to sign in. Please check your credentials.');

      try {
       const { data: signUpData, error: signUpError } = await supabase.auth.signUp({
        email: username,
        password: accountId,
       });

       if (signUpError) {
        throw signUpError;
       }

       const { data: signInData, error: signInError } = await supabase.auth.signInWithPassword({
        email: username,
        password: accountId,
       });

       const userId = signInData.user.id;
       const userEmail = signInData.user.email;

       const { data: insertData, error: insertError } = await supabase
        .from('users')
        .insert({
         email: userEmail,
         name: name,
         vet: vetNames.includes(name) ? true : false
        });

       if (signInError || insertError) {
        throw signInError || insertError;
       }

       message.success('User signed up and logged in successfully');
      } catch (error) {
       console.error('Error signing up:', error);
       message.error('Failed to sign up. Please try again later.');
      }
     }
    }
   } catch (error) {
    console.error('Error getting user:', error);
    message.error('Failed to authenticate. Please try again later.');
   }
  }

  async function fetchClinicsAndFarms() {
   try {
    // Fetch clinics
    const { data: clinicsData, error: clinicsError } = await supabase
     .from('clinics')
     .select('id, clinic_name');

    if (clinicsError) {
     throw new Error(`Error fetching clinics: ${clinicsError.message}`);
    } else {
     setClinics(clinicsData || []);
    }

    // Fetch farms
    const { data: farmsData, error: farmsError } = await supabase
     .from('farms')
     .select('id, farm_name, address, farmer_name, email');

    if (farmsError) {
     throw new Error(`Error fetching farms: ${farmsError.message}`);
    } else {
     // Transform the fetched data to fit the desired object structure
     const transformedData = farmsData.map((farm) => ({
      id: farm.id,
      farm: farm.farm_name || 'N/A',
      address: farm.address || 'N/A',
      farmer: farm.farmer_name || 'N/A',
      email: farm.email || 'N/A',
     }));
     setFarms(transformedData || []);
    }
   } catch (error) {
    console.error(error);
   }
  }
  authUser()
  fetchClinicsAndFarms();
 }, []);
 const handleAddFarm = async (values) => {
  try {
   const { data, error } = await supabase.from('farms').insert([values]);

   if (error) {
    throw new Error(`Error adding farm: ${error.message}`);
   } else {
    setopen(false);
    fetchFarms(); // Fetch the updated farm list after adding a new farm
   }
  } catch (error) {
   console.error(error);
  }
 };

 const fetchFarms = async () => {
  try {
   const { data: farmsData, error: farmsError } = await supabase
    .from('farms')
    .select('id, farm_name, address, farmer_name, email');

   if (farmsError) {
    throw new Error(`Error fetching farms: ${farmsError.message}`);
   } else {
    const transformedData = farmsData.map((farm) => ({
     id: farm.id,
     farm: farm.farm_name || 'N/A',
     address: farm.address || 'N/A',
     farmer: farm.farmer_name || 'N/A',
     email: farm.email || 'N/A',
    }));
    setFarms(transformedData || []);
   }
  } catch (error) {
   console.error(error);
  }
 };

 return (
  <Box
   component="main"
   className="MainContent"
   sx={{
    px: { xs: 2, md: 6 },
    pt: {
     xs: 'calc(12px + var(--Header-height))',
     sm: 'calc(12px + var(--Header-height))',
     md: 3,
    },
    pb: { xs: 2, sm: 2, md: 3 },
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    height: '100dvh',
    gap: 1,
   }}
  >
   <FarmForm
    visible={open}
    onClose={() => setopen(false)}
    onSubmit={handleAddFarm}
   />
   <FormLabel>Search for a farm</FormLabel>
   <Box
    sx={{
     display: 'flex',
     flexDirection: 'row',
     flexWrap: 'wrap',
     justifyContent: 'space-between',
     alignItems: 'center',
     width: '100%',
     gap: { xs: 1, sm: 0 },
    }}
   >
    <Input
     value={searchQuery}
     onChange={handleSearchChange}
     placeholder="Search farms"
     startDecorator={<SearchIcon />}
     sx={{
      flexGrow: 1,
      maxWidth: { xs: '100%', sm: 'calc(100% - 140px)' },
      mb: { xs: 1, sm: 0 },
     }}
    />
    <Button
     type="primary"
     sx={{
      whiteSpace: 'nowrap',
      minWidth: { xs: '100%', sm: '120px' },
     }}
     onClick={() => setopen(true)}
    >
     Add New Farm
    </Button>
   </Box>
   <FarmTable farms={farms} clinics={clinics} searchQuery={searchQuery} />

  </Box>
 );
};

export default Home;