import React, { useEffect, useState } from 'react';
import { Form, Input, Drawer, Button, Select, Row, Col, } from 'antd';
import supabase from '~supabaseConfig';

const FarmForm = ({ visible, onClose, onSubmit }) => {
 const [form] = Form.useForm();
 const [vets, setVets] = useState([]);
 const [clinics, setClinics] = useState([]);

 useEffect(() => {
  fetchVets();
  fetchClinics();
 }, []);

 const fetchVets = async () => {
  try {
   const { data, error } = await supabase
    .from('users')
    .select('id, name')
    .eq('vet', true);

   if (error) {
    throw new Error(`Error fetching vets: ${error.message}`);
   }

   setVets(data);
  } catch (error) {
   console.error('Error fetching vets:', error);
  }
 };

 const fetchClinics = async () => {
  try {
   const { data, error } = await supabase.from('clinics').select('id, clinic_name');

   if (error) {
    throw new Error(`Error fetching clinics: ${error.message}`);
   }

   setClinics(data);
  } catch (error) {
   console.error('Error fetching clinics:', error);
  }
 };

 const handleSubmit = () => {
  form.validateFields().then(values => {
   form.resetFields();
   onSubmit(values);
  });
 };

 return (
  <Form form={form} layout="vertical">
   <Drawer
    title="Create a new account"
    width={720}
    closable={false}
    onClose={onClose}
    open={visible}
    styles={{
     body: {
      paddingBottom: 80,
     },
    }}
    extra={<Form.Item>
     <Button type="primary" onClick={handleSubmit}>
      Add Farm
     </Button>
     <Button onClick={onClose} style={{ marginLeft: 8 }}>
      Cancel
     </Button>
    </Form.Item>}>
    <Form form={form} layout="vertical" hideRequiredMark>
    <Row gutter={16}>
     <Col span={12}>
      <Form.Item
       name="farm_name"
       label="Farm Name"
       rules={[{ required: true, message: 'Please enter the farm name' }]}
      >
       <Input placeholder="Please enter farm name" />
      </Form.Item>
     </Col>
     <Col span={12}>
      <Form.Item name="farmer_name" label="Farmer Name">
       <Input
        style={{
         width: '100%',
        }} placeholder='Please enter the farmers name'
       />
      </Form.Item>
     </Col>
    </Row>
    <Row gutter={16}>
     <Col span={12}>
      <Form.Item
       name="address"
       label="Address"
       rules={[{ required: true, message: 'Please enter the farm address' }]}
      >
       <Input />
      </Form.Item>
     </Col>
     <Col span={12}>
      <Form.Item name="client_code" label="Client code">
       <Input />
      </Form.Item>
     </Col>
    </Row>
    <Row gutter={16}>
     <Col span={12}>
      <Form.Item name="email" label="Email">
       <Input />
      </Form.Item>
     </Col>
     <Col span={12}>
      <Form.Item name="phone_number" label="Phone number">
       <Input />
      </Form.Item>
     </Col>
    </Row>
    <Row gutter={16}>
     <Col span={12}>
      <Form.Item name="vet" label="Vet">
       <Select allowClear placeholder="Select a vet">
        {vets.map((vet) => (
         <Select.Option key={vet.id} value={vet.id}>
          {vet.name}
         </Select.Option>
        ))}
       </Select>
      </Form.Item>
     </Col>
     <Col span={12}>
      <Form.Item name="clinic_id" label="Clinic">
       <Select placeholder="Select a clinic" allowClear>
        {clinics.map((clinic) => (
         <Select.Option key={clinic.id} value={clinic.id}>
          {clinic.clinic_name}
         </Select.Option>
        ))}
       </Select>
      </Form.Item>
     </Col>
    </Row>
    <Row gutter={16}>
     <Col span={8}>
      <Form.Item name="farm_size" label="Farm Size">
       <Input />
      </Form.Item>
     </Col>
     <Col span={8}>
      <Form.Item name="herd_size" label="Herd Size">
       <Input />
      </Form.Item>
     </Col>
     <Col span={8}>
      <Form.Item name="heifer_size" label="Heifer Size">
       <Input />
      </Form.Item>
     </Col>
    </Row>
   </Form>
   </Drawer>
  </Form>
 );
};

export default FarmForm;