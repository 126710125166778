// supabaseConfig.js
import { createClient } from '@supabase/supabase-js';

const supabaseAnon = import.meta.env.VITE_SUPABASE_ANON;
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;

const supabaseConfig = {
  supabaseUrl: supabaseUrl,
  supabaseKey: supabaseAnon,
};

const supabase = createClient(supabaseUrl, supabaseAnon);

export default supabase;
