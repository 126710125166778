const excelValues = (data) => {



 const combinedData = data.consultData


 return {
   A2: combinedData?.farms?.farm_name ?? '',
   B2: null,
   C2: combinedData?.farms?.clinic_id?.clinic_name ?? '',
   D2: combinedData?.farm_objectives_id?.total_milk_production ?? null,
   E2: combinedData?.farms?.supply_number ?? '',
   F2: combinedData?.farms?.vet?.name ?? '',
   G2: combinedData?.farms?.farm_size ?? null,
   H2: combinedData?.farms?.herd_size ?? null,
   I2: combinedData?.farms?.heifer_size ?? null,
   J2: combinedData?.farm_objectives_id?.goal_one ?? '',
   K2: combinedData?.farm_objectives_id?.goal_two ?? '',
   L2: combinedData?.farm_objectives_id?.goal_three ?? '',
   P2: combinedData?.milk_quality_data_id?.average_bmscc ?? null,
   T2: combinedData?.milk_quality_data_id?.clinical_mastitis ?? null,
   AI2: combinedData?.milk_quality_data_id?.number_cows_culled_mastitis ?? null,
   AT2: combinedData?.farm_objectives_id?.planned_start_of_calving_spring ?? null,
   AU2: combinedData?.farm_objectives_id?.planned_start_of_calving_autumn ?? null,
   AY2: combinedData?.repro_id?.six_week_icr ?? null,
   AZ2: combinedData?.repro_id?.empty_rate ?? null,
   BA2: combinedData?.repro_id?.mating_length_weeks ?? null,
   BB2: combinedData?.repro_id?.r2_empty_rate ?? null,
   BG2: combinedData?.repro_id?.scanning ?? null,
   BH2: combinedData?.repro_id?.mating_start_date ?? null,
   BI2: combinedData?.repro_id?.mating_end_date ?? null,
   BJ2: combinedData?.health_id?.cow_abort ?? null,
   BK2: combinedData?.health_id?.cow_metabolic_disease ?? null,
   BL2: combinedData?.health_id?.cow_retained_cleanings ?? null,
   BM2: combinedData?.health_id?.cow_lame ?? null,
   BN2: combinedData?.health_id?.cow_deaths ?? null,
   BO2: combinedData?.farm_objectives_id?.calves_reared ?? null,
   BP2: combinedData?.health_id?.calves_scours ?? null,
   BQ2: combinedData?.health_id?.calves_navel_swollen_joints ?? null,
   BR2: combinedData?.health_id?.calves_deaths ?? null,
   BU2: combinedData?.repro_id?.mating_length_weeks ?? null,
   BX2: combinedData?.health_id?.services?.includes("herd_drench") ?? false,
   CA2: combinedData?.health_id?.services?.includes("young_stock_health_plan") ?? false,
   CB2: combinedData?.health_id?.services?.includes("welfarm") ?? false,
   CC2: combinedData?.health_id?.services?.includes("transition_plan") ?? false,
   CD2: combinedData?.health_id?.services?.includes("debudding") ?? false,
   CE2: combinedData?.health_id?.services?.includes("rotavec") ?? false,
   CF2: combinedData?.health_id?.services?.includes("salvexin") ?? false,
   CG2: combinedData?.mineral_supplementation_id?.dosatron ?? null,
   CH2: combinedData?.mineral_supplementation_id?.trace_mineral_mix_used ?? '',
   CI2: combinedData?.mineral_supplementation_id?.magnesium ?? null,
   CJ2: combinedData?.mineral_supplementation_id?.calcium ?? null,
   CK2: combinedData?.mineral_supplementation_id?.zinc ?? null,
   CL2: combinedData?.mineral_supplementation_id?.copper ?? null,
   CM2: combinedData?.mineral_supplementation_id?.selenium ?? null,
   CN2: combinedData?.mineral_supplementation_id?.cobal_b12 ?? null,
   CO2: combinedData?.mineral_supplementation_id?.last_mineral_check ?? null,
   CP2: combinedData?.mineral_supplementation_id?.next_mineral_check ?? null,
   CS2: combinedData?.repro_id?.herd_animals_vaccinated?.includes('Bulls') ?? false,
   CT2: combinedData?.repro_id?.herd_animals_vaccinated?.includes('Heifers') ?? false,
   CU2: combinedData?.repro_id?.herd_animals_vaccinated?.includes('Calves') ?? false,
   CV2: combinedData?.repro_id?.herd_testing ?? null,
   CW2: combinedData?.repro_id?.herd_animals_tested?.includes('Bulls') ?? false,
   CX2: combinedData?.repro_id?.herd_animals_tested?.includes('Heifers') ?? false,
   CY2: combinedData?.repro_id?.herd_animals_tested?.includes('Calves') ?? false,
   DC2: combinedData?.key_actions_id?.key_action_one ?? '',
   DD2: combinedData?.key_actions_id?.key_action_two ?? '',
   DE2: combinedData?.key_actions_id?.key_action_three ?? '',
   DF2: combinedData?.key_actions_id?.notes ?? '',
   DG2: combinedData?.milk_quality_data_id?.first_case ?? null,
   DH2: combinedData?.milk_quality_data_id?.repeat_case ?? null,
   DI2: combinedData?.milk_quality_data_id?.multiple_quarters ?? null,
   // comment section
   B21: combinedData?.milk_quality_data_id?.plan ?? '',
   B22: combinedData?.repro_id?.comments ?? '',
   B23: combinedData?.health_id?.comments ?? '',
   B24: combinedData?.key_actions_id?.notes ?? '',
 };
};

export default excelValues;