import React from 'react'
import { Input, Form, InputNumber } from 'antd';
import { Table } from '@mui/joy';
const FormItem = Form.Item;


function createDryCowData(name, input1, input2, input3) {
 return { name, input1, input2, input3 };
}

const dryCowRows = [
 createDryCowData('Planned start of calving', 'planned_start_of_calving_spring', 'planned_start_of_calving_autumn'),
 createDryCowData('Latest planned dry-off date', 'latest_planned_dryoff_date_spring', 'latest_planned_dryoff_date_autumn', 'calving_comments'),
 createDryCowData('Dry Period Length', 'dry_period_length_spring', 'dry_period_autumn'),
 createDryCowData('Est milk volume/cow prior to Dry-off', 'est_milk_vol_prior_to_dry_off'),
];

export default function CalvingTable() {
 return (
  <Table variant="soft" borderAxis="bothBetween">
   <thead>
    <tr>
     <th style={{ width: '16%' }}>{null}</th>
     <th style={{ width: '28%' }}>Spring</th>
     <th style={{ width: '28%' }}>Autumn</th>
     <th style={{ width: '28%' }}>{null}</th>
    </tr>
   </thead>
   <tbody>
    {dryCowRows.map((row) => {
     return (
      <tr key={row.name}>
       <th scope="row">{row.name}</th>
       <td>
        {row.name === 'Planned start of calving' || row.name === 'Latest planned dry-off date' ? (
         <FormItem name={row.input1}>
          <Input type="date" />
         </FormItem>
        ) : (
         <FormItem name={row.input1}>
          <InputNumber min={0} />
         </FormItem>
        )}
       </td>
       <td>
        {row.input2 ? (
         row.name === 'Planned start of calving' || row.name === 'Latest planned dry-off date' ? (
          <FormItem name={row.input2}>
           <Input type="date" />
          </FormItem>
         ) : (
          <FormItem name={row.input2}>
           <InputNumber min={0} />
          </FormItem>
         )
        ) : null}
       </td>
       <td>
        {
         row.input3 === 'calving_comments' ? (

          <FormItem label={'Comments'} name={row.input3}>
           <Input />
          </FormItem>
         ) : (
          null
         )
        }
       </td>
       <td></td>
      </tr>
     );
    })}
   </tbody>
  </Table>
 )
}
